var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-1"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pt-0 justify-space-between"},[_c('h5',{staticClass:"black--text"},[_vm._v("Valores")]),_c('small',{staticClass:"grey--text"},[_vm._v("Fecha de atención: "+_vm._s(_vm.formatDate(_vm.medicalRecord.start_date)))])]),_c('v-divider',{staticClass:"mb-3"}),_c('h5',{staticClass:"ml-2"},[_vm._v("Productos")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsProducts,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('span',[_vm._v(_vm._s(item.name))]):_c('TrueAutoComplete',{ref:"productField",staticClass:"mt-4",attrs:{"listMethod":_vm.searchProducts,"payloadMethod":function (query) { return _vm.getPayloadProduct(query); },"itemTextMethod":function (item) { return item.name; },"rules":_vm.validationRules,"minCharacters":2},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('span',[_vm._v(_vm._s(item.quantity))]):_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","type":"number","min":"1"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.medicalRecord.is_finished},on:{"click":function($event){return _vm.deleteProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}}])}),_c('v-btn',{staticClass:"form-btn elevation-0",attrs:{"plain":"","color":"secondary","disabled":_vm.medicalRecord.is_finished},on:{"click":_vm.addNewProducts}},[_vm._v("+ Añadir nuevo producto")]),_c('v-divider',{staticClass:"my-3"}),_c('h5',{staticClass:"ml-2"},[_vm._v("Servicios")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsServices,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('span',[_vm._v(_vm._s(item.name))]):_c('TrueAutoComplete',{ref:"serviceField",staticClass:"mt-4",attrs:{"listMethod":_vm.searchServices,"payloadMethod":function (query) { return _vm.getPayloadProduct(query); },"itemTextMethod":function (item) { return item.name; },"rules":_vm.validationRules,"minCharacters":2},model:{value:(item.service),callback:function ($$v) {_vm.$set(item, "service", $$v)},expression:"item.service"}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('span',[_vm._v(_vm._s(item.quantity))]):_c('v-text-field',{staticClass:"mt-4",attrs:{"outlined":"","dense":"","type":"number","min":"1"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.medicalRecord.is_finished},on:{"click":function($event){return _vm.deleteService(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}}])}),_c('v-btn',{staticClass:"form-btn elevation-0",attrs:{"plain":"","color":"secondary","disabled":_vm.medicalRecord.is_finished},on:{"click":_vm.addNewServices}},[_vm._v("+ Añadir nuevo servicio")]),_c('h5',{staticClass:"mt-4"},[_vm._v("Comentario para ventas")]),(!_vm.medicalRecord.is_finished)?_c('v-textarea',{staticClass:"mr-3",attrs:{"outlined":"","dense":"","clearable":"","clear-icon":"mdi-close-circle","rows":"2"},model:{value:(_vm.comment_for_sale),callback:function ($$v) {_vm.comment_for_sale=$$v},expression:"comment_for_sale"}}):_c('p',{staticClass:"mt-4 text-left"},[_vm._v(_vm._s(_vm.comment_for_sale || 'No hay comentario'))])],1),(_vm.medicalRecord.is_finished)?_c('small',{staticClass:"d-flex justify-end mr-3 red--text"},[_vm._v("Esta ficha ya tiene asociada una venta.")]):_vm._e(),_c('v-card-actions',{staticClass:"card-actions mt-5"},[_c('v-row',{staticClass:"ml-3 justify-space-between"},[_c('v-btn',{staticClass:"form-btn justify-center",attrs:{"color":"primary","outlined":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('previousTab')}}},[_vm._v("Atrás")]),_c('div',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.medicalRecord.is_finished),expression:"!medicalRecord.is_finished"}],staticClass:"form-btn justify-center mr-4",attrs:{"color":"#7672B7","dark":"","elevation":"0"},on:{"click":_vm.saveData}},[_vm._v("Guardar")]),_c('v-btn',{staticClass:"form-btn justify-center mr-4",attrs:{"color":"primary","elevation":"0","disabled":_vm.medicalRecord.is_finished},on:{"click":_vm.createSale}},[_vm._v("Finalizar atención")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }