<template lang="pug">
div
  v-data-table(
    :headers="user.is_superuser ? headersSuperuser : headers"
    :items="items"
    :server-items-length="itemCount"
    :page.sync="currentPage"
    @page-count="pageCount = $event"

    disable-sort
    hide-default-footer
    class="elevation-1 table"
    :loading="isLoading"
  )

    template( #item.staffName="{ item }" )
      | {{item.staffName || '- - -'}}

    template( #item.saleId="{ item }" )
      .d-flex.justify-space-between.align-center
        div {{item.saleId}}
        v-btn( text @click.stop="handleViewSale(item.saleId)" ).action-btn
          v-icon.mb-1 mdi-text-box-search-outline

    template( #item.date="{ item }" )
      | {{dateTimeToLocaleDate(item.date)}}

    template( #item.saleStatus="{ item }" )
      StatusChip( :value="item.saleStatus" )

    template( #item.serviceAmount="{ item }" )
      | {{numberToPesos(item.serviceAmount)}}

    template( #item.commissionRate="{ item }" )
      | {{item.commissionRate * 100}}%

    template( #item.commissionAmount="{ item }" )
      | {{numberToPesos(item.commissionAmount)}}

    template( #no-data ) No se encontraron datos.
    template( #no-results ) La búsqueda no arrojó resultados.
    template( #loading ) Cargando. Por favor espere.

  ViewSaleDialog(ref="viewSaleDialog")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'
import FormatterMixin from '@/components/mixins/FormatterMixin'
import StatusChip from '../../shared/StatusChip.vue'
import ViewSaleDialog from '../../ventas/listado/ViewSaleDialog.vue'

const ITEMS_PER_PAGE = 10

export default {
  mixins: [ FormatterMixin ],
  components: { StatusChip, ViewSaleDialog },

  data() {
    return {
      isLoading: true,
      items: [],
      headers: [
        { text: 'Profesional',        value: 'staffName' },
        { text: 'Servicio prestado',  value: 'service' },
        { text: 'ID Venta',           value: 'saleId',            width: '100px'},
        { text: 'Fecha',              value: 'date',              width: '120px'},
        { text: 'Estado Venta',       value: 'saleStatus',        width: '100px'},
        { text: 'Valor del servicio', value: 'serviceAmount',     width: '120px', align: 'end'},
      ],
      headersSuperuser: [
        { text: 'Profesional',        value: 'staffName' },
        { text: 'Servicio prestado',  value: 'service' },
        { text: 'ID Venta',           value: 'saleId',            width: '100px'},
        { text: 'Fecha',              value: 'date',              width: '120px'},
        { text: 'Estado Venta',       value: 'saleStatus',        width: '100px'},
        { text: 'Valor del servicio', value: 'serviceAmount',     width: '120px', align: 'end'},
        { text: '% Comisión',         value: 'commissionRate',    width: '120px', align: 'end'},
        { text: 'Valor comisión',     value: 'commissionAmount',  width: '120px', align: 'end'},
      ]
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapFields('prestaciones', [
      'itemCount',
      'pageCount',
      'currentPage',
      'filterStaff',
      'filterDates',
      'filterStatus',
    ]),
  },

  watch: {
    itemCount(v) { this.pageCount = Math.ceil(v / ITEMS_PER_PAGE) }
  },
  
  methods: {
    ...mapActions('prestaciones', [
      'listPrestaciones',
      'setSummary',
    ]),

    async loadData() {
      this.isLoading = true

      const { filterDates: dates, filterStatus: status, filterStaff: professional, currentPage } = this
      const params = {
        sale__created_at__lte: dates[1] ? `${dates[1]}T23:59:59` : null,
        sale__created_at__gte: dates[0] ? `${dates[0]}T00:00:00` : null,
        sale__status         : status,
        professional         : professional ? professional.id : null,
        page                 : currentPage,
      }
      const res = await this.listPrestaciones({ params })
      if (res.status === 200) {
        this.itemCount = res.data.count
        this.items = res.data.results.map(this.mapService)
        await this.setSummary({ params })
      }

      this.isLoading = false
    },

    mapService(s) {
      return {
        staffName       : s.professional_name,
        service         : s.service_data.name,
        saleId          : s.sale,
        date            : s.created_at,
        saleStatus      : s.sale_status,
        serviceAmount   : s.total_price,
        commissionRate  : s.service_data.commission_percentage,
        commissionAmount: s.commission,
      }
    },

    handleViewSale(id) {
      this.$refs.viewSaleDialog.open(id)
    },
  },

  created() {
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>

</style>