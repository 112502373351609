<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="modal"
  persistent
)
  template(#activator="{ on, attrs }")
    v-btn(v-bind="attrs" v-on="on" color='#A1A7B5' text='')
      v-icon mdi-pencil
    
  Card(
    ref="card"
    @refreshCalendar="updateRange"
    @closeDialog="handleClose"
  )
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields'
import Card from "./Card.vue";

export default {
  components: {
    Card
  },

  props: {
    id: Number,
    fecha: Date,
  },

  data: () => ({
    isActive: false,
  }),

  computed: {
    ...mapFields('agendarHora', [
      'isEditando',
    ]),
  },

  watch: {
    isActive(active) {
      if (active) {
        this.isEditando = true
        this.setEditForm({ id: this.id })
        this.$nextTick(() => {
          this.$refs.card.reloadFieldData();
        })
      }
    },
  },

  methods: {
    ...mapMutations('agendarHora', [
      'resetFields'
    ]),
    ...mapActions('agendarHora', [
      'setEditForm'
    ]),

    updateRange(){
      this.$emit('refreshCalendar')
      this.$emit('closeDialogSelectEvent')
    },

    handleClose() {
      this.resetFields()
      this.$refs.card.resetForms();
      this.isActive = false;
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

::v-deep .modal {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 84%;
    max-width: 1200px;
  }
}
</style>