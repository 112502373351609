import Vue from 'vue'
import VueRouter from 'vue-router'
import DjangoLayout from '../layouts/DjangoLayout'
import settings from '../../settings'
import isAuthenticated from '../utils/isAuthenticated'
import Inicio from '../views/Inicio.vue'
import Ventas from '../views/Ventas.vue'
import Agenda from '../views/Agenda.vue'
import Pacientes from '../views/Pacientes.vue'
import Presupuesto from '../views/Presupuesto.vue'
import Prestaciones from '../views/Prestaciones.vue'
import Login from '../views/Login.vue'
import checkGroups from '../utils/checkGroups'
import Precios from '../views/Precios.vue'
import DetallePacientes from '../views/DetallePacientes.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {hideHeader:true},
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Ventas
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: Inicio
  },
  {
    path: '/ventas',
    name: 'Ventas',
    component: Ventas
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: Agenda
  },
  {
    path: '/pacientes',
    name: 'Pacientes',
    component: Pacientes
  },
  {
    path: '/pacientes/:idPaciente/detalle',
    name: 'Detalle Paciente',
    component: DetallePacientes
  },
  {
    path: '/presupuesto',
    name: 'Presupuesto',
    component: Presupuesto
  },
  {
    path: '/categorias',
    name: 'Categorías',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/category/`,
      groups: ['Recepcionista', 'Técnico']
    }
  },
  {
    path: '/marcas',
    name: 'Marcas',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/brand/`,
      groups: ['Recepcionista', 'Técnico']
    }
  },
  {
    path: '/movimiento-stock',
    name: 'Stock',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/stockmovement/`,
      groups: ['Recepcionista', 'Técnico']
    }
  },
  {
    path: "/precio-producto",
    name: "Precios",
    component: Precios
  },
  {
    path: '/productos',
    name: 'Productos',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/product/`,
      groups: ['Recepcionista', 'Técnico']
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/auth/user/`,
      groups: ['Recepcionista', 'Técnico']
    }
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/service/`,
    }
  },
  {
    path: '/area',
    name: 'Área',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/inventory/area/`,
    }
  },
  {
    path: '/salas',
    name: 'Salas',
    component: DjangoLayout,
    meta: {
      djangoUrl: `${settings.API_URL}/api/admin/service/room/`,
      groups: ['Recepcionista', 'Técnico']
    }
  },
  {
    path: '/prestaciones',
    name: 'Prestaciones',
    component: Prestaciones,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isAuthenticated()) next({ name: 'login' })

  else next()
})
router.beforeEach((to, from, next) => {
  if (to.name === 'Home') next()
  else if (!to.meta.groups) next()
  else if (checkGroups(to.meta.groups)) next()
  else next({ name: 'Home' })
})
export default router
