export default (groups = []) => {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user) {
    if (user.is_superuser) {
      return true
    }
    return user.groups.some(el => groups.includes(el))
  }
  return false
}
