<template lang="pug">
v-tooltip.tooltip( top )
  template( #activator="{ on, attrs }" )
    v-icon.ml-2(color="#FFB840" v-bind="attrs" v-on="on") mdi-comment-alert
  span Este servicio incluye {{productCount}} producto{{productCount > 1 ? 's' : ''}}
  ul
    li( v-for="p in productsInService" )
      | {{p.product ? p.product.name : '-'}} x {{p.quantity}}
</template>

<script>
export default {
  props: {
    productsInService: Array,
  },

  computed: {
    productCount() {
      return this.productsInService.length
    }
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  color: #FFB840;
  z-index: 9060;
}
</style>