var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-9"},[_c('h2',[_vm._v("Lista de Pacientes")]),_c('div',{staticStyle:{"background-color":"#fff","border-radius":"5px","margin-top":"20px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"pt-7 pl-3 shrink mx-5",staticStyle:{"width":"300px"},attrs:{"filled":"","outlined":"","placeholder":"Buscar","prepend-inner-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-8 rounded-lg",attrs:{"color":"primary","dense":""},on:{"click":function($event){return _vm.$refs.petForm.open()}}},[_vm._v("+ Nuevo Paciente")])],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.patient,"hide-default-footer":"","page":_vm.page,"rows-per-page":_vm.pagination.rowsPerPage,"server-items-length":_vm.rowCount,"loading":_vm.loading},on:{"update:page":function($event){_vm.page=$event},"update:sort-by":_vm.sortData,"update:sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"namePet"},[_vm._v(_vm._s(item.name))])]}},{key:"item.latest_record_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.latest_record_date)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'Detalle Paciente', params: { idPaciente: item.id }})}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#8E8E8E"}},[_vm._v("mdi-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Detalle")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$refs.petForm.open(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#8E8E8E"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Paciente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$refs.mergePetForm.open(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#8E8E8E"}},[_vm._v("mdi-account-convert-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Fusionar Paciente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.deletePet(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticStyle:{"color":"#8E8E8E"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Paciente")])])]}},{key:"footer",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-pagination',{staticClass:"py-4",attrs:{"length":props.pagination.pageCount,"total-visible":7,"circle":"","color":"primary","elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]}}])})],1),_c('ConfirmModal',{ref:"confirm"}),_c('PetForm',{ref:"petForm",on:{"saved":_vm.fetchData}}),_c('MergePetForm',{ref:"mergePetForm",on:{"saved":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }