import axios from '../../plugins/axios'

export const listPrice = (_, params) => {
  return axios.get('inventory/product_price/', { params }).catch((err) => err.response)
}

export const getProductPrice = (_, id) => {
  return axios.get(`inventory/product_price/${id}/`).catch((err) => err.response)
}

export const createProductPrice = (_, data) => {
  return axios.post('inventory/product_price/', data).catch((err) => err.response)
}

export const deleteProductPrice = (_, id) => {
  return axios.delete(`inventory/product_price/${id}/`).catch((err) => err.response)
}
