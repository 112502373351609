<template lang="pug">
v-container.container
  TitleAndBreadcrumb( label="Lista de Ventas" )

  v-card
    v-card-text
      TopBar(
        @queryChange="reloadData"
        @saleCreated="reloadData"
        @openEditDialog="openEditDialog"
      )
    v-card-text.list-card-text
      List( ref="list" @openEditDialog="openEditDialog" )

  Pagination(
    v-model="currentPage"
    :pageCount="pageCount"
  )

  VentasForm(ref="ventasForm" @save="reloadData")
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TitleAndBreadcrumb from '@/components/shared/TitleAndBreadcrumb.vue';
import TopBar from '@/components/ventas/listado/TopBar.vue';
import List from '@/components/ventas/listado/List.vue';
import Pagination from '@/components/prestaciones/listado/Pagination.vue';
import VentasForm from '@/components/ventas/VentasForm.vue'

export default {
  components: { TitleAndBreadcrumb, TopBar, List, Pagination, VentasForm },

  computed: {
    ...mapFields('saleList', [
      'currentPage',
      'pageCount',
    ]),
  },

  watch: {
    currentPage() {
      this.reloadData()
    }
  },

  methods: {
    reloadData() {
      this.$refs.list.loadData()
    },

    openEditDialog(id) {
      this.$refs.ventasForm.open(id)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.list-card-text {
  padding: 0;
  margin-top: 4px;
}
</style>
