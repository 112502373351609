import { updateField } from 'vuex-map-fields'

const mutations = {
  updateField,

  SET_SUMMARY (state, { summary }) {
    state.summary = summary
  },

  CLEAR_SUMMARY (state) {
    state.summary = {
      count: 0,
      total_commission: 0,
    }
  },
}

export default mutations
