/* -------------------------------------------------------------------------- */
/*                         Mixin con estados de venta                         */
/* -------------------------------------------------------------------------- */
export default {
  data() {
    return {
      statusData: [
        {
          value    : 'clear',
          text     : 'Quitar filtro',
          disabled : true,
        },
        {
          value    : 'PAID',
          text     : 'Pagada',
          color    : 'success lighten-5',
          textColor: 'success darken',
        },
        {
          value    : 'NOT_PAID',
          text     : 'No Pagada',
          color    : 'yellow lighten-5',
          textColor: 'yellow darken-2',
        },
        // {
        //   value    : 'CANCELLED',
        //   text     : 'Cancelada',
        //   color    : 'error lighten-5',
        //   textColor: 'error darken',
        // },
      ]
    }
  },

  methods: {
    getStatus(status) {
      return this.statusData.find(s => s.value === status)
    },
  
    getStatusText(status) {
      return this.getStatus(status).text
    },
  
    getStatusColor(status) {
      return this.getStatus(status).color
    },
  
    getStatusTextColor(status) {
      return this.getStatus(status).textColor
    },
  },
}