<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text General
      small.grey--text Fecha de atención: {{ formatDate(medicalRecord.start_date) }}
    v-row.ml-1
      v-col(cols="12")
        h5 Anamnesis
      v-col.py-0(cols="12")
        v-textarea.mr-3(v-model="generalData.anamnesis" outlined dense clearable clear-icon="mdi-close-circle" rows="2" :hide-details="true")

    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Estado Físico
        v-select(v-model="generalData.physical_condition" :items="physicalConditionItems" dense outlined placeholder="Selecciona" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Condición Corporal
        v-select(v-model="generalData.body_condition" :items="bodyConditionItems" dense outlined placeholder="Selecciona" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Estado de las Vacunas
        v-select.mr-3(v-model="generalData.vaccination_status" :items="vaccinationStatusItems" dense outlined placeholder="Selecciona" :hide-details="true")

    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Estado Desparasitaciones
        v-select(v-model="generalData.deworming_status" :items="dewormingStatusItems" dense outlined placeholder="Selecciona" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Comportamiento Social
        v-select(v-model="generalData.social_behavior" :items="socialBehaviorItems" dense outlined placeholder="Selecciona" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Temperamento
        v-select.mr-3(v-model="generalData.temperament" :items="temperamentItems" dense outlined placeholder="Selecciona" :hide-details="true")

    v-row.ml-1
      v-col.py-0.mt-1(cols="12")
        h5 Enfermedades Infecto Contagiosas
      v-col.py-0.mt-1(cols="12")
        v-text-field.mr-3(v-model="generalData.infectious_diseases" outlined dense clearable :hide-details="true")
      v-col.py-0.mt-1(cols="12")
        h5 Lesiones
      v-col.py-0.mt-1(cols="12")
        v-text-field.mr-3(v-model="generalData.injuries" outlined dense clearable :hide-details="true")
      v-col.py-0.mt-1(cols="12")
        h5 Cicatrices
      v-col.py-0.mt-1(cols="12")
        v-text-field.mr-3(v-model="generalData.scars" outlined dense clearable :hide-details="true")
      v-col.py-0.mt-1(cols="12")
        h5 Alergias
      v-col.py-0.mt-1(cols="12")
        v-text-field.mr-3(v-model="generalData.allergies" outlined dense clearable :hide-details="true")
      v-col.py-0.mt-1(cols="12")
        h5 Ración
      v-col.py-0.mt-1(cols="12")
        v-text-field.mr-3(v-model="generalData.portion" outlined dense clearable :hide-details="true")
    v-card-actions.mt-10
      v-row.ml-3.justify-space-between
        v-btn.form-btn.justify-center(color="primary" outlined elevation="0" @click="$emit('previousTab')") Atrás
        v-btn.form-btn.justify-center.mr-4(color="primary" elevation="0" @click="saveData") Guardar y continuar
</template>
<script>
export default {
  props: {
    medicalRecord: {
      type: Object,
      default: () => {
        return {
          anamnesis: '',
          physical_condition: '',
          body_condition: '',
          vaccination_status: '',
          deworming_status: '',
          social_behavior: '',
          temperament: '',
          infectious_diseases: '',
          injuries: '',
          scars: '',
          allergies: '',
          portion: ''
        }
      }
    }
  },
  data () {
    return {
      generalData: {
        anamnesis: this.medicalRecord.anamnesis !== '' ? this.medicalRecord.anamnesis : '',
        physical_condition: this.medicalRecord.physical_condition !== '' ? this.medicalRecord.physical_condition : '',
        body_condition: this.medicalRecord.body_condition !== '' ? this.medicalRecord.body_condition : '',
        vaccination_status: this.medicalRecord.vaccination_status !== '' ? this.medicalRecord.vaccination_status : '',
        deworming_status: this.medicalRecord.deworming_status !== '' ? this.medicalRecord.deworming_status : '',
        social_behavior: this.medicalRecord.social_behavior !== '' ? this.medicalRecord.social_behavior : '',
        temperament: this.medicalRecord.temperament !== '' ? this.medicalRecord.temperament : '',
        infectious_diseases: this.medicalRecord.infectious_diseases !== '' ? this.medicalRecord.infectious_diseases : '',
        injuries: this.medicalRecord.injuries !== '' ? this.medicalRecord.injuries : '',
        scars: this.medicalRecord.scars !== '' ? this.medicalRecord.scars : '',
        allergies: this.medicalRecord.allergies !== '' ? this.medicalRecord.allergies : '',
        portion: this.medicalRecord.portion !== '' ? this.medicalRecord.portion : ''
      },
      physicalConditionItems: [
        { text: 'Normal saludable', value: 'normal' },
        { text: 'Presenta decaimiento', value: 'decayed' },
        { text: 'Enfermo', value: 'sick' },
        { text: 'Enfermo de abandono', value: 'abandoned' }
      ],
      bodyConditionItems: [
        { text: 'Caquéctico', value: 'cachectic' },
        { text: 'Delgado', value: 'slim' },
        { text: 'Normal', value: 'normal' },
        { text: 'Sobrepeso', value: 'overweight' },
        { text: 'Obeso', value: 'obese' }
      ],
      vaccinationStatusItems: [
        { text: 'Vacunado', value: 'vaccinated' },
        { text: 'Sin Vacunar', value: 'unvaccinated' },
        { text: 'Incompletas', value: 'incomplete' },
        { text: 'Vacunas al dia', value: 'update' }
      ],
      dewormingStatusItems: [
        { text: 'Desparacitación interna y externa al dia', value: 'fullDeworming' },
        { text: 'Desparacitacion interna realizada, externa no', value: 'onlyInternalDeworming' },
        { text: 'Desparacitacion externa realizada, interna no', value: 'onlyExternalDeworming' },
        { text: 'Sin desparacitación', value: 'withoutDeworming' }
      ],
      socialBehaviorItems: [
        { text: 'Alegría excesiva', value: 'excessiveJoy' },
        { text: 'Miedo', value: 'afraid' },
        { text: 'Ansiedad', value: 'ansiety' },
        { text: 'Agresividad ofensiva', value: 'offensiveAggressiveness' },
        { text: 'Agresividad Defensiva', value: 'defensiveAggressiveness' },
        { text: 'Agresividad depredadora', value: 'depredativeAgressiveness' },
        { text: 'Conducta territorial', value: 'territorialConduct' },
        { text: 'Conducta afiliativa', value: 'affiliativeConduct' },
        { text: 'Dominancia', value: 'dominance' },
        { text: 'Sumisión', value: 'submission' }
      ],
      temperamentItems: [
        { text: 'Equilibrado', value: 'balanced' },
        { text: 'Linfático', value: 'linfatic' },
        { text: 'Nervioso', value: 'nervious' }
      ]
    }
  },
  methods: {
    saveData () {
      this.$emit('saveAndNext', this.generalData)
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  }
}
</script>
<style scoped>
.form-btn {
  text-transform: none;
}
.v-text-field input {
  padding: 0;
}
.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 30px;
}
</style>
