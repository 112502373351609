<template lang="pug">
v-dialog(v-model="dialog" max-width="1000px")
  v-card
    v-card-title.d-flex.align-center.justify-space-between
      span.black--text {{ title }}
      v-btn.icon(@click="close" icon small)
        v-icon mdi-close
    v-divider.mb-3
    v-card-text
      p.text-left.black--text.text-left.mb-3 Al fusionar pacientes, se eliminará el paciente "{{ pet ? pet.name : '' }}" y se reemplazará por el nuevo paciente seleccionado.
        |  Si el paciente "{{ pet ? pet.name : '' }}" tiene una ficha médica, esta pasará a ser del nuevo paciente. Los datos del paciente final no se verán afectados.
      v-form(ref="form" @submit.prevent="submit")
        label.font-weight-bold.primary--text Buscar Paciente
        v-autocomplete(
          dense outlined
          v-model="otherPet"
          no-filter
          return-object
          :items="[otherPet, ...searchPetResults]"
          item-text="name"
          no-data-text="No se encontraron datos"
          @update:search-input="autocompletePetSearch"
          prepend-inner-icon="mdi-magnify"
          :rules="[v => !!v || 'Este campo es requerido']"
        )
        p.text-left.black--text.text-left(v-if="!otherPet")  Selecciona otro paciente para fusionar con "{{ pet ? pet.name : '' }}"
        v-row(v-else)
          v-col
            h3.black--text.mb-2 Paciente a eliminar
            v-card.rounded-lg(color="#fae1e1" elevation=0)
              v-card-text
                label.font-weight-bold.primary--text Nombre Dueño
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.client && pet.client.name ? pet.client.name : 'No asignado' }}
                label.font-weight-bold.primary--text Rut Dueño
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.client && pet.client.rut ? pet.client.rut : 'No asignado' }}
                label.font-weight-bold.primary--text Nombre
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.name || 'No asignado' }}
                label.font-weight-bold.primary--text Especie
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.specie || 'No asignado' }}
                label.font-weight-bold.primary--text Raza
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.race || 'No asignado' }}
                label.font-weight-bold.primary--text Sexo
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.sex || 'No asignado' }}
                label.font-weight-bold.primary--text Fecha de Nacimiento
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.date_of_birth || 'No asignado' }}
                label.font-weight-bold.primary--text Peso
                p.text-left.black--text.text-left.text-decoration-line-through {{ pet.weight || 'No asignado' }}
                label.font-weight-bold.primary--text Color
                p.ma-0.text-left.black--text.text-left {{ pet.color || 'No asignado' }}
          v-col(cols="auto").d-flex.align-center.justify-center
            v-icon(color="green" x-large) mdi-arrow-right-thick
          v-col()
            h3.black--text.mb-2 Paciente a mantener
            v-card.rounded-lg(color="#F3F6F9" elevation=0)
              v-card-text
                label.font-weight-bold.primary--text Nombre Dueño
                p.text-left.black--text.text-left {{ otherPet.client && otherPet.client.name ? otherPet.client.name : 'No asignado' }}
                label.font-weight-bold.primary--text Rut Dueño
                p.text-left.black--text.text-left {{ otherPet.client && otherPet.client.rut ? otherPet.client.rut : 'No asignado' }}
                label.font-weight-bold.primary--text Nombre
                p.text-left.black--text.text-left {{ otherPet.name || 'No asignado' }}
                label.font-weight-bold.primary--text Especie
                p.text-left.black--text.text-left {{ otherPet.specie || 'No asignado' }}
                label.font-weight-bold.primary--text Raza
                p.text-left.black--text.text-left {{ otherPet.race || 'No asignado' }}
                label.font-weight-bold.primary--text Sexo
                p.text-left.black--text.text-left {{ otherPet.sex || 'No asignado' }}
                label.font-weight-bold.primary--text Fecha de Nacimiento
                p.text-left.black--text.text-left {{ otherPet.date_of_birth || 'No asignado' }}
                label.font-weight-bold.primary--text Peso
                p.text-left.black--text.text-left {{ otherPet.weight || 'No asignado' }}
                label.font-weight-bold.primary--text Color
                p.ma-0.text-left.black--text.text-left {{ otherPet.color || 'No asignado' }}
        v-row(justify="end")
          v-col(cols=6 sm="auto")
            v-btn.rounded-lg(@click="close" color="primary" dark outlined block :loading="loadingSubmit")
              span Cancelar
          v-col(cols=6 sm="auto")
            v-btn.rounded-lg(type="submit" color="primary" dark block :loading="loadingSubmit")
              span Fusionar
</template>

<script>
import { debounce } from 'lodash'

export default {
  data () {
    return {
      loadingSubmit: false,
      dialog: false,
      id: null,
      pet: null,
      otherPet: null,
      searchPetResults: [],
    }
  },
  methods: {
    async open (id) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.id = id
      this.loadingSubmit = false
      const { data: pet, error } = await this.$store.dispatch('pet/getPet', { id })

      if (error) {
        this.$toast.error('Error al obtener el paciente')
        return
      }
      this.pet = { ...pet }
      this.otherPet = null
      this.dialog = true

    },
    close () {
      this.dialog = false
    },
    async autocompletePetSearch (search) {
      const { data, error } = await this.$store.dispatch('pet/autocompletePet', { search })
      if (!error) {
        this.searchPetResults = data.filter(p => p.id !== this.id)
      }
    },
    async submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loadingSubmit = true
      const { error } = await this.$store.dispatch('pet/mergePet', { id: this.id, data: { other_pet_id: this.otherPet.id } })
      this.loadingSubmit = false
      if (!error) {
        this.$toast.success('Paciente fusionado correctamente')
        this.$emit('saved')
        this.close()
      } else {
        this.$toast.error('No se pudo fusionar el paciente')
      }
    }
  },
  computed: {
    title () {
      return `Fusionar Paciente "${this.pet?.name}"`
    }
  },
  created () {
    this.autocompletePetSearch = debounce(this.autocompletePetSearch, 500)
  }
}
</script>
