import axios from '../../plugins/axios'

export const login = ({ commit }, { data }) => {
  return axios.post('login/', data)
    .then(res => {
      commit('SET_USER', res.data)
      return res
    })
    .catch(err => err.response)
}

export const logout = ({ commit }) => {
  return axios.post('logout/')
    .then((res) => {
      commit('SET_USER', null)
      return res
    })
    .catch(err => err.response)
}
