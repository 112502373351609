/* -------------------------------------------------------------------------- */
/*                          Mixin de formato de datos                         */
/* -------------------------------------------------------------------------- */
export default {
  methods: {
    dateTimeToLocaleDate: (datetime) => {
      return new Date(datetime)
      .toLocaleDateString('es-CL', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
    },

    dateTimeToLocaleTime: (datetime) => {
      return new Date(datetime)
      .toLocaleTimeString('es-CL', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })
    },

    numberToPesos: (amount) => {
      let formatter = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
      })
      return formatter.format(amount).replace(/^(\D+)/, '$1 ')
    },

    dateOrStringDateToYMD: (date) => {
      if (date instanceof Date) return date.getDate()
      if (typeof date === 'string') return date.substring(0, 10)
      if (typeof date === 'object') return date.date
      return null
    }
  }
}
