export default {

  primary: {
    base: '#474787',
    lighten1: '#7672B7',
    darken1: '#17205A',
  },

  secondary: {
    base: '#0FB9B1',
    lighten1: '#60ECE3',
    darken1: '#008882',
  },

  default: '#1867C0', // 'Primary' en el diseño
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00',
  
}