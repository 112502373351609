import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const AuthStore = {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('user'))
  },
  getters,
  mutations,
  actions
}

export default AuthStore
