<template lang="pug">
v-form(
  ref="formPaciente"
  v-model="isFormPacienteValid"
  lazy-validation
)
  h3.outer-label.black--text.mb-2 Agrega Paciente

  v-row
    v-col( cols=12 md=4 )
      v-autocomplete(
        dense outlined
        v-model="paciente"
        return-object
        :items="[paciente, ...searchPetResults]"
        item-value="id"
        item-text="name"
        no-data-text="No se encontraron datos"
        :search-input.sync="searchPet",
        @focus="searchPet=''"
      )

    v-col( cols=12 md=4 ).d-flex.justify-end
      v-btn.form-btn.form-btn-inline(
        v-if="!creandoPaciente"
        :disabled="cliente === null"
        color="primary"
        @click="handleNewPaciente"
      )
        | Nuevo paciente
      v-btn.form-btn.form-btn-inline.mr-2(
        v-else
        color="primary"
        @click="handleSavePaciente"
      )
        | Guardar
      v-btn.form-btn.form-btn-inline(
        v-if="creandoPaciente"
        outlined color="warning"
        @click="handleCancelPaciente"
      )
        | Cancelar

  v-row.mt-n6
    v-col( cols=12 md=4 )
      v-subheader.toplabel.req
        | Nombre
      v-text-field(
        ref="fieldNombrePaciente"
        v-model="nombrePaciente"
        :rules="getRules('nombrePaciente')"
        :readonly="!creandoPaciente"
        :filled="!creandoPaciente"
        outlined dense
      )

    v-col( cols=12 md=4 )
      v-subheader.toplabel.req
        | Especie
      v-text-field(
        v-model="especie"
        :rules="getRules('especie')"
        :readonly="!creandoPaciente"
        :filled="!creandoPaciente"
        outlined dense
      )

    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | Raza
      v-text-field(
        v-model="raza"
        :rules="getRules('raza')"
        :readonly="!creandoPaciente"
        :filled="!creandoPaciente"
        outlined dense
      )

  v-row.mt-n6
    v-col( cols=12 md=4 )
      v-subheader.toplabel.req
        | Sexo
      v-select(
        v-model="sexo"
        :items="[{text: 'Hembra', value: 'hembra'}, {text: 'Macho', value: 'macho'}]"
        :rules="getRules('sexo')"
        :readonly="!creandoPaciente"
        :filled="!creandoPaciente"
        placeholder="Seleccione"
        outlined dense
      )

    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | Fecha de Nacimiento)
      DatePicker(
        v-model="fechaNacimiento"
        :rules="getRules('fechaNacimiento')"
        :filled="!creandoPaciente"
        :disabled="!creandoPaciente"
      )

    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | Peso
      v-text-field(
        type="number"
        v-model="peso"
        :rules="getRules('peso')"
        :readonly="!creandoPaciente"
        :filled="!creandoPaciente"
        outlined dense
      )
        template( #append )
          span.pt-1 kg

  v-row.mt-n6
    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | Color
      v-text-field(
        v-model="color"
        :rules="getRules('color')"
        :readonly="!creandoPaciente"
        :filled="!creandoPaciente"
        outlined dense
      )
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields, createHelpers } from 'vuex-map-fields'
import AutoComplete from './AutoComplete.vue';
import DatePicker from './DatePicker.vue';

const { mapFields: mapFormFields } = createHelpers({
  getterType: 'getFormClienteField',
  mutationType: 'updateFormClienteField',
});

export default {
  components: { AutoComplete, DatePicker },

  data() {
    return {
      searchPet: null,
      searchPetResults: [],
      isFormPacienteValid: true,
      pacienteErrorMessages: [],
    }
  },

  watch: {
    paciente(val) {
      if (val === null) {
        this.$refs.formPaciente.reset()
        return
      }

      this.nombrePaciente  = val.name
      this.especie         = val.specie
      this.raza            = val.race
      this.sexo            = val.sex
      this.fechaNacimiento = val.date_of_birth
      this.peso            = val.weight
      this.color           = val.color
    },
    async searchPet (search) {
      console.log('searchPet', search)
      const response = await this.listPets({ search: search, owner_id: this.cliente?.id })
      console.log('response', response)
      this.searchPetResults = response.data.results
    },
  },

  computed: {
    ...mapFields('agendarHora', [
      'isEditando',
      'idCitaEnEdición',
    ]),
    ...mapFormFields('agendarHora', {
      cliente        : 'cliente',
      paciente       : 'paciente',
      nombrePaciente : 'pacienteFields.nombre',
      especie        : 'pacienteFields.especie',
      raza           : 'pacienteFields.raza',
      sexo           : 'pacienteFields.sexo',
      fechaNacimiento: 'pacienteFields.fechaNacimiento',
      peso           : 'pacienteFields.peso',
      color          : 'pacienteFields.color',
      creandoCliente : 'creandoCliente',
      creandoPaciente: 'creandoPaciente',
    }),
  },

  methods: {
    ...mapActions('service', [
      'listPets',
      'createPet',
    ]),

    getRules(field) {
      const rules = {
        nombrePaciente: !this.creandoPaciente ? [] : [
          v => !!v || 'Debe ingresar un nombre',
        ],
        especie: !this.creandoPaciente ? [] : [
          v => !!v || 'Debe ingresar una especie',
        ],
        raza: [],
        sexo: !this.creandoPaciente ? [] : [
          v => !!v || 'Debe seleccionar un sexo',
        ],
        peso: !this.creandoPaciente ? [] : [
          v => (!v || v > 0) || 'El peso debe ser mayor a 0',
        ],
        fechaNacimiento: [],
        color: [],
      }
      return rules[field];
    },

    handleNewPaciente() {
      this.creandoPaciente = true
      this.$refs.formPaciente.reset()
      this.paciente = null
      this.$refs.fieldNombrePaciente.focus()
      this.$emit('startEditing')
    },

    async handleSavePaciente() {
      if (!this.$refs.formPaciente.validate()) return

      this.paciente = {
        name         : this.nombrePaciente,
        specie       : this.especie,
        sex          : this.sexo.toLowerCase(),
        race         : this.raza || '',
        weight       : this.peso || '',
        color        : this.color || '',
        date_of_birth: this.fechaNacimiento,
        owner        : this.cliente.id,
      }

      const data = {
        appointments: [],
        ...this.paciente
      }

      const res = await this.createPet({ data: data })
      if ( res.status >= 200 && res.status < 300 ) {
        this.paciente = res.data
        this.creandoPaciente = false
      } else {
        console.log(res.data)
      }

    },

    handleCancelPaciente() {
      this.creandoPaciente = false
      this.$refs.formPaciente.reset()
      this.$emit('stopEditing')
    },

    resetForm() {
      this.$refs.formPaciente.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
