<template lang="pug">
v-autocomplete(
  v-model="model"
  :items="items"
  :search-input.sync="search"

  return-object
  :item-text="itemTextMethod"
  item-value="id"
  :rules="rules"

  dense outlined
  :loading="isLoading"
  :label="label"
  :no-data-text="emptyText"
  hide-selected

  :disabled="disabled"
  :filled="disabled"
  :error-messages="errorMessages"
  :menu-props="{ closeOnClick: true, closeOnContentClick: true }"

  @focus="handleFocus()"
)
  template( #item="{ item }" )
    | {{itemTextMethod(item)}}
  template(#append-item v-if="!hideButton")
    div.mr-2.ml-2
      v-btn(color="primary" block @click="buttonInfo.event") {{ buttonInfo.title }}

</template>

<script>
export default {
  props: {
    value: Object,
    label: String,
    listMethod: Function,
    payload: Object,
    itemTextMethod: {
      type: Function,
      default: (item) => item,
    },
    disabled: Boolean,
    rules: Array,
    errorMessages: Array,
    hideButton: {
      type: Boolean,
      default: true
    },
    buttonInfo: Object,
    emptyText: {
      type: String,
      default: 'No se encontraron datos'
    },
    syncSearch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      items: [],
      search: null,
      isLoading: false,
      doReload: false,
    }
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    search(val) {
      this.doReload = true
      this.loadData(val)
    },

    payload(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.reloadData()
      }
    }
  },

  methods: {
    async loadData(val) {
      if (this.items && this.items.length > 0 && !this.doReload) return

      if (this.isLoading) return

      this.isLoading = true

      this.listMethod({ search: val }).then(
        response => {
          this.items = response.data
        }
      )
      .catch(err => console.log(err))
      .finally(() => this.isLoading = false)
    },

    reloadData() {
      this.doReload = true
      this.loadData()
    },
    handleFocus () {
      if (!this.syncSearch) {
        console.log(this.syncSearch)
        this.loadData('')
      }
    }
  },
}
</script>

<style lang="" scoped>

</style>
