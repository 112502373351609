import * as actions from './actions'
import mutations from './mutations'
import getters from './getters'

const VentasStore = {
  namespaced: true,
  state: {},
  getters,
  mutations,
  actions
}

export default VentasStore
