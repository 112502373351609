<template lang="pug">
v-menu(
  ref="menu"
  v-model="menu"
  :close-on-content-click="false"
  :return-value.sync="time"

  transition="scale-transition"
  offset-overflow
  nudge-right="40"
  min-width="290px"
  max-width="290px"
)
  template(#activator="{ on, attrs }")
    v-text-field(
      v-model="time"
      :rules="rules"
      v-bind="attrs"
      v-on="on"
      
      :label="label"
      prepend-inner-icon="mdi-clock-time-four-outline"
      readonly outlined dense
    )
  v-time-picker(
    v-if="menu"
    v-model="time"
    :allowed-minutes="allowedMinutes"
    @click:minute="$refs.menu.save(time)"
    
    full-width
    format="24hr"
  )
</template>

<script>
export default {
  
  props: {
    label: String,
    value: String,
    rules: Array,
  },

  data: () => ({
    menu: false,
    allowedMinutes: m => m % 15 === 0,
  }),

  computed: {
    time: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>