<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text Resumen
      small.grey--text Fecha de atención: {{ formatDateDay(medicalRecord.start_date) }}

    v-row.ml-1
      v-col.py-0(cols="12")
        h5 Hallazgos
        v-textarea.mr-3(v-model="resumenData.findings" outlined dense clearable clear-icon="mdi-close-circle" rows="2" :disabled="medicalRecord.is_finished")

      v-col.py-0(cols="12")
        h5 Diagnóstico*
        v-textarea.mr-3(v-model="resumenData.diagnosis" outlined dense clearable clear-icon="mdi-close-circle" rows="2" :rules="[rules.required]" :disabled="medicalRecord.is_finished")

      v-col.py-0(cols="12")
        h5 Tratamiento (Receta)
        v-textarea.mr-3(v-model="resumenData.treatment" outlined dense clearable clear-icon="mdi-close-circle" rows="2" :disabled="medicalRecord.is_finished")

      v-col.py-0(cols="12")
        h5 Recomendaciones
        v-textarea.mr-3(v-model="resumenData.recommendations" outlined dense clearable clear-icon="mdi-close-circle" rows="2")

      v-col.py-0(cols="12")
        h5 Fecha de Control
        v-menu(v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto")
          template(v-slot:activator="{ on, attrs }")
            v-text-field.mr-3(v-model="dateFormated" outlined dense prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on")
          v-date-picker(v-model="resumenData.control_date" :first-day-of-week="1" locale="es-CL" @input="menu = false" no-title scrollable)

    v-card-actions.mt-6
      v-row.ml-2.justify-space-between
        v-btn.form-btn.justify-center(color="primary" outlined elevation="0" @click="$emit('previousTab')") Atrás
        v-btn.form-btn.justify-center.mr-4(color="primary" elevation="0" @click="saveData" :disabled="!checkRequired") Guardar y continuar

</template>
<script>
export default {
  props: {
    medicalRecord: {
      type: Object,
      default: () => {
        return {
          findings: '',
          diagnosis: '',
          treatment: '',
          recommendations: '',
          control_date: '',
        }
      }
    }
  },
  data () {
    return {
      resumenData: {
        findings: this.medicalRecord.findings !== '' ? this.medicalRecord.findings : '',
        diagnosis: this.medicalRecord.diagnosis !== '' ? this.medicalRecord.diagnosis : '',
        treatment: this.medicalRecord.treatment !== '' ? this.medicalRecord.treatment : '',
        recommendations: this.medicalRecord.recommendations !== '' ? this.medicalRecord.recommendations : '',
        control_date: this.medicalRecord.control_date !== '' ? this.medicalRecord.control_date : '',
      },
      menu: false,
      rules: {
        required: v => !!v || 'Campo Obligatorio'
      }
    }
  },
  methods: {
    saveData () {
      this.$emit('saveAndNext', this.resumenData)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatDateDay (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  },
  computed: {
    dateFormated () {
      if (this.resumenData.control_date) {
        return this.formatDate(this.resumenData.control_date)
      }
      return ''
    },
    state () {
      return {
        diagnosis: this.resumenData.diagnosis === '' || this.resumenData.diagnosis === null,
      }
    },
    checkRequired () {
      return Object.values(this.state).every((e) => e !== true)
    }
  }
}
</script>
<style lang="scss" scoped>
.form-btn {
  text-transform: none;
}
</style>
