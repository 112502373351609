<template>
  <div>
    <h1>Vista de Presupuestos</h1>
  </div>
</template>

<script>


export default {
  name: 'Presupuestos',

  components: {

  }
}
</script>

