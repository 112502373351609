<template lang="pug">
v-menu(
  ref="menu"
  v-model="menu"
  :return-value.sync="dates"

  :close-on-content-click="false"
  transition="scale-transition"
  min-width="auto"
  offset-y
)
  template( #activator="{ on, attrs }" )
    v-text-field(
      v-on="on"
      v-bind="attrs"
      v-model="textModel"

      :placeholder="placeholder"
      dense filled hide-details
      readonly
    )
  v-date-picker(
    v-model="dates"
    range
  )
    v-spacer
    v-btn(
      text
      color="primary"
      @click="menu = false"
    )
      v-icon mdi-arrow-left
    v-btn(
      text
      color="error"
      @click="() => {$refs.menu.save([])}"
    )
      | Quitar
    v-btn(
      text
      color="primary"
      @click="$refs.menu.save(dates)"
    )
      | Aceptar
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: Array,
  },

  data() {
    return {
      menu: false,
    }
  },

  computed: {
    textModel() {
      return this.dates ? this.dates.join(" - ") : ''
    },

    dates: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>