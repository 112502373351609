import { getField, updateField } from 'vuex-map-fields'
import axios from '@/plugins/axios';
import settings from '@/../settings';

const state = {
  currentPage: 1,
  itemCount: 0,
  pageCount: 0,
  data: [],
  filter: {
    query: '',
    dates: [],
    saleStatus: null,
  },
  filterQuery: null,
  filterDates: [],
  filterStatus: null,
};

const getters = {
  getField,
};

const mutations = {
  updateField,
};

const actions = {

};

const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default store
