<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text Valores
      small.grey--text Fecha de atención: {{ formatDate(medicalRecord.start_date) }}
    v-divider.mb-3
    h5.ml-2 Productos
    v-data-table(:headers="headers" :items="itemsProducts" :items-per-page="-1" hide-default-footer)
      template(v-slot:item.item="{ item }")
        span(v-if="!item.isNew") {{ item.name }}
        TrueAutoComplete.mt-4(
          v-else
          ref="productField"
          v-model="item.product"
          :listMethod="searchProducts"
          :payloadMethod="(query) => getPayloadProduct(query)"
          :itemTextMethod="(item) => item.name"
          :rules="validationRules"
          :minCharacters="2"
        )
      template(v-slot:item.quantity="{ item }")
        span(v-if="!item.isNew") {{ item.quantity }}
        v-text-field.mt-4(v-else outlined dense type="number" min="1" v-model="item.quantity")
      template(v-slot:item.action="{ item }")
        div
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon v-bind="attrs" v-on="on" @click="deleteProduct(item)" :disabled="medicalRecord.is_finished")
                v-icon mdi-trash-can
            span Eliminar
    v-btn.form-btn.elevation-0(plain color="secondary" @click="addNewProducts" :disabled="medicalRecord.is_finished") + Añadir nuevo producto
    v-divider.my-3
    h5.ml-2 Servicios
    v-data-table(:headers="headers" :items="itemsServices" :items-per-page="-1" hide-default-footer)
      template(v-slot:item.item="{ item }")
        span(v-if="!item.isNew") {{ item.name }}
        TrueAutoComplete.mt-4(
          v-else
          ref="serviceField"
          v-model="item.service"
          :listMethod="searchServices"
          :payloadMethod="(query) => getPayloadProduct(query)"
          :itemTextMethod="(item) => item.name"
          :rules="validationRules"
          :minCharacters="2"
        )
      template(v-slot:item.quantity="{ item }")
        span(v-if="!item.isNew") {{ item.quantity }}
        v-text-field.mt-4(v-else outlined dense type="number" min="1" v-model="item.quantity")
      template(v-slot:item.action="{ item }")
        div
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon v-bind="attrs" v-on="on" @click="deleteService(item)" :disabled="medicalRecord.is_finished")
                v-icon mdi-trash-can
            span Eliminar
    v-btn.form-btn.elevation-0(plain color="secondary" @click="addNewServices" :disabled="medicalRecord.is_finished") + Añadir nuevo servicio
    h5.mt-4 Comentario para ventas
    v-textarea.mr-3(v-if="!medicalRecord.is_finished" v-model="comment_for_sale" outlined dense clearable clear-icon="mdi-close-circle" rows="2")
    p.mt-4.text-left(v-else) {{ comment_for_sale || 'No hay comentario' }}
  small.d-flex.justify-end.mr-3(v-if="medicalRecord.is_finished").red--text Esta ficha ya tiene asociada una venta.
  v-card-actions.card-actions.mt-5
    v-row.ml-3.justify-space-between
      v-btn.form-btn.justify-center(color="primary" outlined elevation="0" @click="$emit('previousTab')") Atrás
      div
        v-btn.form-btn.justify-center.mr-4(color="#7672B7" dark elevation="0" @click="saveData" v-show="!medicalRecord.is_finished") Guardar
        v-btn.form-btn.justify-center.mr-4(color="primary" elevation="0" @click="createSale" :disabled="medicalRecord.is_finished") Finalizar atención
</template>
<script>
import { mapActions } from 'vuex'
import TrueAutoComplete from '@/components/agenda/NuevaHora/TrueAutoComplete.vue'
export default {
  components: { TrueAutoComplete },
  props: {
    medicalRecord: {
      type: Object
    }
  },
  data () {
    return {
      headers: [
        { text: 'Item', value: 'item', align: 'start' },
        { text: 'Cantidad', value: 'quantity', align: 'center', width: '150px', sortable: false },
        { text: 'Acción', value: 'action', align: 'center', sortable: false }
      ],
      itemsProducts: [],
      itemsServices: [],
      comment_for_sale: '',
      validationRules: [
        v => !!v || 'Este campo es requerido'
      ]
    }
  },
  methods: {
    ...mapActions('product', ['searchProducts']),
    ...mapActions('service', ['searchServices']),
    getPayloadProduct (query) {
      return {
        search: query
      }
    },
    getData () {
      return {
        products: this.itemsProducts.map(
          (i) => {
            const hasProductKey = Object.prototype.hasOwnProperty.call(i, 'product')
            return {
              product: hasProductKey ? i.product.id : i.id,
              quantity: i.quantity,
              medical_record: this.medicalRecord.id
            }
          }
        ),
        services: this.itemsServices.map(
          (i) => {
            const hasServiceKey = Object.prototype.hasOwnProperty.call(i, 'service')
            return {
              service: hasServiceKey ? i.service.id : i.id,
              quantity: i.quantity,
              medical_record: this.medicalRecord.id
            }
          }
        )
      }
    },
    saveData () {
      this.$emit('finish', { products: this.getData().products, services: this.getData().services, finishing: false, comment_for_sale: this.comment_for_sale })
    },
    createSale () {
      this.$emit('finish', { products: this.getData().products, services: this.getData().services, finishing: true, comment_for_sale: this.comment_for_sale })
    },
    addNewProducts () {
      this.itemsProducts.push({
        id: null,
        name: '',
        quantity: 1,
        isNew: true
      })
    },
    addNewServices () {
      this.itemsServices.push({
        id: null,
        name: '',
        quantity: 1,
        isNew: true
      })
    },
    deleteProduct (item) {
      const index = this.itemsProducts.indexOf(item)
      if (index > -1) {
        this.itemsProducts.splice(index, 1)
      }
    },
    deleteService (item) {
      const index = this.itemsServices.indexOf(item)
      if (index > -1) {
        this.itemsServices.splice(index, 1)
      }
    },
    fillData () {
      this.comment_for_sale = this.medicalRecord.comment_for_sale
      const vaccines = this.medicalRecord.vaccines_data.map(
        (v) => { return { id: v.id, name: v.name, quantity: 1, isNew: false } }
      )
      const dewormings = this.medicalRecord.dewormings_data.map(
        (d) => { return { id: d.id, name: d.name, quantity: 1, isNew: false } }
      )
      this.itemsProducts = [...vaccines, ...dewormings]
      const surgeries = this.medicalRecord.surgeries_data.map(
        (s) => { return { id: s.id, name: s.name, quantity: 1, isNew: false } }
      )
      const procedures = this.medicalRecord.procedures_data.map(
        (p) => { return { id: p.id, name: p.name, quantity: 1, isNew: false } }
      )
      this.itemsServices = [...surgeries, ...procedures]

      const tempProducts = this.medicalRecord.medical_record_products.map(
        (m) => { return { id: m.product_data.id, name: m.product_data.name, quantity: m.quantity, isNew: false } }
      ).filter(p => {
        return !this.medicalRecord.vaccines.includes(p.id) && !this.medicalRecord.dewormings.includes(p.id)
      })
      this.itemsProducts = this.itemsProducts.concat(tempProducts)

      const tempServices = this.medicalRecord.medical_record_services.map(
        (m) => { return { id: m.service_data.id, name: m.service_data.name, quantity: m.quantity, isNew: false } }
      ).filter(p => {
        return !this.medicalRecord.surgeries.includes(p.id) && !this.medicalRecord.procedures.includes(p.id)
      })
      this.itemsServices = this.itemsServices.concat(tempServices)
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  },
  mounted () {
    this.fillData()
  },
  watch: {
    medicalRecord: {
      handler () {
        this.fillData()
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.form-btn {
  text-transform: none;
}
.card-actions {
  bottom: 10px;
}
</style>
