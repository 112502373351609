<template lang="pug">
v-text-field(
  v-model="model"
  :placeholder="placeholder"
  prepend-inner-icon="mdi-magnify"
  dense filled hide-details
)
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: String,
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>