<template lang="pug">
div
  v-card.pb-4(style="border-radius: 15px;")
    v-card-text
      v-row(align="center" justify="space-between")
        v-col(cols="auto")
          v-text-field(
            style="width: 410px;"
            v-model="search"
            placeholder="Buscar por nombre"
            prepend-inner-icon="mdi-magnify"
            dense filled hide-details
          )
        v-col(cols="auto")
          v-btn( color="primary" block @click="$emit('openEditDialog')").btn
            | Nuevo Precio
    v-data-table(
      :headers="user.is_superuser ? headersSuperuser : headers"
      :items="priceItems"
      disable-sort
      hide-default-footer
      class="table"
      :loading="isLoading"
      :page.sync="currentPage"
      :server-items-length="itemCount"
      @page-count="pageCount = $event"
    )
      <!-- Producto -->
      template( #item.product="{ item }" )
        span.table-bold {{ item.product }}

      <!-- Costo -->
      template( #item.cost="{ item }" )
        | {{numberToPesos(item.cost)}}

      <!-- Porcentaje -->
      template( #item.profit_percentage="{ item }" )
        | {{ item.profit_percentage + '%' }}

      <!-- Precio Total -->
      template( #item.total_price="{ item }" )
        | {{numberToPesos(item.total_price)}}

      <!-- Precio Fragmentado -->
      template( #item.fragmented_unit_price="{ item }" )
        | {{numberToPesos(item.fragmented_unit_price)}}

      <!-- Porcentaje Fragmentado -->
      template( #item.fragmented_profit_percentage="{ item }" )
        | {{ item.fragmented_profit_percentage + '%' }}

      template( #item.actions="{ item }" )
        v-btn(text @click.stop="openDeleteDialog(item.id)").action-btn
          v-icon mdi-delete
  .d-flex.justify-end.mt-4
    v-pagination(
      v-model="currentPage"
      :length="pageCount"
      :total-visible="7"
    )
  // Confrma eliminar
  v-row(justify='center')
    v-dialog(v-model='dialogEliminar' max-width='290')
      v-card
        v-card-title.headline Eliminar
        v-card-text Seguro que desea eliminar este precio?
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='deleteEvent') Eliminar
          v-btn(color='green darken-1' text='' @click='dialogEliminar = false') Cancelar

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormatterMixin from '@/components/mixins/FormatterMixin'

export default {
  mixins: [FormatterMixin],
  components: { },
  data () {
    return {
      search: '',
      itemCount: 0,
      currentPage: 1,
      pageCount: 1,
      pageSize: 10,
      isLoading: true,
      priceItems: [],
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Producto', value: 'product' },
        { text: 'Costo', value: 'cost' },
        { text: '% Utilidad', value: 'profit_percentage' },
        { text: 'IVA', value: 'iva' },
        { text: 'Precio Manual', value: 'manual' },
        { text: 'Precio Total', value: 'total_price' },
        { text: 'Precio Frag.', value: 'fragmented_unit_price' },
        { text: 'Acciones', value: 'actions' }
      ],
      headersSuperuser: [
        { text: 'id', value: 'id' },
        { text: 'Producto', value: 'product' },
        { text: 'Costo', value: 'cost' },
        { text: '% Utilidad', value: 'profit_percentage' },
        { text: 'IVA', value: 'iva' },
        { text: 'Precio Manual', value: 'manual' },
        { text: 'Precio Total', value: 'total_price' },
        { text: 'Precio Frag.', value: 'fragmented_unit_price' },
        { text: '% Utilidad Frag.', value: 'fragmented_profit_percentage' },
        { text: 'Acciones', value: 'actions' }
      ],
      dialogEliminar: false,
      deleteId: null,
      currentPage: 1,
    }
  },
  methods: {
    ...mapActions('precios', [
      'listPrice', 'deleteProductPrice'
    ]),

    async loadData () {
      this.isLoading = true
      const params = {
        page: this.currentPage,
        page_size: this.pageSize,
        search: this.search
      }
      const { data } = await this.listPrice(params)
      const { results, count } = data
      this.itemCount = count
      this.priceItems = results.map(this.mapPrice)
      this.isLoading = false
    },

    mapPrice (p) {
      return {
        id: p.id,
        product: p.product.name,
        cost: p.net_cost,
        profit_percentage: p.profit_percentage,
        iva: p.iva ? 'Si' : 'No',
        manual: p.manual ? 'Si' : 'No',
        total_price: p.total_price,
        fragmented_unit_price: p.fragmented_unit_price,
        fragmented_profit_percentage: p.fragmented_profit_percentage,
        actions: null
      }
    },

    openDeleteDialog (id) {
      this.deleteId = id
      this.dialogEliminar = true
    },

    async deleteEvent () {
      const response = await this.deleteProductPrice(this.deleteId)
      if (response && response.status >= 200 && response.status < 300) {
        this.dialogEliminar = false
        this.loadData()
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  watch: {
    search () {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.loadData()
      }
    },
    currentPage () {
      this.loadData()
    }
  },

  created () {
    this.loadData()
  }
}
</script>
