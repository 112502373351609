<template lang="pug">
v-row( align="center" )

  v-col( cols="4" )
    SummaryBar
  
  v-spacer

  v-col( cols="3" )
    StaffFilter(
      placeholder="Profesional"
      v-model="filterStaff"
    )

  v-col( cols="3" )
    DateRangeFilter(
      placeholder="Rango de Fechas"
      v-model="filterDates"
    )

  v-col( cols="2" )
    StatusFilter(
      placeholder="Estado"
      v-model="filterStatus"
    )

</template>

<script>
import { mapFields } from 'vuex-map-fields';
import StaffFilter from './StaffFilter.vue';
import DateRangeFilter from './DateRangeFilter.vue';
import StatusFilter from './StatusFilter.vue';
import SummaryBar from './SummaryBar.vue';
export default {
  components: { StaffFilter, DateRangeFilter, StatusFilter, SummaryBar },

  computed: {
    ...mapFields('prestaciones', [
      'filterStaff',
      'filterDates',
      'filterStatus',
    ]),

    trigger() {
      const { filterStaff, filterDates, filterStatus } = this
      return { filterStaff, filterDates, filterStatus }
    }
  },

  watch: {
    trigger: {
      handler() {
        this.$emit('queryChange')
      },
      deep: true,
    }
  },
}
</script>

<style lang="" scoped>

</style>