<template lang="pug">
div
  v-simple-table
    template(#default)
      thead
        tr
          th.text-left(v-for="header in headers" ) {{header}}
      tbody.mt-4
        tr(v-for="(item, index) in productItems" :key="index")
          td {{ item.product_data && item.product_data.id }}
          td
            v-autocomplete(
              v-model="item.category"
              :items="categoriesOptions"
              item-text="name"
              item-value="id"
              outlined dense :hide-details="hideDetails"
              @change="autocompleteProductSearch('', index)"
              no-filter
            )
          td
            v-autocomplete(
              v-model="item.product_data"
              :items="[item.product_data, ...productAutocompleteResults[index]]"
              item-text="name"
              return-object
              no-filter
              outlined dense :hide-details="hideDetails"
              @change="item.category = $event ? $event.category : item.category"
              @update:search-input="autocompleteProductSearch($event, index)"
            )
          td
            v-switch.mt-0(v-model="item.is_fragmented_sale" :hide-details="hideDetails")
          td {{getUnit(item)}}
          td {{getPrice(item) | currency}}
          td
            v-text-field(
              v-model="item.quantity"
              type="number" outlined dense
              :hide-details="hideDetails"
              min=1
              :rules="getQuantityRules(item, item.product_data)"
              :error-messages="stockMessage(item)"
              :ref="`quantity${index}`"
              @blur="$refs[`quantity${index}`][0].validate()"
              :class="{'warning-error': stockMessage(item)}"
            )
          td {{getTotalPrice(item) | currency}}
          td
            v-btn(icon @click="removeProductSlot(index)" v-if="!readonly" color="#F2416C")
              v-icon(color="#F2416C") mdi-close-circle
  v-row
    v-col
      v-btn.mt-4(v-if="!readonly" text @click="addProductSlot" color="primary")
        v-icon mdi-plus-box
        span  Agregar producto
    v-col(cols="auto")
      .mt-4(style="margin-right: 100px")
        strong {{totalPrice | currency}}
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from 'lodash'
import TrueAutoComplete from '@/components/agenda/NuevaHora/TrueAutoComplete.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['value', 'readonly', 'filterArea'],
  components: {
    TrueAutoComplete,
  },
  data () {
    return {
      hideDetails: false,
      headers: [
        'Código',
        'Categoría Producto',
        'Nombre Producto',
        'Venta fragmentada',
        'Unidad de Medida',
        'Precio Unitario',
        'Cantidad',
        'Precio Total',
        ''
      ],
      productItems: this.value,
      productAutocompleteResults: {},
      categoriesOptions: [],
      dataProduct: [],
      dataCategory: [],
      rules: {
      },
      validationRules: [
        v => !!v || 'Este campo es requerido'
      ],

    }
  },
  methods: {
    ...mapActions('product', ['searchCategories']),

    autocompleteProductSearch() {},

    getPrice (item) {
      const { product_data } = item
      if (!product_data) return 0
      return item.is_fragmented_sale ? product_data?.fragmented_unit_price : product_data.price
    },
    getUnit (item) {
      const { product_data } = item
      if (item.is_fragmented_sale) {
        return product_data ? `1${product_data.unit}` : '-'
      }
      return product_data ? `${product_data.purchase_size}${product_data.unit}` : '-'
    },
    getTotalPrice (item) {
      const { product_data } = item
      return product_data ? this.getPrice(item) * item.quantity : 0
    },
    addProductSlot () {
      this.productItems.push({ quantity: 1, is_fragmented_sale: false, category: null })
    },
    removeProductSlot (index) {
      this.productItems.splice(index, 1)
    },
    getStock (item) {
      const { product_data } = item
      if (item.is_fragmented_sale) {
        return product_data ? product_data.fragmented_stock : null
      }
      return product_data ? product_data.stock : null
    },

    required: (v) => !!v || 'Este campo es requerido',

    notZero: (v) => v > 0 || 'Debe ser mayor a 0',

    getQuantityRules (item) {
      return [
        this.required,
        this.notZero,
        this.inStock(item)
      ]
    },

    inStock (item) {
      return (v) => {
        const stock = this.getStock(item)
        if (item.product_data) {
          const quantityByProduct = this.quantityByProduct[item.product_data.id]
          console.log('quantityByProduct', quantityByProduct)
          return stock == null || stock >= quantityByProduct || `Stock insuficiente: ${stock}`
        }

        return false
      }
    },

    stockMessage (item) {
      const stock = this.getStock(item)
      if (stock == null || item.quantity <= 0) return ''
      return stock <= 10 && stock >= parseInt(item.quantity) ? `Stock actual: ${stock}` : ''
    }
  },
  computed: {
    ...mapGetters('ventas', ['quantityByProduct']),
    totalPrice () {
      let totalPrice = 0
      for (const item of this.productItems) {
        totalPrice += this.getTotalPrice(item)
      }
      return totalPrice
    },
  },
  watch: {
    value () {
      this.productItems = this.value
    },
    productItems: {
      deep: true,
      handler () {
        this.$emit('input', this.productItems)
        this.$emit('change', this.productItems)
        this.$emit('totalPrice', this.totalPrice)
      }
    }
  },
  async created () {
    this.categoriesOptions = (await this.searchCategories()).data
    this.$emit('totalPrice', this.totalPrice)

    this.autocompleteProductSearch = debounce(async (search, index) => {
      const params = { search }
      if (this.productItems[index].category) {
        params.category = this.productItems[index].category
      }
      if (this.filterArea) {
        params.area = this.filterArea
      }
      const { data, error } = await this.$store.dispatch('product/autocompleteProducts', params)
      if (!error) {
        this.productAutocompleteResults[index] = data
        this.productAutocompleteResults = { ...this.productAutocompleteResults }
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
td {
  padding-top: 6px !important;
}

::v-deep .warning-error .v-messages__message {
  color: #ff9800 !important;
}
</style>
