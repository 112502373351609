<template lang="pug">
  .resumen-venta
    v-container
      .mb-5
        strong Total
      v-row
        v-col(cols=8)
          div Total Productos
        v-col(cols=4)
          | {{productTotalPrice | currency}}
        v-col(cols=8)
          div Total Servicios
        v-col(cols=4)
          | {{serviceTotalPrice | currency}}
        v-col.mb-1(cols=8)
          strong Total
        v-col(cols=4)
          | {{serviceTotalPrice + productTotalPrice | currency}}
</template>

<script>
export default {
  props: ['serviceTotalPrice', 'productTotalPrice']
}
</script>

<style lang="scss" scoped>
.resumen-venta {
  background: #EDEDF3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  max-width: 390px;
}
</style>