import axios from '../../plugins/axios'

export const searchClient = (_, params) => {
  return axios.get('service/client/', { params })
    .catch(err => err.response)
}

export const autocompleteClient = (_, params) => {
  return axios.get('service/client/autocomplete/', { params })
    .then(response => ({ data: response.data, error: null }))
    .catch(error => ({ data: [], error }))
}

export const createClient = (_, { data }) => {
  return axios.post('service/client/', data)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}

export const updateClient = (_, { id, data }) => {
  return axios.put(`service/client/${id}/`, data)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}