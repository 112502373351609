<template lang="pug">
v-card.mx-auto.card-custom
  v-card-text.text-card-custom
    p.text-h6.text--primary.text-custom
      | Bienvenido a la Herramienta de Gesti&oacute;n de Pachy Salazar
    p.text-custom
      | Por favor ingresa con las credenciales que te fueron asignadas
  v-form(@submit.prevent="onSubmit").ml-9.mr-9
        p.input-custom
        | Usuario
        v-text-field.input-login(v-model="form.username" type='text' outlined='' dense='')
        p.input-custom
        | Contraseña
        v-text-field.input-login(v-model="form.password" type='password' outlined='' dense='').input-password
        v-row.custom-center-btn
          v-btn.text-capitalize.btnIngresar(color='#474787' type="submit" dark='' width='150' height='48')
              | ingresar
</template>
<script>
  import { mapActions } from 'vuex'
  export default {
    data () {
      return {
        error: null,
        form: {
          username: null,
          password: null
        }
      }
    },
    methods: {
      ...mapActions('auth', ['login']),
      async onSubmit () {
        this.error = null
        const res = await this.login({ data: this.form })
        console.log(res)
        if (res.status === 200) {
          this.$router.push('/')
        } else {
          this.error = res.data
          alert('Usuario o contraseña incorrecta')
          location.reload();
        }
      }
    }
  }
</script>
<style>

.text-card-custom{
  margin-top: 15%; 
  text-align: center !important;
  justify-content: center;
  align-items: center;
}
.card-custom{
  margin-top: 5% !important;
  max-width: 460px;
  height: 375px;
  border-radius: 10px !important;
  
}
/* texto primario */
.theme--light.v-application .text--primary {
  margin-top: 2%;
  color: #474787 !important;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-25)/var(--unnamed-line-spacing-27) var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 25px/27px Roboto;
  letter-spacing: 0px;
  opacity: 1;
}
/* texto secundario */
.v-application p {
  color: #A1A7B5;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 15px/17px Roboto;
  letter-spacing: 0px;
  color: #A1A7B5;
  opacity: 1;
}
.btnIngresar{
  border-radius: 10px;

}

/* texto formulario */
.theme--light.v-card {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 15px/17px Roboto;
  letter-spacing: 0px;
  color: #474787;
  opacity: 1;
}
.custom-center-btn{
  margin-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-custom{
  margin-top: -6% !important;
}
</style>