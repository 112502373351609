<template lang="pug">
.d-flex.align-center
  span.text-h6.font-weight-bold.mr-6 {{label}}
  span.text-h4 |
  v-breadcrumbs(
    :items="items"
  ) 
</template>

<script>
  export default {
    props: {
      label: String,
    },
    
    data() {
      return {
        items: [
          {
            text: 'Dashboard',
            disabled: false,
            href: '/',
          },
          {
            text: this.$route.name,
            disabled: false,
            href: this.$route.path,
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>