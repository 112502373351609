import * as actions from './actions'
import mutations from './mutations'

const ProductStore = {
  namespaced: true,
  state: {
    quantityByProduct: {},
  },
  getters: {
    quantityByProduct: state => state.quantityByProduct,
  },
  mutations,
  actions
}

export default ProductStore
