<template lang="pug">
v-col(cols="12" sm="12" md="4" v-if="labels && labels.product").pl-4.pl-md-9.pt-0
  v-row.d-md-none.mb-4
    v-divider

  v-row.mb-12.mt-0.flex-column
    span.toplabel Precio Actual Fragmentado
    label.mt-2.label-grey {{ labels.product.fragmented_unit_price | currency }}

  v-row(justify="space-between").mb-5
    span.label-black Precio Unidad Fragmentada
    span.label-grey 1 {{ labels.product.unit }}

  v-row.flex-column.mb-13.mb-sm-4
    .toplabel.mb-2 Base Referencia
    label.mt-2.label-grey {{ referenceBaseFragmentedPrice | currency }}

  v-row.flex-column
    v-col(cols="12" sm="5" md="6").flex-column.pa-0.mt-md-9.mt-sm-4
      label(for="manual_price_fragmented").toplabel Precio Manual
      v-text-field(
        type="number"
        outlined dense placeholder="Ingrese Precio"
        id="manual_price_fragmented"
        prepend-icon="mdi-currency-usd"
        v-model.number="form.fragmented_unit_price"
        :rules="validationRules"
      ).mt-2

      span.label-italic Utilidad
      v-icon(color="secondary") mdi-arrow-top-right
      span.label-secondary {{ profitPercentageFragmentedManual }}%
</template>

<script>

export default {
  props: ['labels', 'form'],
  data () {
    return {
      validationRules: [
        v => (!!v || v === 0) || 'Este campo es requerido',
        v => v >= 0 || 'Este campo debe ser mayor o igual a 0'
      ]
    }
  },
  methods: {
    setFragmentedPriceSubFormData () {
      this.form.fragmented_profit_percentage = this.profitPercentageFragmentedManual
    }
  },
  computed: {
    referenceBaseFragmentedPrice () {
      return this.form.net_cost / this.labels.product.purchase_size | 0
    },
    profitPercentageFragmentedManual () {
      const profitPercentFragmentedManual = ((this.form.fragmented_unit_price - this.referenceBaseFragmentedPrice) / this.referenceBaseFragmentedPrice) * 100
      return Math.round(profitPercentFragmentedManual) | 0
    }
  }
}
</script>
