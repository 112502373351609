<template lang="pug">
v-container.rounded.elevation-1(style="background-color: #fff; padding: 29px;")
  v-row.mb-2(justify="space-between" align="center")
    v-col(cols="12" md="auto")
      h2 Historial de Paciente
    v-col(cols="12" md="auto")
      v-btn.mr-2.rounded-lg(color="primary" @click="printHistorial")
        v-icon.mr-2 mdi-printer
        | Imprimir Historial
      v-btn.rounded-lg(color="primary" @click="$refs.modalPatientFile.open()") + Nueva Ficha
  ListaTratamientos.mt-8(ref="listaTratamientos")
  ModalFichaPaciente(ref="modalPatientFile" @reloadData="fetchData()" :petId="+$route.params.idPaciente")
</template>
<script>
import ListaTratamientos from './ListaTratamientos.vue'
import settings from '../../../../settings'
import ModalFichaPaciente from '../ModalFichaPaciente.vue'

export default {
   components: {
    ListaTratamientos,
    ModalFichaPaciente
  },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    printHistorial () {
      window.open(settings.API_URL + '/api/service/medical_record/pdf_historical/?pet_id=' + this.$route.params.idPaciente, '_blank')
    },
    fetchData () {
      this.$refs.listaTratamientos.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.tabsTreatment {
  background-color: #F3F6F9;
  padding: 30px;
  border-radius: 5px;
  margin-top: 20px;
}
</style>
