<template lang="pug">
div
  v-simple-table
    template(#default)
      thead
        tr
          th.text-left(v-for="header in headers" ) {{header}}
      tbody.mt-4
        tr(v-for="(item, index) in serviceItems").tr-class
          td {{ item.service_data && item.service_data.id }}
          td
            v-autocomplete(
              v-model="item.category"
              :items="categoriesOptions"
              item-text="name"
              item-value="id"
              outlined dense
              @change="autocompleteServiceSearch('', index)"
              no-filter
            )
          td
            .d-flex.align-baseline
              v-autocomplete(
                ref="serviceAutocomplete"
                v-model="item.service_data"
                :items="[item.service_data, ...serviceAutocompleteResults[index]]"
                item-text="name"
                return-object
                no-filter
                outlined dense :hide-details="hideDetails"
                @change="item.category = $event ? $event.category : item.category"
                @update:search-input="autocompleteServiceSearch($event, index)"
              )
              ServiceProductsTooltip(
                v-if="item.service_data && item.service_data.product_count > 0"
                :productsInService="item.service_data.product_data"
              )
          td
            v-autocomplete(
              v-model="item.professional"
              :items="prefessionalOptions"
              item-text="full_name"
              item-value="id"
              :rules="validationRules"
              outlined dense
            )
          td
            v-text-field(v-model="item.quantity" type="number" outlined dense :hide-details="hideDetails" min=1 :rules="[required, getStockRules(item)]" )
          td {{getTotalPrice(item) | currency}}
          td
            v-btn(icon @click="removeSlot(index)" color="#F2416C")
              v-icon(color="#F2416C") mdi-close-circle
  v-row
    v-col
      v-btn.mt-4(v-if="!readonly" text @click="addSlot" color="primary")
        v-icon mdi-plus-box
        span  Agregar servicio
    v-col(cols="auto")
      .mt-4(style="margin-right: 100px")
        strong {{totalPrice | currency}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import ServiceProductsTooltip from './ServiceProductsTooltip.vue'
import TrueAutoComplete from '@/components/agenda/NuevaHora/TrueAutoComplete.vue'

export default {
  props: ['value', 'readonly', 'filterArea'],
  components: { ServiceProductsTooltip, TrueAutoComplete },
  data () {
    return {
      hideDetails: false,
      headers: [
        'Código',
        'Categoría Servicio',
        'Nombre Servicio',
        'Profesional',
        'Cantidad',
        'Precio Total',
        ''
      ],
      serviceItems: this.value,
      serviceAutocompleteResults: {},
      serviceOptions: [],
      prefessionalOptions: [],
      categoriesOptions: [],
      dataService: [],
      dataCategory: [],
      validationRules: []
    }
  },
  methods: {
    ...mapActions('service', ['listServiceCategories']),
    ...mapActions('user', ['listProfessional']),

    autocompleteServiceSearch() {},

    getServiceById (id) {
      return this.serviceOptions.find(el => el.id === id)
    },
    serviceList (item) {
      if (this.filterArea && !item.category) {
        const services = this.serviceOptions.filter((a) => a.area === this.filterArea)
        const service = this.dataService.concat(services)
        return service ? service : ''
      }
      if (this.filterArea) {
        const services = this.serviceOptions.filter((a) => a.area === this.filterArea).filter((s) => s.category === item.category)
        const service = this.dataService.concat(services)
        return service ? service : ''
      }
      return this.serviceOptions
    },
    categoriesList (item) {
      const service = this.getServiceById(item.service)
      if (!service) {
        return this.categoriesOptions
      }
      const categories = this.categoriesOptions.find(c => c.id === service.category)
      const category = this.dataCategory.concat(categories)
      return category ? category : ''
    },
    getTotalPrice (item) {
      return (item?.service_data?.total_price || 0) * item.quantity
    },
    addSlot () {
      this.serviceItems.push({ service: null })
    },
    removeSlot (index) {
      this.serviceItems.splice(index, 1)
    },
    required: (v) => !!v || 'Este campo es requerido',
    getStockRules (item) {
      // TODO: Verificar venta fragmentada
      const productsWithoutStock = []

      if (item.service_data) {
        for (const product of item.service_data.product_data) {
          if (this.quantityByProduct[product.product.id] > product.product.stock) {
            productsWithoutStock.push(product.product.name)
          }
        }
      }
      if (productsWithoutStock.length > 0) {
        return (v) => 'No hay stock disponible para ' + productsWithoutStock.join(', ')
      }
      return (v) => true
    },
  },
  computed: {
    ...mapGetters('ventas', ['quantityByProduct']),
    totalPrice () {
      let totalPrice = 0
      for (const item of this.serviceItems) {
        totalPrice += this.getTotalPrice(item)
      }
      return totalPrice
    },
  },
  watch: {
    value () {
      this.serviceItems = this.value
    },
    serviceItems: {
      deep: true,
      handler () {
        this.$emit('input', this.serviceItems)
        this.$emit('change', this.serviceItems)
        this.$emit('totalPrice', this.totalPrice)
      }
    }
  },
  async created () {
    this.categoriesOptions = (await this.listServiceCategories()).data
    this.prefessionalOptions = (await this.listProfessional()).data
    this.$emit('totalPrice', this.totalPrice)

    this.autocompleteServiceSearch = debounce(async (search, index) => {
      const params = { search }
      if (this.serviceItems[index].category) {
        params.category = this.serviceItems[index].category
      }
      const { data, error } = await this.$store.dispatch('service/autocompleteServices', params)
      if (!error) {
        this.serviceAutocompleteResults[index] = data
        this.serviceAutocompleteResults = { ...this.serviceAutocompleteResults }
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
td {
  padding-top: 6px !important;
}
</style>
