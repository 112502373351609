<template lang="pug">
v-row
  v-col
    div Servicios prestados:
    div.text-h6
      | {{count}}
  v-col
    div Comisión total:
    div.text-h6
      | {{numberToPesos(total_commission)}}
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import FormatterMixin from '../../mixins/FormatterMixin'
export default {
  mixins: [ FormatterMixin ],

  computed: {
    ...mapFields('prestaciones', [
      'summary.count',
      'summary.total_commission',
    ]),
  },
}
</script>

<style lang="scss" scoped>

</style>