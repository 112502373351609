import axios from '../../plugins/axios'

export const listProfessional = (_, params) => {
  return axios.get('service/professional/', { params })
    .catch(err => err.response)
}

export const autocompleteProfessional = (_, params) => {
  return axios.get('service/professional/autocomplete/', { params })
    .then(res => ({ data: res.data, error: null }))
    .catch(err => ({ data: [], error: err.response }))
}
