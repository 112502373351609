<template lang="pug">
div
  v-btn.normal-caps.mt-4(
    :loading="isLoading"
    color="primary" height="32px"
    @click="handleGetSalaryReport"
  )
    | Generar Informe

  v-snackbar(
    v-model="toast"
    :timeout="4200"
  )
    | {{ toastText }}
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
      toast: null,
      toastText: '',
    }
  },

  computed: {
    ...mapFields('prestaciones', [
      'filterStaff',
      'filterDates',
      'filterStatus',
    ]),
  },

  methods: {
    ...mapActions('prestaciones', [
      'getSalarySlip',
    ]),

    async handleGetSalaryReport() {
      const { filterDates: dates, filterStatus: status, filterStaff: professional } = this

      // if (!professional) return // TODO: TOAST
      // if (!dates[0] || !dates[1]) return // TODO: ANOTHER TOAST

      const params = {
        sale__created_at__lte: dates[1] ? `${dates[1]}T23:59:59` : null,
        sale__created_at__gte: dates[0] ? `${dates[0]}T00:00:00` : null,
        sale__status         : status,
        professional         : professional ? professional.id : null,
      }

      this.isLoading = true

      const res = await this.getSalarySlip({ params })

      if (res.status === 200) {
        window.open(URL.createObjectURL(res.data))
        this.isLoading = false
      } else {
        this.toastText = await res.data.text()
        this.toast = true
        setTimeout(() => {this.isLoading = false}, 3800)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 140px;
}
</style>