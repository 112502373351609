import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import product from './product'
import agendarHora from './agendarHora'
import service from './service'
import ventas from './ventas'
import client from './client'
import saleList from './saleList'
import prestaciones from './prestaciones'
import user from './user'
import precios from './precios'
import room from './room'
import pet from './pet'
import medicalRecord from './medicalRecord'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    product,
    agendarHora,
    service,
    ventas,
    client,
    saleList,
    prestaciones,
    user,
    precios,
    room,
    pet,
    medicalRecord
  }
})
