<template lang="pug">
v-chip.status-chip(
  :color="getStatusColor(value)"
  :text-color="getStatusTextColor(value)"
  :class="['elevation-1']"
  small
)
  | {{ getStatusText(value) }}
</template>

<script>
import StatusDataMixin from '../mixins/StatusDataMixin'
export default {
  mixins: [StatusDataMixin],
  props: {
    value: String,
  },
}
</script>

<style lang="scss" scoped>

</style>