<template lang="pug">

v-row( align="center" )

  v-col( cols="4" )
    TextFilter(
      placeholder="Buscar por ID, cliente o RUT"
      v-model="filterQuery"
    )

  v-col( cols="2" )
    DateRangeFilter(
      placeholder="Rango de Fechas"
      v-model="filterDates"
    )

  v-col( cols="2" )
    StatusFilter(
      placeholder="Estado"
      v-model="filterStatus"
    )
  v-col( cols="2" )
    v-menu(offset-y)
      template(#activator="{ on, attrs}")
        v-btn.btn(color="primary" block v-bind="attrs" v-on="on") Exportar a Excel
      v-list
        v-list-item(@click="getExcel(getSalesReport)") Ventas
        v-list-item(@click="getExcel(getProfitsReport)") Utilidades
  v-col( cols="2" )
    v-btn( color="primary" block @click="$emit('openEditDialog')" ).btn
      | Nueva Venta
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import DateRangeFilter from '../../prestaciones/listado/DateRangeFilter.vue';
import StatusFilter from '../../prestaciones/listado/StatusFilter.vue';
import TextFilter from './TextFilter.vue';
import settings from '@/../settings'
export default {
  components: { DateRangeFilter, StatusFilter, TextFilter },
  computed: {
    ...mapFields('saleList', [
      'filterQuery',
      'filterDates',
      'filterStatus',
    ]),



    trigger() {
      const { filterQuery, filterDates, filterStatus } = this
      return { filterQuery, filterDates, filterStatus }
    }
  },

  watch: {
    trigger: {
      handler() {
        this.$emit('queryChange')
      },
      deep: true,
    }
  },
    methods: {
      ...mapActions('ventas', [
        'getSalesReport',
        'getProfitsReport'
      ]),
      async getExcel(action){
        const fileDownload = require('js-file-download')
        const { filterDates: dates, filterStatus: status} = this
        const params = {
          created_at__lte: dates[1] ? `${dates[1]}T23:59:59` : null,
          created_at__gte: dates[0] ? `${dates[0]}T00:00:00` : null,
          status         : status
        }
        const res =  await action({params})//'http://127.0.0.1:8000/api/order/sale/export_ventas/'
        console.log(res.headers);
        console.log(res.headers['content-disposition'])
        const filename =
          /filename="(?<filename>.*)"/.exec(res.headers["content-disposition"])
          ?.groups?.filename ?? "Reporte.xlsx";
        fileDownload(res.data, filename)
      },
    },
}
</script>

<style lang="scss" scoped>
.btn {
  text-transform: none !important;
  border-radius: 10px;
  height: 40px !important;
}
</style>
