<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text Procedimientos Quirúrgicos
      small.grey--text Fecha de atención: {{ formatDate(medicalRecord.start_date) }}

    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="6")
        h5 Cirugías
        small.grey--text.mb-3 Seleccionar (puede ser más de una)
        v-checkbox(v-for="c in cirugias" :key="c.id" v-model="surgeries" :value="c.id" dense multiple hide-details :disabled="medicalRecord.is_finished")
          template(v-slot:label)
            span.checkboxLabel {{ c.name }}

      v-col.d-flex.justify-start.flex-column(cols="6")
        h5 Procedimientos
        small.grey--text.mb-3 Seleccionar (puede ser más de una)
        v-checkbox(v-for="p in procedimientos" :key="p.id" v-model="procedures" :value="p.id" dense multiple hide-details :disabled="medicalRecord.is_finished")
          template(v-slot:label)
            span.text-capitalize {{ p.name }}

    v-card-actions.mt-6
      v-row.ml-3.justify-space-between
        v-btn.form-btn.justify-center(color="primary" outlined elevation="0" @click="$emit('previousTab')") Atrás
        v-btn.form-btn.justify-center.mr-4(color="primary" elevation="0" @click="saveData") Guardar y continuar
</template>
<script>
export default {
  props: {
    medicalRecord: {
      type: Object,
      default: () => {
        return {
          surgeries: [],
          procedures: []
        }
      }
    },
    cirugias: {
      type: Array
    },
    procedimientos: {
      type: Array
    }
  },
  data () {
    return {
      surgeries: this.medicalRecord.surgeries.length > 0 ? this.medicalRecord.surgeries : [],
      procedures: this.medicalRecord.procedures.length > 0 ? this.medicalRecord.procedures : []
    }
  },
  methods: {
    /**
     * El metodo GetData() se hizo porque es necesario actualizar medical_record_services
     * porque al modificar algun checkbox no se estaba actualizando en la pestaña de valores.
     */
    getData () {
      // Se recuperan los category ID
      const surgerieCategory = this.cirugias[0].category
      const procedureCategory = this.procedimientos[0].category
      // Se filtra medical_record_services por ID de categoría cirugia y por las cirugias que no fueron seleccionadas en el checkbox
      const tempSurgeries = this.medicalRecord.medical_record_services.filter(s => {
        return s.service_data.category === surgerieCategory && !this.surgeries.includes(s.service_data.id)
      }).map(s => s.service_data.id)
      // Se filtra medical_record_services por ID de categoría procedimiento y por los procedimientos que no fueron seleccionados en el checkbox
      const tempProcedures = this.medicalRecord.medical_record_services.filter(s => {
        return s.service_data.category === procedureCategory && !this.procedures.includes(s.service_data.id)
      }).map(s => s.service_data.id)
      // Se filtra medical_record_service y retornamos los servicios actualizados.
      return this.medicalRecord.medical_record_services.filter(
        s => {
          return !tempSurgeries.includes(s.service_data.id) && !tempProcedures.includes(s.service_data.id)
        }
      )
    },
    saveData () {
      if (this.medicalRecord.medical_record_services.length > 0) {
        this.$emit('saveAndNext', { surgeries: this.surgeries, procedures: this.procedures, medical_record_services: this.getData() })
      } else {
        this.$emit('saveAndNext', { surgeries: this.surgeries, procedures: this.procedures })
      }
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  }
}
</script>
<style lang="scss" scoped>
.form-btn {
  text-transform: none;
}
.v-input--checkbox.v-input--dense {
  margin-top: 0.5px;
}
.v-input--checkbox::v-deep {
  .v-label {
    color: #7E8299;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
