<template lang="pug">
v-card.rounded-lg
  v-row.fill-height.mt-5
    v-col
      v-sheet(height='64' tile class="d-flex")
        v-toolbar(flat='')
          v-btn.mr-4.ml-5(outlined='' color='#474787' @click='setToday')
            | Hoy
          v-btn( text='' small='' color='grey darken-2' @click='prev')
            v-icon(small='')
              | mdi-chevron-left
          v-btn.mr-5( text='' small='' color='grey darken-2' @click='next')
            v-icon(small='')
              | mdi-chevron-right
          v-select(v-model="type" :items="types" dense outlined hide-details class="ma-2" label="Seleccione vista" style=" max-width: 200px;")
          v-toolbar-title.text-capitalize.ml-8(v-if='$refs.calendar')
            | {{ $refs.calendar.title }}
          v-spacer
          v-menu(bottom='' right='')
            template(v-slot:activator='{ on, attrs }')
              NuevaHoraDialog( @refreshCalendar="updateCurrentRange")
      v-sheet(height='1000')
        v-calendar(ref='calendar' v-model='focus' :event-color="getEventColor" :events='events' :event-overlap-mode='mode' :type='type' @click:event='showEvent' @change='updateRange' :weekdays="daysWeek" :short-weekdays="false" locale="es" first-interval="7" interval-count="12" interval-height="70")
          template( #event="{ event }" )
            div.pa-1
              div {{ event.cliente }}, {{ event.name }}
  // Evento seleccionado
  v-row(justify='center')
    v-dialog(v-model='dialogEvent' max-width='650')
      v-card
        div.text-lg-right
          EditarHoraDialog(:id='selectedEvent.id' @refreshCalendar="updateCurrentRange" @closeDialogSelectEvent="dialogEvent = false")
          v-btn.btnDialog(color='#A1A7B5' small='' text='' @click='dialogEliminar = true')
            v-icon(small='') mdi-delete
          v-btn.btnDialog(color='#A1A7B5' small='' text='' @click='dialogEvent = false')
            v-icon(small='') x
        v-card-title.headline.ml-5 {{selectService ? selectService.name : 'Datos de la cita'}}
        v-card-text.ml-5
          div.mt-4
            v-row
              v-col(cols=4)
                h4.text-capitalize fecha y hora:
                h4.text-capitalize Cliente:
                h4.text-capitalize mascota:
                h4.text-capitalize Tipo de Servicio:
                h4.text-capitalize Profesional:
                h4.text-capitalize Teléfono:
                h4.text-capitalize(v-if="selectSala")  Sala:
                h4.text-capitalize(v-else) Dirección:
                h4.text-capitalize Comentario:
              v-col(cols=8)
                h4.text-left.text-capitalize {{fechaHoraFormateada}}
                h4.text-left.text-capitalize {{selectClient ? selectClient.name : '---'}}
                h4.text-left.text-capitalize {{selectMascota ? selectMascota.name + ' ' + selectMascota.specie + ' ' +  selectMascota.sex : '---' }}
                h4.text-left.text-capitalize {{selectService ? selectService.name : '---'}}
                h4.text-left.text-capitalize {{selectProfesional.first_name}} {{selectProfesional.last_name}}
                h4.text-left.text-capitalize {{selectClient ? selectClient.contact : '---'}}
                h4.text-left.text-capitalize(v-if="selectSala") {{selectSala.name}}
                h4.text-left.text-capitalize(v-else)  {{selectAddress}}
                h4.text-left.mr-5 {{ selectedEvent.comment }}
        v-card-actions
          v-spacer
          v-btn(color='#474787' style="text-transform: none" dark='' @click="goToPatient" ) Ir a Paciente
          v-btn.text-capitalize(color='#474787' dark='' @click='dialogEvent = false') Aceptar
  // Confrma eliminar
  v-row(justify='center')
    v-dialog(v-model='dialogEliminar' max-width='290')
      v-card
        v-card-title.headline Eliminar
        v-card-text Seguro que desea eliminar este registro?
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='deleteEvent') Eliminar
          v-btn(color='green darken-1' text='' @click='dialogEliminar = false') Cancelar

</template>
<script>
import NuevaHoraDialog from '@/components/agenda/NuevaHora/NuevaHoraDialog.vue'
import EditarHoraDialog from '@/components/agenda/NuevaHora/EditarHoraDialog.vue'
import FormatterMixin from '@/components/mixins/FormatterMixin'

import axios from 'axios'
import settings from '@/../settings'
export default {
  mixins: [FormatterMixin],
  components: {
    NuevaHoraDialog,
    EditarHoraDialog,
  },
  data: () => ({
    daysWeek: [1, 2, 3, 4, 5, 6, 0],
    dialogEliminar: false,
    dialogEvent: false,
    dialogEliminado: false,
    events: [],
    focus: '',
    type: '4day',
    types: [
      { text: 'Día', value: 'day' },
      { text: 'Próximos 4 días', value: '4day' },
      { text: 'Semana', value: 'week' },
      { text: 'Mes', value: 'month' }
    ],
    mode: 'column',
    ready: false,
    selectedEvent: [],
    selectClient: [],
    selectMascota: [],
    selectProfesional: [],
    selectSala: [],
    selectAddress: null,
    selectService: [],
    selectedElement: null,
    selectedOpen: false,
    value: '',
    colors: [ // colores para agenda
      // 'green',
      // 'blue',
      // 'indigo',
      '#40407A',
      '#34ACE0',
      '#33D9B2',
      '#706FD3',
      '#FF5252',
      '#FF793F',
      '#FFB142',
      '#FFDA79'
    ],
    fechaHoraFormateada: ''
  }),
  methods: {
    goToPatient () {
      this.$router.push({ name: 'Detalle Paciente', params: { idPaciente: this.selectMascota.id } })
    },
    // ----- Current Time Line Methods ----
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)
      this.cal.scrollToTime(first)
    },
    updateTime () {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
      // -----
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event ? event.color : '#333'
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    async showEvent ({ nativeEvent, event }) {
      let evento = []
      const url = `${settings.API_URL}/api/service/appointment/` + event.id + '/'
      await axios
        .get(url)
        .then(response => {
          evento = response.data
        })
        .catch(e => console.log(e))
      this.dialogEvent = true
      this.selectedEvent = evento
      this.fechaHoraFormateada = evento.start_date.substring(0, 10) + ' De: ' + evento.start_date.substring(11, 16) + ' Hasta: ' + evento.end_date.substring(11, 16)
      this.selectClient = evento.client
      this.selectProfesional = evento.professional
      this.selectMascota = evento.pet
      this.selectService = evento.service
      this.selectSala = evento.room
      this.selectAddress = evento.address
    },
    async deleteEvent () {
      try {
        const url = `${settings.API_URL}/api/service/appointment/` + this.selectedEvent.id + '/'
        await axios
          .delete(url)
          .then(response => {
            this.dialogEliminar = false
            this.dialogEvent = false
            this.updateCurrentRange()
          })
      } catch (e) { console.log(e) }
    },
    async updateCurrentRange () {
      const defaultDateRange = { start: this.$refs.calendar.lastStart, end: this.$refs.calendar.lastEnd }
      this.updateRange(defaultDateRange)
    },
    async updateRange ({ start, end }) {
      const events = []
      let eventos = []
      const min = `${this.dateOrStringDateToYMD(start)}T00:00:00`
      const max = `${this.dateOrStringDateToYMD(end)}T23:59:59`

      const url = `${settings.API_URL}/api/service/appointment/?start_date__gte=` + min + '&start_date__lte=' + max
      await axios
        .get(url)
        .then(response => {
          eventos = response.data
        })
        .catch(e => console.log(e))

      const getRoomColor = (i) => {
        const index = Math.min(i, this.colors.length - 1)
        return this.colors[index]
      }
      let indexColor = 0
      for (let i = 0; i < eventos.length; i++) {
        if (indexColor > this.colors.length - 1) {
          indexColor = 0
        }
        const event = {
          id: eventos[i].id,
          name: eventos[i].service_name || '- - -',
          start: new Date(`${eventos[i].start_date}`),
          end: new Date(`${eventos[i].end_date}`),
          color: getRoomColor(i),
          timed: true,
          cliente: eventos[i].client_name || '- - -'
        }
        events.push(event)
        indexColor = indexColor + 1
      }
      this.events = events
    }
  },
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  mounted () {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  }
}
</script>

<style scoped>
.custom-row{
  margin-bottom:-40px;
}
.v-calendar .v-event-timed-container {
  margin-right: 0 !important;
}

.v-calendar >>> .v-event-timed {
  font-size: 14px !important;
  padding: 0 0 0 5px;
}
</style>
<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
