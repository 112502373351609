<template lang="pug">
v-card
  v-card-title.card-title
    div.d-flex.justify-space-between
      span.black--text Nueva Hora
      v-btn(icon @click="handleClose")
        v-icon mdi-close

  v-tabs.tab-bar(
    v-model="currentTab"
    height="33px" centered
  )
    v-tab.tab(
      v-for="tab in tabs"
      :key="tab.name"
      :disabled="!tab.enabled"
      @change="tabs[1].enabled = currentTab === 1"
    )
      | {{ tab.name }}

  v-tabs-items( v-model="currentTab" )
    v-tab-item( :key="tabs[0].name" eager )
      v-card.flat
        v-card-text
          FormHora(
            ref="form1"
            @nextTab="switchToTab(1)",
            @closeDialog="handleClose",
            @notAvailable="openSnack('SALA_NO_DISPONIBLE')",
          )
    v-tab-item( :key="tabs[1].name" eager )
      v-card.flat
        v-card-text
          FormCliente(
            ref="form2"
            @prevTab="switchToTab(0)",
            @closeDialog="handleClose",
            @startEditing="tabs[0].enabled = false",
            @stopEditing="tabs[0].enabled = true",
            @refreshCalendar="$emit('refreshCalendar')",
          )

  v-snackbar(
    v-model="snackActive"
    :timeout="5000"
    color="error"
  )
    | {{ snackMessage }}
</template>


<script>
import FormHora from './FormHora.vue';
import FormCliente from './FormCliente.vue';

const snackContent = {
  SALA_NO_DISPONIBLE:
    'La sala no está disponible en el horario indicado.',
}

export default {
  components: { FormHora, FormCliente },

  data() {
    return {
      currentTab: 0,
      tabs: [
        { enabled: true,  name: 'Fecha/Hora' },
        { enabled: false, name: 'Paciente/Cliente' },
      ],
      snackMessage: '',
      snackActive: false,
    }
  },

  methods: {
    switchToTab(tabIndex) {
      this.currentTab = tabIndex
      if (tabIndex === 1) {
        this.tabs[1].enabled = true
      }
    },

    handleClose() {
      this.currentTab = 0
      this.tabs[0].enabled = true
      this.tabs[1].enabled = false
      this.$emit('closeDialog')
    },

    openSnack(messageName) {
      this.snackMessage = snackContent[messageName]
      this.snackActive = true
    },

    resetForms() {
      ['form1', 'form2'].forEach((f) => {
        this.$refs[f] && this.$refs[f].resetForm()
      })
    },

    reloadFieldData() {
      ['form1', 'form2'].forEach((f) => {
        this.$refs[f] && this.$refs[f].reloadFieldData()
      })
    }
  },

}
</script>

<style lang="scss" scoped>
.card-title {
  position: relative;
  height: 60px;
  padding: 30px 30px 0 !important;
  
  &>div {
    position: absolute;
    z-index: 100;
    width: calc(100% - 48px);

    span {
      font-size: 25px;
      font-weight: bold;
    }
  }
}

.tab-bar {
  top: -10px;
  position: relative !important;
  padding-bottom: 22px;
  border-bottom: 1px solid #EFF2F5;
}

.tab {
  margin-right: 80px;
  text-transform: none !important;
  letter-spacing: 0;
  font-size: 21px;
  width: 200px;
}
</style>