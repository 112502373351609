<template lang="pug">
v-container
  h2 Detalles Pacientes
  br
  v-row
    v-col(cols="3")
      ResumenPaciente
    v-col(cols="9")
      ListaHistorialPaciente
</template>
<script>
import ResumenPaciente from '../components/pacientes/detallePacientes/ResumenPaciente.vue';
import ListaHistorialPaciente from '../components/pacientes/detallePacientes/ListaHistorialPaciente.vue';
export default {
  components: {
    ResumenPaciente,
    ListaHistorialPaciente
}
}
</script>