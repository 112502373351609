<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text Datos Cliente
      small.grey--text(v-if="edit") Fecha de atención: {{ formatDate(medicalRecord.start_date) }}
    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Nombre Dueño
        small.grey--text.text-capitalize {{ pet.client.name }}
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Contacto Telefónico
        small.grey--text {{ pet.client.contact }}
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Correo Electrónico
        small.grey--text {{ pet.client.email }}
    v-card-title.pt-5
      h5.black--text Datos Paciente
    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Nombre
        small.grey--text.text-capitalize {{ pet.name }}
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Especie
        small.grey--text.text-capitalize {{ pet.specie }}
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Raza
        small.grey--text.text-capitalize {{ pet.race }}
    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Sexo
        small.grey--text.text-capitalize {{ pet.sex }}
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Edad
        small.grey--text {{ calculateAgePatient }}
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Color
        small.grey--text.text-capitalize {{ pet.color }}
    //- Formulario de Atención
    div(v-if="!edit")
      v-form(ref="form")
        v-card-title.pt-5
          h5.black--text Datos Atención
        v-row.ml-1
          v-col( cols=12 md=4 )
            h5 Fecha
            small.grey--text.text-capitalize {{ formatDate(form.date) }}
          v-col.py-0( cols=12 md=4 )
            h5.mb-1 Hora de Inicio*
            v-text-field(
              outlined
              dense
              prepend-inner-icon="mdi-clock-outline"
              type="time"
              v-model="form.startTime"
              :rules="rules.startTime"
            )
          v-col.py-0(cols=12 md=4)
            h5.mb-1 Sala*
            v-autocomplete(
              v-model="form.room"
              :items="[form.room, ...roomAutocompleteResults]"
              return-object
              outlined dense
              no-filter
              item-text="name"
              @update:search-input="autocompleteRoomSearch"
              :rules="rules.room"
            )
          v-col.py-0(cols=12 md=4)
            h5.mb-1 Categoría
            v-autocomplete(
              v-model="category"
              :items="[...categoryOptions]"
              return-object
              outlined dense
              item-text="name"
            )
          v-col.py-0(cols=12 md=4)
            h5.mb-1 Servicio*
            v-autocomplete(
              v-model="form.service"
              :items="[form.service, ...serviceAutocompleteResults]"
              return-object
              outlined dense
              no-filter
              item-text="name"
              @update:search-input="autocompleteServiceSearch"
              :rules="rules.service"
            )
  v-card-actions.card-actions
    v-row.ml-1.justify-end.btn-row
      v-btn.form-btn.justify-center.mr-4(color="primary" elevation="0" @click="submit()") Siguiente
</template>
<script>
import { dateTimeToLocaleTime } from '@/utils/formatters.js'
import _ from 'lodash'

export default {
  props: {
    pet: {
      type: Object,
      default: null
    },
    medicalRecord: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      form: {
        date: new Date().toISOString().substr(0, 10),
        startTime: dateTimeToLocaleTime(new Date().toISOString()),
        room: null,
        service: null
      },
      category: null,
      roomAutocompleteResults: [],
      serviceAutocompleteResults: [],
      categoryOptions: [],
      loading: true,
      rules: {
        startTime: [
          v => !!v || 'La hora de inicio es requerida'
        ],
        room: [
          v => !!v || 'La sala es requerida'
        ],
        service: [
          v => !!v || 'El servicio es requerido'
        ]
      }
    }
  },
  methods: {
    async create () {
      if (!this.$refs.form.validate()) return

      const data = {
        pet: this.pet.id,
        start_date: `${this.form.date} ${this.form.startTime}`,
        service: this.form.service.id,
        room: this.form.room.id
      }
      const { data: medicalRecord, error } = await this.$store.dispatch('medicalRecord/createMedicalRecord', { data })
      if (error) {
        this.$toast.error('Ha ocurrido un error al crear el registro médico')
        return
      }
      this.$emit('created', medicalRecord)
    },
    update () {
      return this.$emit('nextTab')
    },
    submit () {
      this.edit ? this.update() : this.create()
    },

    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' }).format(new Date(date))
    },

    autocompleteRoomSearch(search) {},

    autocompleteServiceSearch(search) {},

    async fetchCategories() {
      const response = await this.$store.dispatch('service/listServiceCategories')
      if (response.status !== 200) {
        this.$toast.error('Ha ocurrido un error al obtener las categorías')
        return
      }
      this.categoryOptions = response.data
    }
  },
  computed: {
    calculateAgePatient () {
      const today = new Date()
      const birthDate = new Date(this.pet.date_of_birth)
      const yearsDifference = today.getFullYear() - birthDate.getFullYear()
      const monthDifference = today.getMonth() - birthDate.getMonth()
      let message = ''
      if (yearsDifference <= 0) {
        message = `${monthDifference} meses.`
      } else if (monthDifference <= 0) {
        message = `${yearsDifference} años.`
      } else {
        message = `${yearsDifference} años y ${monthDifference} meses.`
      }
      return message
    },
    edit () {
      return this.medicalRecord !== null
    }
  },
  mounted () {
    this.fetchCategories()
  },
  watch: {
    category() {
      this.autocompleteServiceSearch()
    }
  },
  created () {
    this.autocompleteRoomSearch = _.debounce(async (search) => {
      const params = { search }
      const { data, error } = await this.$store.dispatch('room/autocompleteRoom', params)
      if (!error) {
        this.roomAutocompleteResults = data
      }
    }, 500)

    this.autocompleteServiceSearch = _.debounce(async (search) => {
      const params = { search }
      if (this.category) {
        params.category = this.category.id
      }
      const { data, error } = await this.$store.dispatch('service/autocompleteServices', params)
      if (!error) {
        this.serviceAutocompleteResults = data
      }
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  min-height: calc(100vh - 250px);
}
small {
  font-weight: 400;
}
.form-btn {
  text-transform: none;
}
.card-actions {
  bottom: 20px
}

</style>
