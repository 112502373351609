<template lang="pug">
    agenda
</template>

<script>
import agenda from '@/components/agenda/agenda.vue'

export default {
  name: 'Agenda',

  components: {
    agenda
  }
}
</script>
