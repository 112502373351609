<template lang="pug">
v-container.container( fluid ).pt-1
  SubFormCliente.mb-4(
    ref="formCliente"
    @startEditing="$emit('startEditing')"
    @stopEditing="$emit('stopEditing')"
  )
  SubFormPaciente(
    ref="formPaciente"
    @startEditing="$emit('startEditing')"
    @stopEditing="$emit('stopEditing')"
  )

  v-row.justify-space-between.buttons-row
    v-btn.form-btn(
      @click="$emit('prevTab')"
      :disabled="creandoCliente || creandoPaciente"
      outlined color="primary"
    )
      | Volver
    v-btn.form-btn(
      @click="handleSaveCita"
      :disabled="creandoCliente || creandoPaciente || (cliente && !paciente)"
      color="primary"
    )
      | {{ isEditando ? 'Actualizar' : 'Agendar' }}
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SubFormCliente from './SubFormCliente.vue'
import SubFormPaciente from './SubFormPaciente.vue';

export default {
  components: { SubFormCliente, SubFormPaciente },

  computed: {
    ...mapFields('agendarHora', {
      isEditando     : 'isEditando',
      idCitaEnEdición: 'idCitaEnEdición',
      creandoCliente : 'formCliente.creandoCliente',
      creandoPaciente: 'formCliente.creandoPaciente',
      cliente        : 'formCliente.cliente',
      paciente       : 'formCliente.paciente',
    }),
  },

  methods: {
    ...mapGetters('agendarHora', [
      'getAppointment',
    ]),
    ...mapMutations('agendarHora', [
      'resetFields',
    ]),
    ...mapActions('service', [
      'updateAppointment',
      'createAppointment'
    ]),

    async handleSaveCita() {
      if (!this.validateSelected()) return

      const data = this.getAppointment()
      const method = this.isEditando ? this.updateAppointment : this.createAppointment

      const res = await method({ data, id: this.idCitaEnEdición })
      if ( res.status >= 200 && res.status < 300 ) {
        this.resetFields()
        this.$emit('closeDialog')
        this.$emit('refreshCalendar')
      }
    },

    validateSelected() {
      const faltaCliente = this.cliente === null
      const faltaPaciente = this.paciente === null
      this.clienteErrorMessages = faltaCliente ? ['Seleccione un cliente'] : []
      this.pacienteErrorMessages = faltaPaciente ? ['Seleccione un paciente'] : []
      return !(this.cliente && !this.paciente)
    },

    resetForm() {
      this.$refs.formCliente.resetForm();
      this.$refs.formPaciente.resetForm();
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 24px;
}

.buttons-row {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
