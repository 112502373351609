import axios from '../../plugins/axios'


export const deletePet = (_, id) => {
  return axios.delete(`service/pet/${id}/`)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}

export const createPet = (_, { data }) => {
  return axios.post('service/pet/', data)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}

export const updatePet = (_, { id, data }) => {
  return axios.put(`service/pet/${id}/`, data)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}

export const getPet = (_, { id }) => {
  return axios.get(`service/pet/${id}/`)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}

export const autocompletePet = (_, params) => {
  return axios.get('service/pet/autocomplete/', { params })
    .then(response => ({ data: response.data, error: null }))
    .catch(error => ({ data: [], error }))
}

export const mergePet = (_, { id, data }) => {
  return axios.put(`service/pet/${id}/merge/`, data)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}
