import { getField, updateField } from 'vuex-map-fields'
import getters from './getters'
import mutations from './mutations'
import * as actions from './actions'

const state = {
  currentPage: 1,
  itemCount: 0,
  pageCount: 0,
  filterStaff: null,
  filterDates: [],
  filterStatus: null,

  summary: {
    count: 0,
    total_commission: 0,
  }
}

const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default store
