import axios from 'axios'
import settings from '../../settings'

const instance = axios.create({
  baseURL: `${settings.API_URL}/api/`,
  withCredentials: true

})

export default instance
