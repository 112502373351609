var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemCount,"page":_vm.currentPage,"disable-sort":"","hide-default-footer":"","loading":_vm.isLoading},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateTimeToLocaleDate(item.datetime))+", "+_vm._s(_vm.dateTimeToLocaleTime(item.datetime)))]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.description))]),(item.hasMoreThanOneItem)?_c('span',[_vm._v(","),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleViewSale(item.id)}}},[_vm._v("...")])]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberToPesos(item.amount)))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"value":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"action-btn",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('openEditDialog', item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(_vm.showDeleteBtn)?_c('v-btn',{staticClass:"action-btn",attrs:{"text":""},on:{"click":function($event){return _vm.showDialogDelete(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{staticClass:"action-btn",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleViewSale(item.id)}}},[_c('v-icon',[_vm._v("mdi-text-box-search-outline")])],1),(item.status === 'PAID')?_c('v-btn',{staticClass:"action-btn",attrs:{"text":""},on:{"click":function($event){return _vm.handleGetReceipt(item.id)}}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1):_vm._e()]}}])}),_c('ViewSaleDialog',{ref:"viewSaleDialog"}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Eliminar")]),_c('v-card-text',[_vm._v("Seguro que desea eliminar esta venta?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteEvent}},[_vm._v("Eliminar")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false; _vm.selectedSale = null}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }