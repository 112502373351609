import Vue from 'vue'
import light from './theme'
import VuetifyToast from 'vuetify-toast-snackbar-ng'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

export default new Vuetify({
  theme: {
    themes: { light },
    options: { customProperties: true },
  }
})

Vue.use(VuetifyToast)
