import axios from '../../plugins/axios'


export const listMedicalRecords = (_, { params }) => {
  return axios.get(`service/medical_record/`, { params })
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}

export const createMedicalRecord = (_, { data }) => {
  return axios.post('service/medical_record/', data)
    .then(response => ({ data: response.data }))
    .catch(error => ({ error }))
}
