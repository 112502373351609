<template lang="pug">
div
  v-row
    v-col( cols=12 md=4 )
      v-autocomplete(
        v-model="cliente"
        :items="[cliente, ...clientAutocompleteResults]"
        return-object
        outlined dense
        no-filter
        hide-details
        item-text="name"
        @update:search-input="autocompleteClientSearch"
      )
        template(#item="{ item }")
          v-list-item-content(v-if="item")
            v-list-item-title {{ item.name }}
            v-list-item-subtitle {{ item.rut }}
    v-spacer

    v-col( cols=12 md=4 ).d-flex.justify-end
      v-btn.form-btn.form-btn-inline(
        v-if="!creandoCliente"
        color="primary"
        @click="handleNewCliente"
      )
        | Nuevo cliente
      v-btn.form-btn.form-btn-inline.mr-2(
        v-else
        color="primary"
        @click="handleSaveCliente"
      )
        | Guardar
      v-btn.form-btn.form-btn-inline(
        v-if="creandoCliente"
        outlined color="warning"
        @click="handleCancelCliente"
      )
        | Cancelar

  v-form(
    ref="formCliente"
    v-model="isFormClienteValid"
    lazy-validation
    :readonly="readonly || !creandoCliente"
  )

    v-row.mt-n6
      v-col( cols=12 md=4 )
        v-subheader.toplabel.req
          | Nombre y Apellido
        v-text-field(
          ref="fieldNombre"
          v-model="nombre"
          :rules="getRules('nombre')"
          :readonly="!creandoCliente"
          :filled="!creandoCliente"
          outlined dense
        )
        
      v-col( cols=12 md=4 )
        v-subheader.toplabel.req
          | R.U.T.
        v-text-field(
          v-model="rut"
          :rules="getRules('rut')"
          :readonly="!creandoCliente"
          :filled="!creandoCliente"
          required outlined dense
          @change="clienteYaExiste = false"
        )

    v-row.mt-n6
      v-col( cols=12 md=4 )
        v-subheader.toplabel.req
          | Teléfono
        v-text-field(
          v-model="fono"
          :rules="getRules('fono')"
          :readonly="!creandoCliente"
          :filled="!creandoCliente"
          required outlined dense
        )

      v-col( cols=12 md=4 )
        v-subheader.toplabel
          | Correo
        v-text-field(
          v-model="correo"
          :rules="getRules('correo')"
          :readonly="!creandoCliente"
          :filled="!creandoCliente"
          outlined dense
        )
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields, createHelpers } from 'vuex-map-fields'
import { validateRUT } from 'validar-rut';

const { mapFields: mapFormFields } = createHelpers({
  getterType: 'getFormClienteField',
  mutationType: 'updateFormClienteField',
})

export default {
  props: ['value', 'readonly'],

  data() {
    return {
      isFormClienteValid: true,
      clienteErrorMessages: [],
      clientAutocompleteResults: [],
      clienteYaExiste: false,
    }
  },

  watch: {
    cliente(val) {
      if (val === null) {
        this.$refs.formCliente.reset()
        return
      }
      this.nombre = val.name
      this.rut    = val.rut
      this.fono   = val.contact
      this.correo = val.email

      this.$emit('input', val ? val.id : null)
    },
  },

  computed: {
    ...mapFields('agendarHora', [
      'isEditando',
      'idCitaEnEdición',
    ]),
    ...mapFormFields('agendarHora', {
      cliente        : 'cliente',
      nombre         : 'clienteFields.nombre',
      rut            : 'clienteFields.rut',
      fono           : 'clienteFields.fono',
      correo         : 'clienteFields.correo',
      creandoCliente : 'creandoCliente',
    }),
  },

  methods: {
    ...mapActions('service', [
      'listCustomers',
      'createCustomer',
      'getCustomer',
    ]),

    async autocompleteClientSearch (search) {
      const { data, error } = await this.$store.dispatch('client/autocompleteClient', { search })
      if (!error) {
        this.clientAutocompleteResults = data
      }
    },

    getRules(field) {
      const rules = {
        nombre: !this.creandoCliente ? [] :
          [
            v => !!v || 'Debe ingresar un nombre',
          ],
        rut: !this.creandoCliente ? [] : [
          v => !!v || 'Debe ingresar un RUT',
          v => (v && validateRUT(v)) || 'Ingrese un RUT válido',
          v => !this.clienteYaExiste || 'Ya existe un cliente con este RUT'
        ],
        fono: !this.creandoCliente ? [] : [
          v => !!v || 'Debe ingresar un teléfono',
        ],
        correo: !this.creandoCliente ? [] : [
          v => (!v || /.+@.+\..+/.test(v)) || 'El correo es inválido'
        ],
      }
      return rules[field];
    },

    handleNewCliente() {
      this.creandoCliente = true
      this.$emit('resetForm')
      this.cliente = null
      this.$refs.fieldNombre.focus()
      this.$emit('startEditing')
    },

    async handleSaveCliente() {
      if (!this.$refs.formCliente.validate()) return

      this.cliente = {
        name   : this.nombre,
        email  : this.correo || '',
        rut    : this.rut,
        contact: this.fono,
      }

      const res = await this.createCustomer({ data: this.cliente })
      if ( res.status >= 200 && res.status < 300 ) {
        this.cliente = res.data
        this.handleCancelCliente()
      } else {
        if (res.data.rut) {
          this.clienteYaExiste = true
          this.$refs.formCliente.validate()
        }
      }
    },

    handleCancelCliente() {
      this.creandoCliente = false
      this.$refs.formCliente.reset()
      this.$emit('stopEditing')
    },

    resetForm() {
      this.$refs.formCliente.reset()
    },
  },
}
</script>

<style scoped lang="scss">

</style>
