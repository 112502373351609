<template lang="pug">
v-dialog(v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel")
  v-card
    v-card-title {{ title }}
    v-card-text.pa-4(v-show="!!message" v-html="message")
    v-card-text.text-right.mt-
        v-btn.mr-4.rounded-lg(v-if="!options.noconfirm" color="primary" outlined @click.native="cancel") {{ options.cancelTitle }}
        v-btn.rounded-lg(color="primary" @click.native="agree") {{ options.okTitle }}
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        width: 450,
        zIndex: 200,
        noconfirm: false,
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar'
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      });
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
