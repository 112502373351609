<template lang="pug">
div
  h1.mt-2.mb-4 Precios
    List(ref="list" @openEditDialog="openEditDialog")
  PreciosForm(ref="preciosForm" @reloadData="reloadData")
</template>

<script>
import List from '@/components/precios/listado/List.vue'
import PreciosForm from '@/components/precios/form/PreciosForm.vue'

export default {
  data () {
    return {

    }
  },
  watch: {
    currentPage () {
      this.reloadData()
    }
  },
  methods: {
    reloadData () {
      this.$refs.list.loadData()
    },
    openEditDialog (id) {
      this.$refs.preciosForm.open(id)
    },
    filterList (value) {
      this.$refs.list.filterList(value)
    }
  },
  components: { List, PreciosForm }
}
</script>
