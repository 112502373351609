<template lang="pug">
div
  ListaPacientes
</template>

<script>
import ListaPacientes from '@/components/pacientes/ListaPacientes.vue'

export default {
  components: {
    ListaPacientes
  }
}
</script>

