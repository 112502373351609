<template lang="pug">
v-menu(
  v-model="menu"
  :close-on-content-click="false"
  :disabled="disabled"

  transition="scale-transition"
  nudge-right="40"
  offset-overflow
  min-width="auto"
)
  template(#activator="{ on, attrs }")
    v-text-field(
      v-model="date"
      :rules="rules"
      v-bind="attrs"
      v-on="on"

      :label="label"
      prepend-inner-icon="mdi-calendar"
      :filled="filled"
      readonly outlined dense
    )
  v-date-picker(
    v-model="date"
    @input="menu = false"

    first-day-of-week="1"
    locale="es-CL"
  )
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    rules: Array,
    disabled: Boolean,
    filled: Boolean,
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    date: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>