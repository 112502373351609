<template lang="pug">
v-dialog(
  v-model="dialog"
  max-width="1180px"
  scrollable
)
  v-card
    v-card-title.card-title.d-flex.justify-space-between
      span Venta N° {{sale && sale.id}}
      v-btn(icon @click="dialog = false").mr-n2
        v-icon mdi-close
    v-card-text
      v-progress-circular.progress(
        v-if="loading"
        indeterminate
        color="primary"
      )
      SaleDataDisplay( v-else :sale="sale" )

</template>

<script>
import { mapActions } from 'vuex';
import SaleDataDisplay from './SaleDataDisplay.vue';

export default {
  components: { SaleDataDisplay },
  data() {
    return {
      dialog: false,
      loading: true,
      sale: null,
    };
  },

  methods: {
    ...mapActions('ventas', [
      'getSale',
    ]),

    async open(id) {
      this.sale = null;
      this.loading = true;
      this.dialog = true;

      const response = await this.getSale(id)
      this.sale = response.data || []
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.progress {
  display: block;
  width: 100% !important;
}
</style>