var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pb-4",staticStyle:{"border-radius":"15px"}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticStyle:{"width":"410px"},attrs:{"placeholder":"Buscar por nombre","prepend-inner-icon":"mdi-magnify","dense":"","filled":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"btn",attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.$emit('openEditDialog')}}},[_vm._v("Nuevo Precio")])],1)],1)],1),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.user.is_superuser ? _vm.headersSuperuser : _vm.headers,"items":_vm.priceItems,"disable-sort":"","hide-default-footer":"","loading":_vm.isLoading,"page":_vm.currentPage,"server-items-length":_vm.itemCount},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"table-bold"},[_vm._v(_vm._s(item.product))])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberToPesos(item.cost)))]}},{key:"item.profit_percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.profit_percentage + '%'))]}},{key:"item.total_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberToPesos(item.total_price)))]}},{key:"item.fragmented_unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberToPesos(item.fragmented_unit_price)))]}},{key:"item.fragmented_profit_percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.fragmented_profit_percentage + '%'))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"action-btn",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDialog(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogEliminar),callback:function ($$v) {_vm.dialogEliminar=$$v},expression:"dialogEliminar"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Eliminar")]),_c('v-card-text',[_vm._v("Seguro que desea eliminar este precio?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteEvent}},[_vm._v("Eliminar")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogEliminar = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }