import { getField, updateField } from 'vuex-map-fields'
import axios from '@/plugins/axios';
import settings from '@/../settings';

const state = {
  isEditando: false,
  idCitaEnEdición: null,

  formHora: {
    isValidated: false,
    fecha: null,
    horaInicio: null,
    horaTérmino: null,
    profesional: null,
    tipoUbicación: 'sala',
    sala: null,
    dirección: null,
    categoria: null,
    servicio: null,
    comentario: '',
    fechaOriginal: null,
    horaInicioOriginal: null,
    horaTérminoOriginal: null,
  },

  formCliente: {
    pacientes: [],
    clienteFields: {
      nombre: '',
      rut: '',
      fono: '',
      correo: '',
    },
    pacienteFields: {
      nombre: '',
      especie: null,
      raza: null,
      sexo: null,
      fechaNacimiento: null,
      peso: null,
      color: '',
    },
    cliente: null,
    paciente: null,
    creandoCliente: false,
    creandoPaciente: false,
  },

}

const getters = {
  getField,

  getFormHoraField(state) {
    return getField(state.formHora);
  },

  getFormClienteField(state) {
    return getField(state.formCliente);
  },

  getAppointment(state) {
    const { formHora: form1, formCliente: form2 } = state;
    const isSala = form1.tipoUbicación === 'sala';

    return {
      start_date  : form1.fecha + 'T' + form1.horaInicio,
      end_date    : form1.fecha + 'T' + form1.horaTérmino,
      professional: form1.profesional.id,
      service     : form1.servicio ? form1.servicio.id : null,
      room        : isSala ? form1.sala.id               : null,
      address     : !isSala ? form1.dirección            : '',
      comment     : form1.comentario,
      client      : form2.cliente?.id || null,
      pet         : form2.paciente?.id || null
    }
  }
}

const mutations = {
  updateField,

  updateFormHoraField(state, field) {
    updateField(state.formHora, field);
  },

  updateFormClienteField(state, field) {
    updateField(state.formCliente, field);
  },

  resetFields(state) {
    state.isEditando = false;
    state.formHora = {
      isValidated: false,
      fecha: null,
      horaInicio: null,
      horaTérmino: null,
      profesional: null,
      tipoUbicación: 'sala',
      sala: null,
      dirección: null,
      categoria: null,
      servicio: null,
      comentario: '',
    };
    state.formCliente = {
      clienteFields: {
        nombre: '',
        rut: '',
        fono: '',
        correo: '',
      },
      pacienteFields: {
        nombre: '',
        especie: null,
        raza: null,
        sexo: null,
        fechaNacimiento: null,
        peso: null,
        color: '',
      },
      cliente: null,
      paciente: null,
      creandoCliente: false,
      creandoPaciente: false,
    }
  },

  setEditando (state, { val }){
    state.isEditando = val;
  },

  editFields(state, { data }) {

    state.idCitaEnEdición = data.id;
    const fecha = data.start_date.substring(0,10);
    const horaInicio = data.start_date.substring(11,16);
    const horaTérmino = data.end_date.substring(11,16);

    state.formHora = {
      isValidated:false,
      fecha: fecha,
      horaInicio: horaInicio,
      horaTérmino: horaTérmino,
      profesional: data.professional,
      tipoUbicación: !!data.room ? 'sala' : 'domicilio',
      sala: data.room,
      dirección: data.address,
      categoria: { id: data.service ? data.service.category : null },
      servicio: data.service,
      comentario: data.comment,
      fechaOriginal: fecha,
      horaInicioOriginal: horaInicio,
      horaTérminoOriginal: horaTérmino,
    };

    state.formCliente = {
      clienteFields: {
        nombre: data.client?.name,
        rut:  data.client?.rut,
        fono: data.client?.contact,
        correo:  data.client?.email,
      },
      pacienteFields: {
        nombre: data.pet?.name,
        especie: data.pet?.specie,
        raza: data.pet?.race,
        sexo: data.pet?.sex,
        fechaNacimiento: data.pet?.date_of_birth,
        peso: data.pet?.weight,
        color: data.pet?.color,
      },
      cliente: data.client || null,
      paciente: data.pet || null,
      creandoCliente: false,
      creandoPaciente: false,
      justLoadedData: true
    }
  }
}

const actions = {
  isRoomAvailable({ state }) {
    return new Promise((resolve, reject) => {
      const form = state.formHora;
      if (form.tipoUbicación !== 'sala') resolve(true);

      const data = {
        start_date    : form.fecha + 'T' + form.horaInicio,
        end_date      : form.fecha + 'T' + form.horaTérmino,
        appointment_id: state.isEditando ? state.idCitaEnEdición: undefined
      };
      const id = form.sala.id;

      const endpoint = `service/room/${id}/validate_availability/`;
      axios.post(endpoint, data)
        .then(res => {
          resolve(res.data.is_available);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  setEditForm({ state, commit },{id} ){

    const url = `${settings.API_URL}/api/service/appointment/${id}`;
    axios.get(url)
      .then(res => {
        commit('editFields', { data: res.data })
      })
      .catch(err => {
        console.log(err);
      });
  }

}

const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default store
