<template lang="pug">
v-form(
  ref="formCliente"
  v-model="isFormClienteValid"
  lazy-validation
)
  h3.outer-label.black--text.mb-2 Cliente

  v-row
    v-col(cols=12 md=4)
      v-autocomplete(
        v-model="cliente"
        :items="[cliente, ...clientAutocompleteResults]"
        return-object
        outlined dense
        no-filter
        hide-details
        item-text="name"
        :readonly="creandoCliente"
        :filled="creandoCliente"
        @update:search-input="autocompleteClientSearch"
      )
        template(#item="{ item }")
          v-list-item-content(v-if="item")
            v-list-item-title {{ item.name }}
            v-list-item-subtitle {{ item.rut }}
    v-col( cols=12 md=4).d-flex.justify-end
      v-btn.form-btn.form-btn-inline.mr-2(v-if="!creandoCliente" color="primary" @click="handleNewCliente" height="40") Nuevo Cliente 
      v-btn.form-btn.form-btn-inline.mr-2(
        v-if="clienteSeleccionado"
        color="primary"
        @click="handleEditCliente"
      )
        | Editar Datos
      v-btn.form-btn.form-btn-inline.mr-2(
        v-if="creandoCliente"
        color="primary"
        @click="handleSaveCliente"
      )
        | Guardar
      v-btn.form-btn.form-btn-inline(
        v-if="creandoCliente"
        outlined color="warning"
        @click="handleCancelCliente"
      )
        | Cancelar
  v-row(v-if="creandoCliente || clienteSeleccionado")
    v-col( cols=12 md=4 )
      v-subheader.toplabel.req
        | Nombre y Apellido
      v-text-field(
        ref="fieldNombre"
        v-model="nombre"
        :rules="getRules('nombre')"
        :readonly="!creandoCliente"
        :filled="!creandoCliente"
        outlined dense
      )

    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | R.U.T.
      v-text-field(
        v-model="rut"
        :rules="getRules('rut')"
        :readonly="!creandoCliente"
        :filled="!creandoCliente"
        outlined dense
        @change="clienteYaExiste = false"
      )

  v-row.mt-n6(v-if="creandoCliente || clienteSeleccionado")
    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | Teléfono
      v-text-field(
        v-model="fono"
        :rules="getRules('fono')"
        :readonly="!creandoCliente"
        :filled="!creandoCliente"
        outlined dense
      )

    v-col( cols=12 md=4 )
      v-subheader.toplabel
        | Correo
      v-text-field(
        v-model="correo"
        :rules="getRules('correo')"
        :readonly="!creandoCliente"
        :filled="!creandoCliente"
        outlined dense
      )
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields, createHelpers } from 'vuex-map-fields'
import { validateRUT } from 'validar-rut';
import AutoComplete from './AutoComplete.vue';

const { mapFields: mapFormFields } = createHelpers({
  getterType: 'getFormClienteField',
  mutationType: 'updateFormClienteField',
})

export default {
  components: { AutoComplete },

  data() {
    return {
      isFormClienteValid: true,
      clienteErrorMessages: [],
      clienteYaExiste: false,
      newCliente: null,
      clienteSeleccionado: false,
      loading: false,
      clientAutocompleteResults: [],
    }
  },

  watch: {
    cliente(val) {
      if(this.justLoadedData) {
        this.justLoadedData = false
      } else {
        this.paciente = null
      }

      if (val === null) {
        this.$refs.formCliente.reset()
        return
      }

      this.nombre = val.name
      this.rut    = val.rut
      this.fono   = val.contact
      this.correo = val.email
      this.clienteSeleccionado = true
    },
  },

  computed: {
    ...mapFields('agendarHora', [
      'isEditando',
      'idCitaEnEdición',
    ]),
    ...mapFormFields('agendarHora', {
      cliente        : 'cliente',
      paciente       : 'paciente',
      nombre         : 'clienteFields.nombre',
      rut            : 'clienteFields.rut',
      fono           : 'clienteFields.fono',
      correo         : 'clienteFields.correo',
      creandoCliente : 'creandoCliente',
      creandoPaciente: 'creandoPaciente',
      justLoadedData : 'justLoadedData'
    }),
    nuevoClienteButton () {
      return {
        title: 'Agregar nuevo Cliente',
        event: this.handleNewCliente
      }
    }
  },

  methods: {
    ...mapActions('service', [
      'listCustomers',
      'createCustomer',
      'editCustomer'
    ]),

    getRules(field) {
      const rules = {
        nombre: !this.creandoCliente ? [] :
          [
            v => !!v || 'Debe ingresar un nombre',
          ],
        rut: !this.creandoCliente || this.rut == null || this.rut == '' ? [] : [
          v => v && validateRUT(v) || 'Ingrese un RUT válido',
          v => !this.clienteYaExiste || 'Ya existe un cliente con este RUT'
        ],
        correo: !this.creandoCliente ? [] : [
          v => (!v || /.+@.+\..+/.test(v)) || 'El correo es inválido'
        ],
      }
      return rules[field];
    },

    handleNewCliente() {
      this.clienteSeleccionado = false
      this.creandoCliente = true
      this.$emit('resetForm')
      this.cliente = null
      this.paciente = null
      this.$nextTick(() => {
        this.$refs.fieldNombre.focus()
      })
      this.$emit('startEditing')
    },

    handleEditCliente() {
      this.creandoCliente = true
      this.clienteSeleccionado = false
      this.$emit('resetForm')
      this.$refs.fieldNombre.focus()
      this.$emit('startEditing')
    },

    async autocompleteClientSearch (search) {
      const { data, error } = await this.$store.dispatch('client/autocompleteClient', { search })
      if (!error) {
        this.clientAutocompleteResults = data
      }
    },

    async handleSaveCliente() {
      this.loading = true
      if (!this.$refs.formCliente.validate()) return

      this.cliente = {
        id     : this.cliente != null && this.cliente.id ? this.cliente.id : null,
        name   : this.nombre,
        email  : this.correo || '',
        rut    : this.rut || null,
        contact: this.fono || '',
      }

      const action = this.cliente.id != null ? this.editCustomer : this.createCustomer

      const res = await action({ data: this.cliente })
      if ( res.status >= 200 && res.status < 300 ) {
        this.loading = false
        this.cliente.id = res.data.id
        this.newCliente = this.cliente

        this.handleCancelCliente()
        this.$nextTick(() => {
          this.cliente = this.newCliente
        })

      } else {
        this.loading = false
        if (res.data.rut) {
          this.clienteYaExiste = true
          this.$refs.formCliente.validate()
        }
      }
    },

    handleCancelCliente() {
      this.creandoCliente = false
      this.$refs.formCliente.reset()
      this.$emit('stopEditing')
    },

    resetForm() {
      this.$refs.formCliente.reset()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
