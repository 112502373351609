<template>
  <div>
    <h1>Vista de inicio</h1>
    <div id="example-2">
    </div>
  </div>
</template>

<script>
import settings from '../../settings'

export default {

}
</script>

