<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text Cuidados Preventivos
      small.grey--text Fecha de atención: {{ formatDate(medicalRecord.start_date) }}
    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Vacunas
        small.grey--text Seleccionar (puede ser más de una)
        v-checkbox(v-for="v in vacunas" :key="v.id" v-model="vaccines" :value="v.id" dense multiple hide-details :disabled="medicalRecord.is_finished")
          template(v-slot:label)
            span.text-capitalize {{ v.name }}

      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Desparacitaciones
        small.grey--text Seleccionar (puede ser más de una)
        v-checkbox(v-for="d in desparasitarios" :key="d.id" v-model="dewormings" :value="d.id" dense hide-details :disabled="medicalRecord.is_finished")
          template(v-slot:label)
            span.text-capitalize {{ d.name }}
      v-col(cols="4")

  v-card-actions.card-actions
    v-row.ml-3.justify-space-between
      v-btn.form-btn.justify-center(color="primary" outlined elevation="0" @click="$emit('previousTab')") Atrás
      v-btn.form-btn.justify-center.mr-4(color="primary" elevation="0" @click="saveData") Guardar y continuar
</template>
<script>
export default {
  props: {
    medicalRecord: {
      type: Object,
      default: () => {
        return {
          vaccines: [],
          dewormings: []
        }
      }
    },
    vacunas: {
      type: Array
    },
    desparasitarios: {
      type: Array
    }
  },
  data () {
    return {
      vaccines: this.medicalRecord.vaccines.length > 0 ? this.medicalRecord.vaccines : [],
      dewormings: this.medicalRecord.dewormings.length > 0 ? this.medicalRecord.dewormings : []
    }
  },
  methods: {
    /**
     * El metodo GetData() se hizo porque es necesario actualizar medical_record_products
     * porque al modificar algun checkbox no se estaba actualizando en la pestaña de valores.
     */
    getData () {
      // Se recuperan los category ID
      const vaccineCategory = this.vacunas[0].category
      const dewormingCategory = this.desparasitarios[0].category
      // Se filtra medical_record_products por ID de categoría vacuna y por las vacunas que no fueron seleccionadas en el checkbox
      const tempVaccines = this.medicalRecord.medical_record_products.filter(p => {
        return p.product_data.category === vaccineCategory && !this.vaccines.includes(p.product_data.id)
      }).map(p => p.product_data.id)
      // Se filtra medical_record_products por ID de categoría desparacitario y por los desparasitarios que no fueron seleccionados en el checkbox
      const tempDewormings = this.medicalRecord.medical_record_products.filter(p => {
        return p.product_data.category === dewormingCategory && !this.dewormings.includes(p.product_data.id)
      }).map(p => p.product_data.id)
      // Se filtra medical_record_products y retornamos los productos actualizados.
      return this.medicalRecord.medical_record_products.filter(
        p => {
          return !tempVaccines.includes(p.product_data.id) && !tempDewormings.includes(p.product_data.id)
        }
      )
    },
    saveData () {
      // Si ya vienen datos en el medical_record_products (osea ya se guardo por primera vez),
      // entonces tengo que actualizar los datos que voy a enviar si es que se hicieron modificaciones.
      if (this.medicalRecord.medical_record_products.length > 0) {
        this.$emit('saveAndNext', { vaccines: this.vaccines, dewormings: this.dewormings, medical_record_products: this.getData() })
      } else {
        this.$emit('saveAndNext', { vaccines: this.vaccines, dewormings: this.dewormings })
      }
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card {
  min-height: calc(100vh - 230px);
}
.card-actions {
  bottom: 10px;
}
.form-btn {
  text-transform: none;
}
.v-input--checkbox.v-input--dense {
  margin-top: 0.5px;
}
.v-input--checkbox::v-deep {
  .v-label {
    color: #7E8299;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
