<template lang="pug">
v-autocomplete(
  :value="value"
  @input="$emit('input', $event)"
  :items="items"
  :search-input.sync="query"

  :return-object="!returnId"
  :item-text="itemTextMethod"
  item-value="id"
  :rules="rules"

  dense outlined
  :loading="loading"
  :label="label"
  :no-data-text="noDataText"
  hide-selected clearable
  :hide-details="hideDetails"

  :disabled="disabled"
  :filled="disabled"
  :error-messages="errorMessages"

  @focus="onFocus()"
)
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    value: undefined,
    label: String,
    listMethod: Function,
    payloadMethod: {
      type: Function,
      default: (query) => ({ search: query })
    },
    itemTextMethod: {
      type: Function,
      default: (item) => item
    },
    minCharacters: {
      type: Number,
      default: 0
    },
    returnId: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    rules: Array,
    errorMessages: Array
  },
  data () {
    return {
      items: [],
      query: null,
      loading: false
    }
  },
  computed: {
    noDataText () {
      const length = this.query?.length || 0
      const plural = this.minCharacters === 1 ? '' : 'es'
      return length < this.minCharacters
        ? `Escriba al menos ${this.minCharacters} caracter${plural}`
        : 'No se encontraron datos'
    }
  },
  watch: {
    query (query) {
      const length = this.query?.length || 0
      if (length < this.minCharacters) {
        this.items = []
        return
      }
      this.onQueryChange(query)
    },
    value: {
      immediate: true,
      handler (value) {
        if (!value) return
        this.setQuery()
      }
    }
  },
  methods: {
    onQueryChange() {},

    reload () {
      this.$emit('input', null)
      this.query = null
    },

    setQuery () {
      const { value, returnId, itemTextMethod } = this
      this.query = returnId ? value : itemTextMethod(value)
    },

    onFocus () {
      if (this.minCharacters > 0) return
      this.query = this.query || ''
    }
  },
  created () {
    this.onQueryChange = debounce(async (query) => {
      this.loading = true
      const payload = this.payloadMethod(query)
      const res = await this.listMethod({ params: payload })
      if (res.data.results) {
        this.items = res.data.results
      } else {
        this.items = res.data
      }
      if (this.value && !this.items.find((item) => item.id === this.value.id)) {
        this.items = [this.value, ...this.items]
      }
      this.loading = false
    }, 500)
  },
}
</script>
