<template lang="pug">
v-autocomplete(
  v-model="model"
  :items="items"
  :search-input.sync="search"

  return-object
  :item-text="getFullName"
  item-value="id"

  dense filled hide-details
  :loading="isLoading"
  :placeholder="placeholder"
  no-data-text="No se encontraron datos"
  hide-selected

  @focus="loadData"
)
  template( #item="{ item }" )
    | {{getFullName(item)}}
    
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    value: Object,
    placeholder: String,
  },

  data() {
    return {
      items: [],
      search: null,
      isLoading: false,
    }
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    search(_) {
      this.loadData()
    }
  },

  methods: {
    ...mapActions('user', [
      'listProfessional'
    ]),

    async loadData() {
      if (this.items.length > 0) return
      if (this.isLoading) return

      this.isLoading = true

      const res = await this.listProfessional()
      this.items = res.data

      this.isLoading = false
    },

    getFullName(item) {
      return `${item.first_name} ${item.last_name}`
    },
  },
}
</script>

<style lang="" scoped>

</style>