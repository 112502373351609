<template lang="pug">
div
  v-dialog(v-model="modalPatientFile" transition="dialog-bottom-transition" max-width="1200" content-class="elevation-0")
    v-card(tile)
      v-card-title.black--text Nueva Ficha
        v-btn.ml-auto(fab color="white" small depressed @click="modalPatientFile = false")
          v-icon mdi-close-thick
      v-divider
      v-card-text.pa-0
        v-container(fluid)
          v-row
            v-col.pa-0.tabs__col(cols="4")
              v-tabs(v-model="tab" vertical background-color="#CFD1D9" grow hide-slider active-class="active")
                v-tab.flex-column.align-start
                  h3.tab__title 1. Datos Cliente / Paciente
                  small.tab__subtitle.pl-5 Revisa si los datos coinciden.
                v-tab.flex-column.align-start(:disabled="tab !== 1 && !isFinished")
                  h3.tab__title 2. General
                  small.tab__subtitle.pl-5 Completa los datos.
                v-tab.flex-column.align-start(:disabled="tab !== 2 && !isFinished")
                  h3.tab__title 3. Exploración
                  small.tab__subtitle.pl-5 Completa los datos.
                v-tab.flex-column.align-start(:disabled="tab !== 3 && !isFinished")
                  h3.tab__title 4. Cuidados Preventivos
                  small.tab__subtitle.pl-5 Marca las opciones que correspondan.
                v-tab.flex-column.align-start(:disabled="tab !== 4 && !isFinished")
                  h3.tab__title 5. Procedimientos Quirúrgicos
                  small.tab__subtitle.pl-5 Marca las opciones que correspondan.
                v-tab.flex-column.align-start(:disabled="tab !== 5 && !isFinished")
                  h3.tab__title 6. Resumen
                  small.tab__subtitle.pl-5 Completa los datos y recuerda los campos obligatorios.
                v-tab.flex-column.align-start(:disabled="tab !== 6 && !isFinished")
                  h3.tab__title 7. Valores
                  small.tab__subtitle.pl-5 Revisa que los datos coincidan y agrega otros si es necesario.
            v-col(cols="8")
              v-tabs-items(v-model="tab")
                v-tab-item
                  DatosCliente(v-if="modalPatientFile" :medicalRecord="localMedicalRecord" @nextTab="tab++"  @saveAndNext="saveChanges" :pet="petData" @created="onCreated")
                v-tab-item
                  General(:medicalRecord="localMedicalRecord" @previousTab="tab--" @saveAndNext="saveChanges")
                v-tab-item
                  Exploracion(:medicalRecord="localMedicalRecord" @previousTab="tab--" @saveAndNext="saveChanges")
                v-tab-item
                  CuidadosPreventivos(:medicalRecord="localMedicalRecord" :vacunas="vacunas" :desparasitarios="desparasitarios" @previousTab="tab--" @saveAndNext="saveChanges")
                v-tab-item
                  ProcedimientosQuirurgicos(:medicalRecord="localMedicalRecord" :cirugias="cirugias" :procedimientos="procedimientos" @previousTab="tab--" @saveAndNext="saveChanges")
                v-tab-item
                  Resumen(:medicalRecord="localMedicalRecord" @previousTab="tab--" @saveAndNext="saveChanges")
                v-tab-item
                  Valores(:medicalRecord="localMedicalRecord" @previousTab="tab--" @finish="saveChanges")
</template>
<script>
import DatosCliente from './FichaPaciente/DatosCliente.vue'
import General from './FichaPaciente/General.vue'
import Exploracion from './FichaPaciente/Exploracion.vue'
import CuidadosPreventivos from './FichaPaciente/CuidadosPreventivos.vue'
import ProcedimientosQuirurgicos from './FichaPaciente/ProcedimientosQuirurgicos.vue'
import Resumen from './FichaPaciente/Resumen.vue'
import Valores from './FichaPaciente/Valores.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    petId: {
      type: Number
    },
    medicalRecord: {
      type: Object
    },
  },
  components: {
    DatosCliente,
    General,
    Exploracion,
    CuidadosPreventivos,
    ProcedimientosQuirurgicos,
    Resumen,
    Valores
  },
  data () {
    return {
      pet: null,
      modalPatientFile: false,
      tab: 0,
      localMedicalRecord: null,
      dataIsLoaded: false,
      vacunas: [],
      desparasitarios: [],
      cirugias: [],
      procedimientos: []
    }
  },
  methods: {
    ...mapActions('service', ['patchMedicalRecord']),
    ...mapActions('product', ['searchProducts']),
    ...mapActions('service', ['searchServices']),
    async open () {
      this.localMedicalRecord = this.medicalRecord
      this.loadData()
      this.tab = 0
      if (this.petId) {
        const { data,  error } = await this.$store.dispatch('pet/getPet', { id: this.petId })
        if (error) {
          this.$toast.error('Ha ocurrido un error al cargar los datos de la mascota')
          return
        }
        this.pet = data
      }
      this.modalPatientFile = true
    },
    close () {
      this.modalPatientFile = false
    },
    onCreated (medicalRecord) {
      this.localMedicalRecord = medicalRecord
      this.tab++
      this.$emit('reloadData', medicalRecord)
    },
    loadData () {
      if (this.dataIsLoaded) return
      this.dataIsLoaded = true
      this.searchProducts({ category__name: 'Vacunas' }).then(
        response => {
          this.vacunas = response.data
        }
      )
      this.searchProducts({ category__name: 'Desparacitarios' }).then(
        response => {
          this.desparasitarios = response.data
        }
      )
      this.searchServices({ category__name: 'CIRUGIA' }).then(
        response => {
          this.cirugias = response.data
        }
      )
      this.searchServices({ category__name: 'PROCEDIMIENTO' }).then(
        response => {
          this.procedimientos = response.data
        }
      )
    },
    async saveChanges (data) {
      let dataToSend = data
      const hasFinished = Object.prototype.hasOwnProperty.call(data, 'finishing')
      if (hasFinished) {
        dataToSend = {
          is_finished: data.finishing,
          medical_record_products: data.products,
          medical_record_services: data.services,
          comment_for_sale: data.comment_for_sale
        }
      }
      await this.patchMedicalRecord({
        id: this.localMedicalRecord.id,
        data: dataToSend
      }).then(
        response => {
          this.localMedicalRecord = response.data
          if (hasFinished) {
            this.$emit('showAlert', response.data.is_finished)
            this.tab = 0
          } else {
            this.tab++
          }
          if (hasFinished) {
            this.modalPatientFile = false
            this.$emit('reloadData', response.data)
          }
        }
      ).catch(
        error => console.log(error)
      )
    }
  },
  watch: {
    medicalRecord: {
      handler () {
        this.localMedicalRecord = this.medicalRecord
      },
      immediate: true
    }
  },
  computed: {
    isFinished () {
      return this.medicalRecord?.is_finished
    },
    petData () {
      return this.pet || this.medicalRecord?.pet
    }
  },
}
</script>
<style lang="scss" scoped>

.active {
  color: black !important;
  background-color: #fff !important;
  opacity: 1 !important;
}
.active:before {
  background-color: #fff !important;
}
.tab__title {
  color: #000;
  text-transform: capitalize;
  letter-spacing: 0.01em;
}
.tab__subtitle {
  color: #7E8299;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-transform: none;
}

</style>
<style scoped>
.v-tabs--vertical > .v-tabs-bar .v-tab {
  height: 80px;
}
.v-tab {
  border-right: 1px solid lightgrey;
}
.tabs__col {
  background-color: #CFD1D9;
}
</style>
