<template lang="pug">
v-dialog(
  v-model="isActive"
  content-class="modal"
  persistent
)
  template(#activator="{ on, attrs }")
    v-btn(
      v-bind="attrs"
      v-on="on"
      color="primary"
    )
      v-icon(left) mdi-plus
      | Nueva Hora
  
  Card(
    ref="card"
    @refreshCalendar="$emit('refreshCalendar')"
    @closeDialog="isActive = false"
  )
</template>

<script>
import Card from "./Card.vue";

export default {
  components: { Card },

  data: () => ({
    isActive: false,
  }),

  watch: {
    isActive(_) {
      this.$nextTick(() => {
        this.$refs.card.resetForms();
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep .modal {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 84%;
    max-width: 1200px;
  }
}
</style>