var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.user.is_superuser ? _vm.headersSuperuser : _vm.headers,"items":_vm.items,"server-items-length":_vm.itemCount,"page":_vm.currentPage,"disable-sort":"","hide-default-footer":"","loading":_vm.isLoading},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.staffName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.staffName || '- - -'))]}},{key:"item.saleId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_vm._v(_vm._s(item.saleId))]),_c('v-btn',{staticClass:"action-btn",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleViewSale(item.saleId)}}},[_c('v-icon',{staticClass:"mb-1"},[_vm._v("mdi-text-box-search-outline")])],1)],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.dateTimeToLocaleDate(item.date)))]}},{key:"item.saleStatus",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"value":item.saleStatus}})]}},{key:"item.serviceAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberToPesos(item.serviceAmount)))]}},{key:"item.commissionRate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.commissionRate * 100)+"%")]}},{key:"item.commissionAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberToPesos(item.commissionAmount)))]}},{key:"no-data",fn:function(){return [_vm._v("No se encontraron datos.")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("La búsqueda no arrojó resultados.")]},proxy:true},{key:"loading",fn:function(){return [_vm._v("Cargando. Por favor espere.")]},proxy:true}])}),_c('ViewSaleDialog',{ref:"viewSaleDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }