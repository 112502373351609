<template lang="pug">
.d-flex.justify-end.mt-4
  v-pagination(
    v-model="model"
    :length="pageCount"
  )
</template>

<script>
export default {
  props: {
    value: Number,
    pageCount: Number,
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>