<template lang="pug">
v-select(
  v-model="model"
  :items="statusData"

  item-text="text"
  item-value="value"

  :placeholder="placeholder"
  :menu-props="{ offsetY: true }"
  dense filled hide-details
)
  template( #item="{ item }" )
    StatusChip(
      v-if="item.value !== 'clear'"
      :value="item.value"
    )
    span( v-else )
      | {{item.text}}

  template( #selection="{ item }" )
    StatusChip( :value="item.value" )
</template>

<script>
import StatusDataMixin from '../../mixins/StatusDataMixin'
import StatusChip from '../../shared/StatusChip.vue'

export default {
  mixins: [StatusDataMixin],
  components: { StatusChip },

  props: {
    placeholder: String,
    value: String,
  },

  data() {
    return {};
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        if (val === 'clear') {
          this.model = null;
        } else {
          this.$emit("input", val);
        }
      }
    },
  },

  watch: {
    model(v) {
      if (v === null) {
        this.getStatus('clear').disabled = true;
      }
      else {
        this.getStatus('clear').disabled = false;
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>