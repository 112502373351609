import axios from '../../plugins/axios'

export const searchServices = (_, params) => {
  return axios.get('service/', { params })
    .catch(err => err.response)
}

export const listServiceCategories = (_, params) => {
  return axios.get('service/service_category/', { params })
    .catch(err => err.response)
}

export const listServicesInCat = (_, params) => {
  return axios.get(`/service/`, { params })
    .catch(err => err.response)
}

export const listRooms = (_, params) => {
  return axios.get('service/room/', { params })
    .catch(err => err.response)
}

export const getCustomer = (_, { id }) => {
  return axios.get(`service/client/${id}/`)
    .catch(err => err.response)
}

export const listCustomers = (_, params) => {
  return axios.get('service/client/', { params })
    .catch(err => err.response)
}

export const createCustomer = (_, { data }) => {
  return axios.post('service/client/', data)
    .catch(err => err.response)
}

export const editCustomer = (_, { data }) => {
  return axios.put(`service/client/${data.id}/`, data)
  .catch(err => err.response)
}

export const listPets = (_, params) => {
  return axios.get('service/pet/', { params })
    .catch(err => err.response)
}

export const listPetsPag = (_, params) => {
  return axios.get('service/pet/list_pets', { params })
    .catch(err => err.response)
}

export const createPet = (_, { data }) => {
  return axios.post('service/pet/', data)
    .catch(err => err.response)
}

export const getPet = (_, id) => {
  return axios.get(`service/pet/${id}/`)
    .catch(err => err.response)
}

export const createAppointment = (_, { data }) => {
  return axios.post('service/appointment/', data)
    .catch(err => err.response)
}

export const updateAppointment = (_, { id, data }) => {
  return axios.put(`service/appointment/${id}/`, data)
    .catch(err => err.response)
}

export const listAppointments = (_, { id, params }) => {
  return axios.get(`service/pet/${id}/appointments/`, { params })
    .catch(err => err.response)
}

export const getMedicalRecord = (_, id) => {
  return axios.get(`service/medical_record/${id}/`)
    .catch(err => err.response)
}

export const patchMedicalRecord = (_, { id, data }) => {
  return axios.patch(`service/medical_record/${id}/`, data)
    .catch(err => err.response)
}

export const autocompleteServices = (_, params) => {
  return axios.get('service/autocomplete/', { params })
    .then(response => ({ data: response.data, error: null }))
    .catch(error => ({ data: [], error }))
}
