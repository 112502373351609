<template lang="pug">
v-dialog(v-model="isOpen" max-width=1400)
  v-form(ref="form" @submit.prevent="onSubmit" :readonly="getReadonly()")
    v-card
      v-card-title(justify="space-between")
        v-col
          h3 Nueva Venta
        v-col(cols="12" md="2" justify="end")
          v-autocomplete(
            v-model= "filteredArea"
            :items="listArea"
            :loading="isLoading"
            item-text="name"
            item-value="id"
            label="Filtrar por area"
          )
      v-card-text
        v-container(fluid)
          v-tabs(v-model="tab" centered fixed-tabs)
            v-tab(v-for="(tabItem, index) in tabItems" :key="tabItem" :disabled="index !== tab") {{tabItem}}
      v-divider
      v-card-text
        v-alert(v-if="form.comment_from_medical_record" outlined type="warning") Comentario: {{ form.comment_from_medical_record }}
        v-container(fluid)
          v-tabs-items(v-model="tab")
            v-tab-item(key="Productos/Servicios")
              h3.mb-2 Agregar Productos
              InputProductosEnVenta.mb-3(
                v-model="form.products_in_sale"
                @totalPrice="productTotalPrice=$event"
                :filterArea="filteredArea"
                :readonly="getReadonly()"
                ref="inputProductos"
              )
              h3.mb-2 Agregar Servicios
              InputServiciosEnVenta(
                v-model="form.services_in_sale"
                @totalPrice="serviceTotalPrice=$event"
                :filterArea="filteredArea"
                :readonly="getReadonly()"
                ref="inputServicios"
              )
              v-row(justify="end")
                v-col(cols="auto")
                  ResumenVenta(:serviceTotalPrice="serviceTotalPrice" :productTotalPrice="productTotalPrice")
              v-row(justify="space-between")
                v-col(cols="auto")
                  v-btn(color="primary" outlined @click="isOpen=false") Cancelar
                v-col(cols="auto")
                  v-btn(color="primary" @click="goNext") Siguiente
            v-tab-item(key="Pago")
              v-row()
                v-col(cols=12 md=6)
                  h3.mb-2 Cliente
                  FormCliente(v-model="form.client" :readonly="getReadonly()")
                v-col(cols=12 md=6)
                  h3.mb-2 Tipo de Pago
                  v-radio-group(v-model="form.payment_method" row)
                    v-radio(label="No pagado" :value="''" :key="''")
                    v-radio(label="Efectivo" value="Efectivo" key="Efectivo")
                    v-radio(label="Tarjeta" value="Tarjeta" key="Tarjeta")
                    v-radio(label="Otro" value="Otro" key="Otro")

              v-row(justify="end")
                v-col(cols="auto")
                  ResumenVenta(:serviceTotalPrice="serviceTotalPrice" :productTotalPrice="productTotalPrice")
              v-row(justify="space-between")
                v-col(cols="auto")
                  v-btn(color="primary" outlined @click="tab=0") Atrás
                v-col(cols="auto")
                  v-btn(v-if="!getReadonly()" color="primary" type="submit") Terminar
                  v-btn(v-else color="primary" @click="isOpen=false") Cerrar
</template>

<script>
import InputProductosEnVenta from './subcomponets/InputProductosEnVenta.vue'
import InputServiciosEnVenta from './subcomponets/InputServiciosEnVenta.vue'
import FormCliente from './subcomponets/FormCliente.vue'
import ResumenVenta from './subcomponets/ResumenVenta.vue'
import { mapActions, mapMutations } from 'vuex'
import { getArrayErrorsFromResponse } from '@/utils/apiErrors'

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InputProductosEnVenta,
    InputServiciosEnVenta,
    FormCliente,
    ResumenVenta
  },
  data () {
    return {
      isOpen: false,
      isLoading: false,
      filteredArea: null,
      listArea: [],
      tab: 0,
      tabItems: ['Productos/Servicios', 'Pago'],
      productTotalPrice: 0,
      serviceTotalPrice: 0,
      form: {
        products_in_sale: [],
        services_in_sale: [],
        payment_method: '',
        amount_paid: 0,
        client: null
      }
    }
  },
  methods: {
    ...mapActions('ventas', ['createSale', 'getSale', 'updateSale', 'getReceipt']),
    ...mapActions('product', ['listAreas']),
    ...mapMutations('ventas', ['SET_QUANTITY_BY_PRODUCT']),
    getReadonly () {
      return this.readonly || this.form.status === 'PAID'
    },
    reset () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.tab = 0
      this.form = {
        products_in_sale: [{ quantity: 1, is_fragmented_sale: false }],
        payment_method: '',
        services_in_sale: [{ service: null, quantity: 1 }],
        amount_paid: 0,
        client: null
      }
    },
    async open (id) {
      this.reset()
      if (id) {
        this.form = (await this.getSale(id)).data
      }
      this.isOpen = true
    },
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }

      const data = {
        ...this.form
      }
      data.products_in_sale = data.products_in_sale.map((p) => ({ ...p, product: p.product_data.id }))
      data.services_in_sale = data.services_in_sale.map((s) => ({ ...s, service: s.service_data.id }))

      if (data.payment_method === '') {
        data.amount_paid = 0
      }

      let response
      if (data.id) {
        response = await this.updateSale(data)
      } else {
        response = await this.createSale(data)
      }
      if (response.status >= 200 && response.status < 300) {
        this.$emit('save', response.data)
        this.isOpen = false
        this.printReceipt(response.data.id)
      } else {
        console.error(response)
        for (const error of getArrayErrorsFromResponse(response.data)) {
          this.$toast.error(error)
        }
      }
    },
    goNext () {
      this.form.products_in_sale = this.form.products_in_sale.filter((p) => p.product_data && p.quantity > 0)
      this.form.services_in_sale = this.form.services_in_sale.filter((s) => s.service_data && s.quantity > 0)
      this.$nextTick(() => {
        if (!this.$refs.form.validate()) {
          return
        }
        this.tab = 1
      })
    },
    onScanCode (code) {
      if (this.isOpen) {
        this.addProductOrServiceFromCode(code)
      }
    },
    async addProductOrServiceFromCode (code) {
      const response = await this.$store.dispatch('product/getProductOrServiceByCode', code)

      if (response.status < 200 || response.status >= 300) {
        this.$toast.error(response.data.detail)
        return
      }

      const { type, data } = response.data
      if (type === 'product') {
        this.addProduct(data)
      } else if (type === 'service') {
        this.addService(data)
      }
    },
    addProduct (product) {
      const productInSale = this.form.products_in_sale.find((p) => p.product_data?.id === product.id)
      if (productInSale) {
        productInSale.quantity += 1
      } else if (this.form.products_in_sale.length === 1 && !this.form.products_in_sale[0].product_data) {
        this.form.products_in_sale.splice(0, 1, {
          category: product.category,
          product_data: product,
          quantity: 1,
          is_fragmented_sale: false
        })
      } else {
        this.form.products_in_sale.push({
          category: product.category,
          product_data: product,
          quantity: 1,
          is_fragmented_sale: false
        })
      }
    },
    addService (service) {
      const serviceInSale = this.form.services_in_sale.find((s) => s.service_data?.id === service.id)
      if (serviceInSale) {
        serviceInSale.quantity += 1
      } else if (this.form.services_in_sale.length === 1 && !this.form.services_in_sale[0].service_data) {
        this.form.services_in_sale.splice(0, 1, {
          category: service.category,
          service_data: service,
          quantity: 1
        })
      } else {
        this.form.services_in_sale.push({
          category: service.category,
          service_data: service,
          quantity: 1
        })
      }
    },
    async printReceipt (id) {
      const res = await this.getReceipt({ id })
      if (res.status === 200) {
        window.open(URL.createObjectURL(res.data))
      }
    },
    updateQuantityByProduct () {
      const quantityByProduct = {}
      console.log('this.form.products_in_sale', [...this.form.products_in_sale])
      console.log('this.form.services_in_sale', [...this.form.services_in_sale])
      for (const product of this.form.products_in_sale) {
        
        if (product.product_data) {
          if (quantityByProduct.hasOwnProperty(product.product_data.id)) {
            quantityByProduct[product.product_data.id] += parseInt(product.quantity) || 0
          } else {
            quantityByProduct[product.product_data.id] = parseInt(product.quantity) || 0
          }
        }
      }

      for (const service of this.form.services_in_sale) {
        if (!service) {
          continue
        }
        console.log('service', service)

        if (!service.service_data) {
          continue
        }
        for (const product of service.service_data.product_data) {
          if (!product.product) {
            continue
          }

          if (quantityByProduct.hasOwnProperty(product.product.id)) {
            quantityByProduct[product.product.id] += (parseInt(service.quantity) || 0) * product.quantity
          } else {
            quantityByProduct[product.product.id] = (parseInt(service.quantity) || 0) * product.quantity
          }
        }
      }
      console.log('quantityByProduct', quantityByProduct)
      this.SET_QUANTITY_BY_PRODUCT(quantityByProduct)
    },
  },
  watch: {
    'form.products_in_sale': {
      deep: true,
      handler () {
        this.updateQuantityByProduct()
      }
    },
    'form.services_in_sale': {
      deep: true,
      handler () {
        this.updateQuantityByProduct()
      }
    }
  },
  async created () {
    let code = ''
    let reading = false
    window.document.addEventListener('keypress', (e) => {
      if (e.keyCode === 13) {
          if(code.length > 5) {
              this.onScanCode(code)
            }
        } else {
            code += e.key; //while this is not an 'enter' it stores the every key
        }

        //run a timeout of 200ms at the first read and clear everything
        if(!reading) {
            reading = true;
            setTimeout(() => {
                code = "";
                reading = false;
            }, 300);  //200 works fine for me but you can adjust it
        }
    })
    this.listArea = (await this.listAreas()).data
  },
 }
</script>
