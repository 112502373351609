<template lang="pug">
v-app
  v-app-bar.vtoolbar(v-if="$route.meta.hideHeader !== true" app color='#0FB9B1' dark=''  height='90' elevation=0)
    v-container.py-0.fill-height
      v-row
        //Burger-menu principal
        v-col.d-lg-none(cols=2 xl=1 lg=1 md=1 sm=1)
          v-app-bar-nav-icon.mt-4(@click.stop='drawer = !drawer')
        // Imagen Logo
        v-col(cols=3 xl=1 lg=1 md=7 sm=6)
          v-img.mt-5( width='65' height='50'  src='./assets/img/logo_solo_animales.svg')
        // Menu Principal
        v-col.d-none.d-lg-block(cols=12 xl=9 lg=7 style="margin-top: -2px;")
          v-toolbar.vtoolbar.mt-7(color='#0FB9B1' elevation=0)
            v-toolbar-items.hidden-sm-and-down
              v-btn.mr-1.btn-menu.text-capitalize(
                v-for="item in navItems"
                :to="item.url"
                color='#0FB9B1'
                active-class="btn-menu-active"
                elevation=0
                @click="selectedSubMenu = item.subMenu ? item.subMenu : null"
                :class="selectedSubMenu === item.subMenu ? 'btn-menu-active' : ''"
              )
                div.letra-menu {{item.text}}
        // opciones de Usuario
        v-col.mt-4(cols=6 xl=2 lg=4 md=4 sm=5 )
          v-flex.float-end.ml-1
            v-menu(transition='slide-y-transition' content-class='vmenu-content')
              template(v-slot:activator='{ on, attrs }')
                v-btn.ml-1(v-bind='attrs' v-on='on' color='white' elevation='2'  dark='' fab='' width='60' height='60')
                  H2.text-letras {{letraNombre}}{{letraApellido}}
              v-row.ml-3.mb-2.mt-4
                  v-list-item(@click="onLogout") Salir
          v-flex.float-end
            v-responsive.mt-2.text-right
              H4 {{usuario.username}} {{usuario.last_name}}
              H5  {{usuario.email}}
  v-main(v-if="$route.meta.hideHeader !== true" app style="background-color: #F3F6F9;")
    div.d-none.d-lg-block(v-if="selectedSubMenu" style="background-color: white;")
      v-container
        v-btn.mr-2.text-capitalize(v-for="subMenu in selectedSubMenu" text :to="subMenu.url" active-class="sub-menu-active-btn" x-large color="white")
          div.sub-menu-btn {{subMenu.text}}
    v-container
      router-view
    //Burger-menu lista
    v-navigation-drawer.navigation-drawer.d-lg-none(v-model='drawer' absolute)
      v-list
        v-list-item-group(v-model='group' active-class='#0FB9B1--text text--accent-4')
          v-list-item(v-for="item in navItems" v-if="!item.subMenu" :to="item.url")
            v-list-item-title {{item.text}}
        v-list-group(v-for="item in navItems" v-if="item.subMenu" no-action sub-group)
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title {{item.text}}
          v-list-item(v-for="subMenu in item.subMenu" :to="subMenu.url")
            v-list-item-title {{subMenu.text}}
  v-main(v-else app style="background-color: #F3F6F9;")
    router-view
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    drawer: false,
    group: null,
    usuario: '',
    letraNombre:'',
    letraApellido:'',
    watch: {
      group () {
        this.drawer = false
      },
    },
    selectedMenuItem: null,
    selectedSubMenu: null,
    navItems: [
      {
        text: 'Ventas',
        url: '/ventas'
      },
      {
        text: 'Pacientes',
        url: '/pacientes'
      },
      {
        text: 'Agenda',
        url: '/agenda'
      },
      {
        text: 'Inventario',
        subMenu: [
          {
            text: 'Categorías',
            url: '/categorias'
          },
          {
            text: 'Marcas',
            url: '/marcas'
          },
          {
            text: 'Precios',
            url: '/precio-producto'
          },
          {
            text: 'Productos',
            url: '/productos'
          },
          {
            text: 'Servicios',
            url: '/servicios'
          },
          {
            text: 'Stock',
            url: '/movimiento-stock'
          },
          {
            text: 'Área',
            url: '/area'
          },
        ]
      },
      {
        text: 'Servicios',
        subMenu: [
          {
            text: 'Salas',
            url: '/salas'
          },
          {
            text: 'Prestaciones',
            url: '/prestaciones'
          }
        ]
      },
      {
        text: 'Usuarios',
        url: '/usuarios'
      }
    ]
  }),
  mounted () {
    if (localStorage.user) {
      this.usuario = JSON.parse(localStorage.user)
      this.letraNombre = this.usuario.username.substring(0,1)
      this.letraApellido = this.usuario.last_name.substring(0,1)
    }
  },

  methods: {
    ...mapActions('auth', ['logout']),
    onLogout () {
      this.logout()
        .then(() => this.$router.push('/login'))
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-letras{
    color: #0FB9B1;
    }
  .sub-menu-btn {
    color: #A1A7B5 !important;
  }
  .sub-menu-active-btn {
    color: white !important;

    .sub-menu-btn{
      color: #0FB9B1 !important;
    }
  }
  .btn-menu-active{
    background-color: white !important;
    .letra-menu{
      color: #0FB9B1 !important;
    }
  }
  .vmenu-content{
    border-radius: 10px !important;
    margin: 70px 0px 0px 0px;
    width: 160px;

    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #0FB9B1 !important
  }
  .usermenu-list::v-deep .v-list-item{
    color: #0FB9B1 !important;
  }

  .vtoolbar::v-deep .v-toolbar__content, .vtoolbar::v-deep .v-toolbar__extension {
    padding: 0px !important;
  }

  .btn-menu{
    height: 60px;
    border-radius: 15px 15px 0px 0px !important;
  }
  .btn-menu:before{
    border-radius: 15px 15px 0px 0px !important;
  }
  .letra-menu{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-21)/var(--unnamed-line-spacing-27) var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal bold 21px/27px Roboto;
    letter-spacing: 0px;

  }
</style>

<style lang="scss">
  @import "./scss/global.scss";
</style>
