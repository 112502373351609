const mutations = {
  'SET_USER' (state, value) {
    state.user = value
    if (value) {
      localStorage.setItem('user', JSON.stringify(value))
    } else {
      localStorage.removeItem('user')
    }
  }
}

export default mutations
