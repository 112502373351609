<template lang="pug">
v-container.rounded.elevation-1(v-if="!loading" style="background-color: #FFFFFF")
  v-row.pa-5
    v-col.px-0(cols="4")
      .pet-image
    v-col(cols="6")
      h4(style="color: #474787;") {{ patient.name }}
      h5 {{ patient.specie }}
    //- v-col(cols="2")
    //-   v-btn.pr-2(icon color="primary")
    //-     v-icon mdi-pencil
  v-row.pt-5
    v-divider
  v-row.flex-column.pa-5
    p Nombre
    span {{ patient.name }}
    p Especie
    span {{ patient.specie }}
    p Raza
    span {{ patient.race }}
    p Sexo
    span {{ patient.sex }}
    p Edad
    span {{ calculateAgePatient }}
    p Peso
    span {{ patient.weight }} kg.
    p Color
    span {{ patient.color }}
    p Nombre Dueño
    span {{ patient.client.name }}
    p Contacto
    span {{ patient.client.contact }}
    //- p Contacto 2
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      patient: {},
      loading: true
    }
  },
  methods: {
    ...mapActions('service', ['getPet']),
    async fetchData () {
      this.loading = true
      const patientID = +this.$route.params.idPaciente
      await this.getPet(patientID).then(
        response => {
          this.patient = response.data
          this.loading = false
        }
      )
    }
  },
  computed: {
    calculateAgePatient () {
      const today = new Date()
      const birthDate = new Date(this.patient.date_of_birth)
      const yearsDifference = today.getFullYear() - birthDate.getFullYear()
      const monthDifference = today.getMonth() - birthDate.getMonth()
      let message = ''
      if (yearsDifference <= 0) {
        message = `${monthDifference} meses.`
      } else if (monthDifference <= 0) {
        message = `${yearsDifference} años.`
      } else {
        message = `${yearsDifference} años y ${monthDifference} meses.`
      }
      return message
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.pet-image {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background-image: url('../../../assets/img/logo_solo_animales.svg');
  background-position: center;
  background-color: #F3F6F9;
  background-size: 50px 50px;
}

p {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #474787;
  margin-bottom: 0;
  margin-top: 20px;
}

span {
  color: #7E8299;
}

.img-logo{
  margin: 0 auto ;
  margin-top: 5%;
}
</style>