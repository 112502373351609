<template lang="pug">
v-container.pt-1
  v-card(elevation="0")
    v-card-title.pt-0.justify-space-between
      h5.black--text Exploración
      small.grey--text Fecha de atención: {{ formatDate(medicalRecord.start_date) }}

    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Peso(kg)
        v-text-field(v-model="explorationData.weight" type="number" outlined dense placeholder="Rellenar" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Temperatura ºC
        v-text-field(v-model="explorationData.temperature" type="number" outlined dense placeholder="Rellenar" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 FC
        v-text-field(v-model="explorationData.fc" outlined dense placeholder="Rellenar" :hide-details="true")
    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 FR
        v-text-field(v-model="explorationData.fr" outlined dense placeholder="Rellenar" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Ganglios
        v-text-field(v-model="explorationData.ganglions" outlined dense placeholder="Rellenar" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 SpO2 %
        v-text-field(v-model="explorationData.spo2" outlined dense placeholder="Rellenar" :hide-details="true")

    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 TRC
        v-text-field(v-model="explorationData.trc" outlined dense placeholder="Rellenar" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Mucosas
        v-text-field(v-model="explorationData.mucous" outlined dense placeholder="Rellenar" :hide-details="true")
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Sist.Digestivo
        v-text-field(v-model="explorationData.digestive_system" outlined dense placeholder="Rellenar" :hide-details="true")
    v-row.ml-1
      v-col.d-flex.justify-start.flex-column(cols="4")
        h5 Sist.Nervioso
        v-text-field(v-model="explorationData.nervous_system" outlined dense placeholder="Rellenar" :hide-details="true")
    v-card-actions.card-actions
      v-row.ml-2.justify-space-between
        v-btn.form-btn.justify-center(color="primary" outlined elevation="0" @click="$emit('previousTab')") Atrás
        v-btn.form-btn.justify-center.mr-1(color="primary" elevation="0" @click="saveData") Guardar y continuar
</template>
<script>
export default {
  props: {
    medicalRecord: {
      type: Object,
      default: () => {
        return {
          weight: null,
          temperature: null,
          fc: '',
          fr: '',
          ganglions: '',
          spo2: '',
          trc: '',
          mucous: '',
          digestive_system: '',
          nervous_system: ''
        }
      }
    }
  },
  data () {
    return {
      explorationData: {
        weight: this.medicalRecord.weight !== null ? this.medicalRecord.weight : null,
        temperature: this.medicalRecord.temperature !== null ? this.medicalRecord.temperature : null,
        fc: this.medicalRecord.fc !== '' ? this.medicalRecord.fc : '',
        fr: this.medicalRecord.fr !== '' ? this.medicalRecord.fr : '',
        ganglions: this.medicalRecord.ganglions !== '' ? this.medicalRecord.ganglions : '',
        spo2: this.medicalRecord.spo2 !== '' ? this.medicalRecord.spo2 : '',
        trc: this.medicalRecord.trc !== '' ? this.medicalRecord.trc : '',
        mucous: this.medicalRecord.mucous !== '' ? this.medicalRecord.mucous : '',
        digestive_system: this.medicalRecord.digestive_system !== '' ? this.medicalRecord.digestive_system : '',
        nervous_system: this.medicalRecord.nervous_system !== '' ? this.medicalRecord.nervous_system : ''
      }
    }
  },
  methods: {
    saveData () {
      this.$emit('saveAndNext', this.explorationData)
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  }
}
</script>
<style lang="scss" scoped>
.card-actions {
  margin-top: 15%;
}
.form-btn {
  text-transform: none;
}
</style>
