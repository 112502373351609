<template lang="pug">
v-container.rounded-lg.pa-10(style="background-color: #F3F6F9;" fluid)
  h3(v-if="medicalRecords.length === 0" style="text-align: center;") No hay tratamientos futuros.
  v-row.row-treatments.mb-5.rounded(v-for="treatment in medicalRecords" :key="treatment.id")
    v-col(cols="3").hour-col.content-col
      h4.color {{formatDate(treatment.start_date)}}
      p {{formatHour(treatment.start_date)}}
        span(v-if="treatment.end_date") - {{formatHour(treatment.end_date)}}hrs.
    v-col(cols="8")
      v-row
        v-col.content-col
          p.color Servicio
          p(v-if="treatment.service_name" style="text-align: left;") {{treatment.service_name}}
          p(v-else) No especificado
        v-col.content-col
          p.color Profesional
          p(style="text-align: left;") {{treatment.professional_name}}
        v-col.content-col
          p.color Box
          p {{treatment.room_name}}
    v-col(cols="1").d-flex.align-center.pl-0
      v-btn(color="#0FB9B1" icon @click="openModalPatientFile(treatment)" :disabled="loading")
        v-icon(v-if="!loading") {{ treatment.is_finished ? 'mdi-eye' : 'mdi-pencil' }}
        v-progress-circular.progress(v-else indeterminate)
      v-btn.icon(color="#0FB9B1" icon @click="goToMedicalRecordPdf(treatment.id)" :disabled="loading")
        v-icon mdi-printer
    v-col.pt-0(cols=12).content-col
      p.color Diagnóstico
      p {{treatment.diagnosis || 'No especificado'}}
  v-pagination.mt-4(v-model="page" :length="pageCount" circle color="primary" elevation=0)
  ModalFichaPaciente(ref="modalPatientFile" :medicalRecord="medicalRecord" :vacunas="vacunas" :desparasitarios="desparasitarios" :cirugias="cirugias" :procedimientos="procedimientos" @reloadData="fetchData()" @showAlert="showAlert")
  .alertContainer
    v-alert(:value="alert" color="success" dark transition="fade-transition") {{ alertMessage }}
</template>
<script>
import { mapActions } from 'vuex'
import ModalFichaPaciente from '../ModalFichaPaciente.vue'
import settings from '../../../../settings'

export default {
  components: {
    ModalFichaPaciente
  },
  data () {
    return {
      medicalRecord: null,
      vacunas: [],
      desparasitarios: [],
      cirugias: [],
      procedimientos: [],
      loading: false,
      medicalRecords: [],
      page: 1,
      rowCount: 0,
      selectedTreatment: {},
      alert: false,
      alertMessage: ''
    }
  },
  methods: {
    ...mapActions('service', ['listAppointments', 'getMedicalRecord']),
    ...mapActions('product', ['searchProducts']),
    ...mapActions('service', ['searchServices']),
    goToMedicalRecordPdf (medicalRecordId) {
      window.open(`${settings.API_URL}/api/service/medical_record/${medicalRecordId}/pdf/`, '_blank')
    },
    async fetchData () {
      const params = {
        pet: this.$route.params.idPaciente,
        ordering: '-start_date',
        page: this.page
      }
      const { data, error } = await this.$store.dispatch('medicalRecord/listMedicalRecords', { params })
      if (error) {
        this.$toast.error('Ha ocurrido un error al cargar los tratamientos')
        return
      }
      this.medicalRecords = data.results
      this.rowCount = data.count
    },
    async openModalPatientFile (treatment) {
      this.loading = true
      await this.getMedicalRecord(treatment.id).then(
        async response => {
          if (response.status === 200) {
            this.medicalRecord = response.data
            await this.searchProducts({ category__name: 'Vacunas' }).then(
              response => {
                this.vacunas = response.data
              }
            )
            await this.searchProducts({ category__name: 'Desparacitarios' }).then(
              response => {
                this.desparasitarios = response.data
              }
            )
            await this.searchServices({ category__name: 'CIRUGIA' }).then(
              response => {
                this.cirugias = response.data
              }
            )
            await this.searchServices({ category__name: 'PROCEDIMIENTO' }).then(
              response => {
                this.procedimientos = response.data
              }
            )
          }
        }
      )
      this.loading = false
      this.$refs.modalPatientFile.open()
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    formatHour (date) {
      return new Intl.DateTimeFormat('cl-ES', { hour: 'numeric', minute: 'numeric' }).format(new Date(date))
    },
    showAlert (isFinished) {
      const timeInterval = isFinished ? 5000 : 2000
      this.$refs.modalPatientFile.close()
      this.alert = !this.alert
      this.alertMessage = isFinished ? `La ficha médica se ha completado con éxito y se ha creado una nueva venta a nombre de ${this.medicalRecord.appointment.client.name}. Puedes revisarla desde la sección "Ventas"` : 'La ficha médica ha sido guardada con éxito'
      setTimeout(() => {
        this.alert = false
      }, timeInterval)
    }
  },
  watch: {
    page () {
      this.fetchData()
    }
  },
  computed: {
    pageCount () {
      return Math.ceil(this.rowCount / 10)
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
<style lang="scss" scoped>
.color {
  color: #474787;
  font-weight: 600;
}

.hour-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h4 {
    margin-top: 3px;
  }
}
.content-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    margin-top: 5px;
    margin-bottom: 0px;
  }
}
.row-treatments {
  background-color: #fff;
  text-align: left;
  border-radius: 5px;
}
.progress {
  display: block;
  width: 100% !important;
}
.alertContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 101;
}
</style>
