<template lang="pug">
v-col(cols="12" sm="12" md="4" v-if="labels && labels.product").mr-8
  v-row.mb-12.flex-column
    span.toplabel Precio actual
    label.mt-2.label-grey {{ labels.product.price | currency }}

  v-row(justify="space-between").mb-5
    span.label-black Precio Unidad Completa
    span.label-grey {{ labels? labels.product.purchase_size : 0 }} {{ labels.product.unit }}

  v-row.flex-column.mb-2
    v-col(cols="12" sm="6" md="6").pa-0
      label(for="cost").toplabel Costo
      v-text-field(
        type="number"
        outlined dense placeholder="Ingrese costo"
        id="cost"
        prepend-icon="mdi-currency-usd"
        v-model.number="form.net_cost"
        :rules="validationRules"
      ).mt-2

  v-row.flex-column.mb-2
    v-col(cols="12" sm="6" md="6").pa-0
      label(for="profit_percentage").toplabel Porcentaje de Utilidad
      v-text-field(
        type="number"
        outlined dense placeholder="Ingrese %"
        id="profit_percentage"
        append-outer-icon="mdi-percent-outline"
        v-model.number="profitPercentage"
        :rules="validationRules"
      ).mt-2

  v-row.flex-column.mb-4
    span.toplabel Activar IVA.
    v-checkbox(label="Incluir IVA." v-model="form.iva").mt-2

  v-radio-group(v-model="form.manual" row).ml-n3
    v-col(cols="12" sm="12" md="6").flex-column.pa-0.mb-sm-4
      v-radio(:value="false").mb-2
        template(v-slot:label)
          .toplabel.mb-2 Precio Total
      span.label-secondary.ml-8.mt-2 {{ calculatedPrice | currency }}

    v-col(cols="12" sm="6" md="6").flex-column.pa-0
      v-radio(:value="true")
        template(v-slot:label)
          .toplabel.mb-2 Precio Manual
      div(v-if="form.manual")
        v-text-field(
          type="number"
          outlined dense placeholder="Ingrese Precio"
          id="manual_price"
          prepend-icon="mdi-currency-usd"
          v-model.number="manualPrice"
          :rules="validationRulesManualPrice"
        )

        div.ml-8
          span.label-italic Utilidad
          v-icon(color="secondary") mdi-arrow-top-right
          span.label-secondary {{ profitPercentageManual }}%

</template>

<script>
const IVA = 0.19

export default {
  props: ['labels', 'form'],
  data () {
    return {
      manualPrice: null,
      profitPercentage: null,
      validationRules: [
        v => (!!v || v === 0) || 'Este campo es requerido',
        v => v >= 0 || 'Este campo debe ser mayor o igual a 0'
      ],
      validationRulesManualPrice: [
        v => ((!!v || v === 0) && this.form.manual) || 'Este campo es requerido',
        v => v >= 0 || 'Este campo debe ser mayor o igual a 0'
      ]
    }
  },
  methods: {
    reset () {
      this.manualPrice = null
      this.profitPercentage = null
    },
    setPriceSubFormData () {
      this.form.total_price = this.form.manual ? this.manualPrice : this.calculatedPrice
      this.form.profit_percentage = this.form.manual ? this.profitPercentageManual : this.profitPercentage
    }
  },
  computed: {
    calculatedPrice: function () {
      const ivaMultiply = Number(this.form.iva ? IVA : 0)
      const netCost = Number(this.form.net_cost)
      const profitPercent = Number(this.profitPercentage) / 100
      const price = netCost + (netCost * profitPercent) + (netCost * ivaMultiply)
      if (isNaN(price)) return '--'
      return Math.round(price)
    },
    profitPercentageManual: function () {
      const ivaMultiply = Number(this.form.iva ? IVA : 0)
      const profitPercentManual = ((Number(this.manualPrice) - Number(this.form.net_cost) - (Number(this.form.net_cost) * ivaMultiply)) / Number(this.form.net_cost)) * 100
      if (isNaN(profitPercentManual) || !isFinite(profitPercentManual)) return '--'
      return Math.round(profitPercentManual)
    }
  }
}
</script>
