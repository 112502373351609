<template lang="pug">
v-container.mt-9
  h2 Lista de Pacientes
  div(style="background-color: #fff; border-radius: 5px; margin-top: 20px;")
    v-row
      v-col(cols="6")
        v-text-field.pt-7.pl-3(v-model="search" filled outlined placeholder="Buscar" class="shrink mx-5" prepend-inner-icon="mdi-magnify" style="width: 300px" dense)
      v-col.d-flex.justify-end.align-center(cols="6")
        v-btn.mr-8.rounded-lg(color="primary" dense @click="$refs.petForm.open()") + Nuevo Paciente
    v-data-table(
      :headers="headers"
      :items="patient"
      class="elevation-0"
      hide-default-footer
      :page.sync="page"
      :rows-per-page="pagination.rowsPerPage"
      :server-items-length="rowCount"
      :loading="loading"
      @update:sort-by="sortData"
      @update:sort-desc="sortDesc"
    )

      template(v-slot:item.name="{item}")
        p.namePet {{ item.name }}

      template(#item.latest_record_date="{ item }")
        | {{ formatDate(item.latest_record_date) }}

      template(v-slot:item.actions="{ item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="$router.push({ name: 'Detalle Paciente', params: { idPaciente: item.id }})")
                  v-icon(style="color: #8E8E8E") mdi-eye-outline
              span Ver Detalle
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="$refs.petForm.open(item.id)")
                  v-icon(style="color: #8E8E8E") mdi-pencil-outline
              span Editar Paciente
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="$refs.mergePetForm.open(item.id)")
                  v-icon(style="color: #8E8E8E") mdi-account-convert-outline
              span Fusionar Paciente
            v-tooltip(bottom)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon large v-bind="attrs" v-on="on" @click="deletePet(item)")
                  v-icon(style="color: #8E8E8E") mdi-trash-can-outline
              span Eliminar Paciente

      template(#footer="{ props, on }")
        v-pagination.py-4(
          v-model="page"
          :length="props.pagination.pageCount"
          :total-visible="7"
          circle
          color="primary"
          elevation=0
        )
  ConfirmModal(ref="confirm")
  PetForm(ref="petForm" @saved="fetchData")
  MergePetForm(ref="mergePetForm" @saved="fetchData")
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmModal from '../shared/ConfirmModal.vue'
import PetForm from './PetForm.vue'
import MergePetForm from './MergePetForm.vue'

export default {
  components: {
    ConfirmModal,
    PetForm,
    MergePetForm
  },
  data () {
    return {
      headers: [
        { text: 'Nombre', value: 'name', sortable: true, class: 'header' },
        { text: 'Especie', value: 'specie', sortable: true, class: 'header' },
        { text: 'Raza', value: 'race', sortable: true, class: 'header' },
        { text: 'Sexo', value: 'sex', sortable: false, class: 'header' },
        { text: 'Nombre Dueño', value: 'client.name', sortable: true, class: 'header' },
        { text: 'Contacto', value: 'client.contact', sortable: false, class: 'header' },
        { text: 'Última consulta', value: 'latest_record_date', sortable: true, class: 'header' },
        { text: 'Acciones', value: 'actions', sortable: false, class: 'header' }
      ],
      search: null,
      patient: [],
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        rowCount: 0
      },
      page: 1,
      rowCount: 0,
      ordering: null
    }
  },
  methods: {
    ...mapActions('service', ['listPets']),
    async fetchData () {
      this.loading = true
      const query = {
        page: this.page,
        page_size: this.pagination.rowsPerPage,
        search: this.search,
        ordering: this.ordering || '-id'
      }
      await this.listPets(query).then(
        response => {
          this.patient = response.data.results
          this.rowCount = response.data.count
          this.loading = false
        }
      )
    },
    sortData (value) {
      this.ordering = value
      if (this.ordering === 'client.name') {
        this.ordering = 'owner__name'
      }
      this.fetchData()
    },
    sortDesc (value) {
      if (!this.ordering) return
      this.ordering = value ? `-${this.ordering}` : this.ordering.replace(/^-/, '')
      this.fetchData()
    },
    async deletePet (pet) {
      const ok = await this.$refs.confirm.open('Eliminar Paciente', `¿Está seguro que desea eliminar al paciente "${pet.name}"?`, { okTitle: 'Eliminar' })
      if (ok) {
        const { error } = await this.$store.dispatch('pet/deletePet', pet.id)
        if (!error) {
          this.$toast.success('Paciente eliminado correctamente')
          this.fetchData()
        } else {
          this.$toast.error('No se pudo eliminar el paciente')
        }
      }
    },
    formatDate (date) {
      if (!date) return ''
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    }
  },
  watch: {
    search () {
      if (this.page !== 1) {
        this.page = 1
      } else {
        this.fetchData()
      }
    },
    page: {
      handler () {
        this.fetchData()
      },
      immediate: true
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-data-table-header  {
  background-color: #F3F6F9;
}

.namePet {
  color: #474787;
  text-align: start;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 5px;
}
</style>
