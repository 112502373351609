<template lang="pug">

v-dialog(v-model="isOpen" max-width=1400 @click:outside="resetForm")
  v-form(ref="form" @submit.prevent="saveProductPrice")
    v-card
      v-card-title
        h3 Nuevo Precio de Producto

      v-divider

      v-card-subtitle.mt-2.mb-2
        v-container(fluid)
          v-row
            v-col(cols="12" md="1").pl-0
                span.label-black Producto
            v-col(cols="12" md="6").pa-0
              v-autocomplete(
                v-model="product"
                :items="products"
                item-text="name"
                item-value="id"
                no-filter
                :rules="validationRules"
                @update:search-input="autocompleteProductSearch"
                outlined dense)

      v-card-text.pb-0
        v-container(fluid)
          v-row
            PriceSubForm(
              :labels="labels"
              :form="form"
              ref="priceSubForm"
            )

            v-divider(vertical).d-none.d-md-block

            FragmentedPriceSubForm(
              :labels="labels"
              :form="form"
              ref="fragmentedPriceSubForm")

      v-card-actions
        v-container(fluid)
          v-row(justify="end")
            v-col(cols="12" sm="6" md="2").pa-0.ml-md-7.mb-7.mx-sm-0
              v-btn(color="primary" plain @click="resetForm").w-100 Restaurar Valores
            v-col(cols="12" sm="6" md="2").pa-0.ml-md-5.mb-7.mx-sm-0
              v-btn(color="primary" outlined @click="closeDialog").w-100  Cancelar
            v-col(cols="12" sm="6" md="2").pa-0.mx-md-7.mb-7.mx-sm-0
              v-btn(color="primary" type="submit").w-100 Guardar
</template>

<script>
import { mapActions } from 'vuex'
import PriceSubForm from './PriceSubForm.vue'
import FragmentedPriceSubForm from './FragmentedPriceSubForm.vue'

export default {
  data () {
    return {
      isOpen: false,
      precioRadio: null,
      product: null,
      products: [],
      labels: null,
      form: {
        product: null,
        net_cost: null,
        profit_percentage: null,
        fragmented_unit_price: null,
        fragmented_profit_percentage: null,
        total_price: null,
        iva: false,
        manual: false
      },
      validationRules: [v => !!v || 'Este campo es requerido']
    }
  },
  watch: {
    product (id) {
      if (id) this.loadLabels(id)
      else this.labels = null
    }
  },
  methods: {
    ...mapActions('precios', ['getProductPrice', 'createProductPrice']),
    ...mapActions('product', { listProducts: 'searchProducts' }),
    async autocompleteProductSearch (search) {
      const params = { search }

      const { data, error } = await this.$store.dispatch('product/autocompleteProducts', params)
      if (!error) {
        this.products = data
      }
    },

    open () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.isOpen = true
    },
    async loadLabels (id) {
      if (id) {
        this.labels = {}
        const product = this.products.find(p => p.id === id)
        this.labels.product = product
        this.form.product = this.product
      }
    },
    async saveProductPrice () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.$refs.fragmentedPriceSubForm.setFragmentedPriceSubFormData()
      this.$refs.priceSubForm.setPriceSubFormData()

      const response = await this.createProductPrice(this.form)
      if (response && response.data) {
        this.closeDialog()
        this.$emit('reloadData')
      }
    },
    resetForm () {
      this.product = null
      this.labels = {}
      this.form = { manual: false, iva: false }
      this.$refs.priceSubForm.reset()
    },
    closeDialog () {
      this.isOpen = false
      this.resetForm()
    }
  },
  components: { PriceSubForm, FragmentedPriceSubForm }
}
</script>

<style lang="scss">
  .w-100 {
    width: 100%;
  }
</style>
