import axios from '../../plugins/axios'

export const searchProducts = (_, params) => {
  return axios.get('inventory/product/', { params })
    .catch(err => err.response)
}

export const searchCategories = (_, params) => {
  return axios.get('inventory/category/', { params })
    .catch(err => err.response)
}

export const listAreas  = (_, params) => {
  return axios.get('inventory/area/', { params })
    .catch(err => err.response)
}

export const getProductByCode = (_, code) => {
  return axios.get('inventory/product/scan/', { params: { code } })
    .catch(err => err.response)
}

export const getProductOrServiceByCode = (_, code) => {
  return axios.get('inventory/scan/', { params: { code } })
    .catch(err => err.response)
}

export const autocompleteProducts = (_, params) => {
  return axios.get('inventory/product/autocomplete/', { params })
    .then(response => ({ data: response.data, error: null }))
    .catch(error => ({ data: [], error }))
}
