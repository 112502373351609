// TODO: Remove this file, use mixin version instead

export const dateTimeToLocaleDate = (datetime) => {
  return new Date(datetime)
    .toLocaleDateString('es-CL', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
}

export const dateTimeToLocaleTime = (datetime) => {
  return new Date(datetime)
    .toLocaleTimeString('es-CL', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })
}

export const formatDate = (date) => {
  return new Date(date)
    .toLocaleDateString('es-CL', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
}


export const numberToPesos = (amount) => {
  let formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  });
  return formatter.format(amount).replace(/^(\D+)/, '$1 ');
}