<template  lang="pug">
.img-fondo
  v-responsive
    v-img.img-logo(max-width='290' src='../assets/img/logo.svg')
  login
  v-footer.footer-custom(color='rgb(255,0,0,0)' padless='')
    v-card-text.white--text.pt-0.text-center
      | &copy; Veterinaria Agr&iacute;cola 2022, Todos los Derechos Reservados. Desarrollado por Eleva
</template>
<script>
import Login from '../components/auth/Form-Login'
export default {
  components: {
    Login
  }
}
</script>
<style scoped>
/* ::-webkit-scrollbar {
    display: none;
} */
.img-fondo{
  
  background: transparent url('../assets/img/fondo_login.png') 0% 0% no-repeat padding-box !important;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.img-logo{
  margin: 0 auto ;
  margin-top: 5%;
}
.footer-custom{
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
</style>