import axios from '../../plugins/axios'

export const createSale = (_, data) => {
  return axios.post('order/sale/', data)
    .catch(err => err.response)
}

export const getSale = (_, id) => {
  return axios.get(`order/sale/${id}/`)
    .catch(err => err.response)
}

export const updateSale = (_, data) => {
  return axios.put(`order/sale/${data.id}/`, data)
    .catch(err => err.response)
}

export const listSales = (_, { params }) => {
  return axios.get('order/sale/', { params })
    .catch(err => err.response)
}

export const getReceipt = (_, { id }) => {
  const options = { responseType: 'blob' }
  return axios.get(`order/sale/${id}/receipt/`, options)
    .catch(err => err.response)
}

export const getSalesReport = (_, { params }) => {
  return axios.get('/order/sale/export_ventas/', { params, responseType : 'blob'})
    .catch(err => err.response)
}

export const getProfitsReport = (_, { params }) => {
  return axios.get('/order/sale/export_profits_report/', { params, responseType : 'blob'})
    .catch(err => err.response)
}
