<template lang="pug">
div
  v-data-table(
    :headers="headers"
    :items="items"
    :server-items-length="itemCount"
    :page.sync="currentPage"
    @page-count="pageCount = $event"

    disable-sort
    hide-default-footer
    class="elevation-1 table"
    :loading="isLoading"
  )
    template( #item.datetime="{ item }" )
      | {{dateTimeToLocaleDate(item.datetime)}},
      | {{dateTimeToLocaleTime(item.datetime)}}

    template( #item.description="{ item }" )
      span {{item.description}}
      span( v-if="item.hasMoreThanOneItem" )
        | ,
        a(href="#" @click.prevent="handleViewSale(item.id)") ...

    template( #item.amount="{ item }" )
      | {{numberToPesos(item.amount)}}

    template( #item.status="{ item }" )
      StatusChip( :value="item.status" )

    template( #item.actions="{ item }" )
      v-btn( text @click="$emit('openEditDialog', item.id)" ).action-btn
        v-icon mdi-pencil
      v-btn( text @click="showDialogDelete(item.id)" v-if="showDeleteBtn" ).action-btn
        v-icon mdi-delete
      v-btn( text @click.stop="handleViewSale(item.id)" ).action-btn
        v-icon mdi-text-box-search-outline
      v-btn( text @click="handleGetReceipt(item.id)" v-if="item.status === 'PAID'" ).action-btn
        v-icon mdi-currency-usd

  ViewSaleDialog(ref="viewSaleDialog")

  // Confirma eliminar
  v-row(justify='center')
    v-dialog(v-model='dialogDelete' max-width='290')
      v-card
        v-card-title.headline Eliminar
        v-card-text Seguro que desea eliminar esta venta?
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='deleteEvent') Eliminar
          v-btn(color='green darken-1' text='' @click='dialogDelete = false; selectedSale = null') Cancelar
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import FormatterMixin from '@/components/mixins/FormatterMixin'
import StatusChip from '../../shared/StatusChip.vue'
import ViewSaleDialog from '../../ventas/listado/ViewSaleDialog.vue'
import checkGroups from '../../../utils/checkGroups'
import settings from '@/../settings'
import axios from 'axios'
import { GROUP } from '../../../cons/group.js'

const ITEMS_PER_PAGE = 10

export default {
  mixins: [ FormatterMixin ],
  components: { StatusChip, ViewSaleDialog },

  data() {
    return {
      isLosading: true,
      items: [],
      headers: [
        { text: 'ID',           value: 'id' },
        { text: 'Fecha y Hora', value: 'datetime' },
        { text: 'Cliente',      value: 'customer' },
        { text: 'Descripción',  value: 'description' },
        { text: 'Monto',        value: 'amount',      width: '110px' },
        { text: 'Estado Venta', value: 'status',      width: '100px' },
        { text: 'Acciones',     value: 'actions',     width: '178px' }
      ],
      showDeleteBtn: false,
      dialogDelete: false,
      selectedSale: null
    }
  },

  computed: {
    ...mapFields('saleList', [
      'itemCount',
      'pageCount',
      'currentPage',
      'filterQuery',
      'filterDates',
      'filterStatus'
    ])
  },

  watch: {
    itemCount(v) { this.pageCount = Math.ceil(v / ITEMS_PER_PAGE) }
  },

  methods: {
    ...mapActions('ventas', [
      'listSales',
      'getReceipt',
    ]),

    async loadData() {
      this.isLoading = true

      const { currentPage, filterQuery: query, filterDates: dates, filterStatus: status } = this
      const params = {
        search         : query,
        page           : currentPage,
        created_at__gte: dates[0] ? `${dates[0]}T00:00:00` : '',
        created_at__lte: dates[1] ? `${dates[1]}T23:59:59` : '',
        status         : status || '',
      }

      const res = await this.listSales({ params })
      this.itemCount = res.data.count
      this.items     = res.data.results.map(this.mapSale)

      this.isLoading = false
    },

    mapSale(s) {
      return {
        id         : s.id,
        datetime   : s.created_at,
        customer   : s.client_data ? s.client_data.name : '- - -', // TODO: fix
        amount     : s.total_price,
        status     : s.status,
        actions    : null,
        description: s.first_item,
        hasMoreThanOneItem: (s.services_in_sale.length + s.products_in_sale.length) > 1
      }
    },

    hasMoreThanOneItem(sale) {
      const saleServices = sale.description.services
      const saleProducts = sale.description.products
      return saleServices.length + saleProducts.length > 1
    },

    handleViewSale(id) {
      this.$refs.viewSaleDialog.open(id)
    },

    async handleGetReceipt(id) {
      const res = await this.getReceipt({id})
      if (res.status === 200) {
        window.open(URL.createObjectURL(res.data))
      }
    },
    showDialogDelete (id) {
      this.dialogDelete = true
      this.selectedSale = id
    },
    async deleteEvent () {
      try {
        const url = `${settings.API_URL}/api/order/sale/${this.selectedSale}/`
        await axios
          .delete(url)
          .then(response => {
            this.dialogDelete = false
            this.selectedSale = null
            this.loadData()
          })
      } catch (e) { console.log(e) }
    }
  },

  created() {
    this.loadData()
    this.showDeleteBtn = checkGroups([GROUP.GERENTE])
  }
}
</script>

<style lang="scss" scoped>

</style>
