<template lang="pug">
v-container.container( fluid ).pt-1
  v-form(
    ref="form"
    v-model="isValidated"
    lazy-validation
  )
    h3.outerLabel.black--text.mb-2 Fecha y hora
    v-row.mb-0
      v-col( cols=12 md=4 )
        DatePicker(
          label="Fecha"
          v-model="fecha"
          :rules="[() => !!fecha || 'Debe ingresar una fecha']"
        )

      v-col( cols=12 md=4 )
        v-text-field(
          outlined
          dense
          prepend-inner-icon="mdi-clock-outline"
          type="time"
          label="Hora de Inicio"
          v-model="horaInicio"
          :rules="rulesHoraInicio()"
        )

      v-col( cols=12 md=4 )
        v-text-field(
          outlined
          dense
          prepend-inner-icon="mdi-clock-outline"
          type="time"
          label="Hora de Término"
          v-model="horaTérmino"
          :rules="rulesHoraTérmino()"
        )

    h3.outerLabel.black--text.mb-2 Profesional y Ubicación
    v-row( align="center" ).mb-0
      v-col(cols=12 md=4)
        v-autocomplete(
          v-model="profesional"
          :items="[profesional, ...profesionalAutocompleteResults]"
          return-object
          outlined dense
          no-filter
          item-text="full_name"
          @update:search-input="autocompleteProfesionalSearch"
          :rules="[(v) => !!v || 'Seleccione un profesional']"
          label="Profesional"
        )
      v-col( cols=12 md=4 )
        v-autocomplete(
          v-if="tipoUbicación === 'sala'"
          v-model="sala"
          :items="[sala, ...roomAutocompleteResults]"
          return-object
          outlined dense
          no-filter
          item-text="name"
          @update:search-input="autocompleteRoomSearch"
          :rules="rulesSala()"
          label="Sala"
        )

        v-text-field(
          v-else
          label="Dirección"
          v-model="dirección"
          :rules="rulesDirección()"
          outlined dense
        )

      v-col( cols=12 md=4 )
        v-radio-group.mt-n3(
          v-model="tipoUbicación"
          column
        )
          v-radio(
            label="Sala"
            value="sala"
          )
          v-radio(
            label="Domicilio"
            value="domicilio"
          )

    h3.outerLabel.black--text.mb-2 Servicio
    v-row.mb-n10
      v-col( cols=12 md=4 )
        
        AutoComplete(
          ref="fieldCategoría"
          label="Categoría Servicio"
          v-model="categoria"
          :listMethod="listServiceCategories"
          :itemTextMethod="(item) => item.name"
        )

      v-col( cols=12 md=4 )
        v-autocomplete(
          v-model="servicio"
          :items="[servicio, ...serviceAutocompleteResults]"
          item-text="name"
          label="Servicio"
          return-object
          outlined dense
          @update:search-input="autocompleteServiceSearch"
        )

    v-row
      v-col( cols=12 )
        v-textarea(
          label="Comentario"
          v-model="comentario"
          rows="4" no-resize outlined dense
        )

    v-row.justify-space-between.buttons-row
      v-btn.form-btn(
        @click="$emit('closeDialog')"
        outlined color="primary"
      )
        | Cancelar
      v-btn.form-btn(
        color="primary"
        @click="handleNext"
      )
        | Siguiente
</template>

<script>
import { mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import DatePicker from './DatePicker.vue';
import TimePicker from './TimePicker.vue';
import AutoComplete from './AutoComplete.vue';
import TrueAutoComplete from './TrueAutoComplete.vue';

const { mapFields } = createHelpers({
  getterType: 'getFormHoraField',
  mutationType: 'updateFormHoraField',
});

export default {
  components: { DatePicker, TimePicker, AutoComplete, TrueAutoComplete },

  data() {
    return {
      isValidated: true,
      profesionalAutocompleteResults: [],
      roomAutocompleteResults: [],
      serviceAutocompleteResults: [],
    }
  },

  computed: {
    ...mapFields('agendarHora', [
      'fecha',
      'horaInicio',
      'horaTérmino',
      'profesional',
      'tipoUbicación',
      'sala',
      'dirección',
      'categoria',
      'servicio',
      'comentario',
      'fechaOriginal',
      'horaInicioOriginal',
      'horaTérminoOriginal',
    ]),
  },

  watch: {
    comentario(val) {
      if (val === null) {
        this.comentario = ''
      }
    },

    categoria(val) {
      this.autocompleteServiceSearch('')
    }
  },

  methods: {
    ...mapActions('user', [
      'listProfessional'
    ]),
    ...mapActions('service', [
      'listRooms',
      'listServiceCategories',
      'listServicesInCat',
    ]),
    ...mapActions('agendarHora', [
      'isRoomAvailable'
    ]),

    async autocompleteProfesionalSearch(search) {
      const { data, error } = await this.$store.dispatch('user/autocompleteProfessional', { search })
      if (!error) {
        this.profesionalAutocompleteResults = data
      }
    },

    async autocompleteRoomSearch(search) {
      const { data, error } = await this.$store.dispatch('room/autocompleteRoom', { search })
      if (!error) {
        this.roomAutocompleteResults = data
      }
    },

    async autocompleteServiceSearch(search) {
      const params = { search }
      if (this.categoria) {
        params.category = this.categoria.id
      }
      const { data, error } = await this.$store.dispatch('service/autocompleteServices', params)
      if (!error) {
        this.serviceAutocompleteResults = data
      }
    },

    getPayloadServicio(query) {
      const { categoria } = this
      return {
        ...( categoria?.id ? { category__id: categoria.id } : {} ),
        search: query,
      }
    },
    rulesHoraInicio() {
      return [
        (v) => !!v || 'Debe ingresar una hora',
        (v) => (!this.horaTérmino || v < this.horaTérmino) || 'Debe ser anterior a la de término',
      ]
    },
    rulesHoraTérmino() {
      return [
        (v) => !!v || 'Debe ingresar una hora',
        (v) => (!this.horaInicio || v > this.horaInicio) || 'Debe ser posterior a la de inicio',
      ]
    },
    rulesSala() {
      return this.tipoUbicación !== 'sala' ? [] : [
        (v) => !!v || 'Seleccione una sala'
      ]
    },
    rulesDirección() {
      return this.tipoUbicación === 'sala' ? [] : [
        (v) => !!v || 'Ingrese una dirección'
      ]
    },

    resetForm() {
      this.isValidated = true
      this.$refs.form.reset()
      this.$nextTick(() => this.tipoUbicación = 'sala')
    },

    hasDateTimeChanged() {
      return(
        this.fecha !== this.fechaOriginal
        || this.horaInicio !== this.horaInicioOriginal
        || this.horaTérmino !== this.horaTérminoOriginal
      )
    },

    handleNext() {
      if (!this.$refs.form.validate()) return

      if (this.isEditando && !this.hasDateTimeChanged) {
        this.$emit('nextTab')
        return
      }

      this.isRoomAvailable()
        .then(isAvailable => {
          this.$emit(isAvailable ? 'nextTab' : 'notAvailable')
        })
        .catch(err => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 24px;
}

.buttons-row {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
</style>
