import axios from '@/plugins/axios';

export const listPrestaciones = ({ commit }, { params }) => {
  return axios.get('order/service-in-sale/', { params })
    .catch(err => err.response)
}

export const setSummary = ({ commit }, { params }) => {
  return axios.get('order/service-in-sale/report/', { params })
    .then(res => {
      commit('SET_SUMMARY', { summary: res.data })
    })
    .catch(err => {
      commit('CLEAR_SUMMARY')
      return err.response
    })
}

export const getSalarySlip = (_, { params }) => {
  const options = { responseType: 'blob' }
  return axios.get('order/service-in-sale/salary/', { ...options, params })
    .catch(err => err.response)
}