<template lang="pug">
v-dialog(v-model="dialog" max-width="1200px")
  v-card
    v-card-title.d-flex.align-center.justify-space-between
      span.black--text {{ title }}
      v-btn.icon(@click="close" icon small)
        v-icon mdi-close
    v-divider.mb-4
    v-card-text
      v-form(ref="form" @submit.prevent="submit")
        p.text-left.black--text.text-left.mb-8  Selecciona un cliente existente o crea uno nuevo.
        v-row
          v-col.py-0
            label.font-weight-bold.primary--text Buscar Cliente
            .d-flex
              v-autocomplete.mr-2(
                v-model="owner"
                :items="[owner, ...clientAutocompleteResults]"
                return-object
                outlined dense
                no-filter
                item-text="name"
                no-data-text="No se encontraron datos"
                @update:search-input="autocompleteClientSearch"
                prepend-inner-icon="mdi-magnify"
                :append-inner-icon="form.owner && form.owner.id ? 'mdi-pencil' : null"
                @change="setOwner"
                :rules="rules.owner"
              ) 
                template(#item="{ item }")
                  v-list-item-content(v-if="item")
                    v-list-item-title {{ item.name }}
                    v-list-item-subtitle {{ item.rut }}
              v-tooltip(top)
                template(#activator="{ on, attrs }")
                  v-btn.pa-0.rounded-lg(
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="newClient()"
                    height="40"
                    min-width="40"
                    )
                    v-icon mdi-account-plus
                span Nuevo Cliente
        div(v-if="activeClientForm")
         
          //- Formulario Cliente
          h3.black--text.mb-4 {{ form.owner.id ? `Editando Cliente "${form.owner.name}"` : 'Nuevo Cliente' }}
          v-card.rounded-lg.mb-4(color="#F3F6F9" elevation=0 style="position: relative;")
            v-tooltip(top)
              template(#activator="{ on, attrs }")
                v-btn.pa-0.rounded-lg(
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  @click="cancelClientForm()"
                  height="40"
                  min-width="40"
                  style="position: absolute; right: 10px; top: 10px;"
                )
                  v-icon(color="white") mdi-cancel
              span Cancelar
            v-card-text
              v-row
                v-col(cols=12 md=8)
                  v-row.mb-2
                    v-col(cols=12 md=6)
                      label.font-weight-bold.primary--text Nombre*
                      v-text-field(
                        v-model="form.owner.name"
                        :rules="rules.ownerName"
                        outlined dense
                      )
                    v-col(cols=12 md=6)
                      label.font-weight-bold.primary--text Rut
                      v-text-field(
                        v-model="form.owner.rut"
                        v-mask="'##.###.###-#'"
                        :rules="rules.ownerRut"
                        :error-messages="backendErrors.ownerRut"
                        outlined dense
                        @input="backendErrors.ownerRut = []"
                      )
                    v-col.py-0(cols=12 md=6)
                      label.font-weight-bold.primary--text Email
                      v-text-field(
                        v-model="form.owner.email"
                        :rules="rules.ownerEmail"
                        outlined dense
                      )
                    v-col.py-0(cols=12 md=6)
                      label.font-weight-bold.primary--text Contacto
                      v-text-field(
                        v-model="form.owner.contact"
                        outlined dense
                      )
        div(v-else)
          //- Datos Cliente Solo Lectura
          
          div(v-if="owner")
            h3.black--text.mb-4 Datos Cliente
            v-card.rounded-lg.mb-4(color="#F3F6F9" elevation=0 style="position: relative;")
              v-tooltip(top)
                template(#activator="{ on, attrs }")
                  v-btn.pa-0.rounded-lg(
                    v-if="!activeClientForm && owner && owner.id"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="activeClientForm = true"
                    height="40"
                    min-width="40"
                    style="position: absolute; right: 10px; top: 10px;"
                    )
                    v-icon mdi-pencil
                span Editar Cliente
              v-card-text
                v-row
                  v-col(cols=12 md=8)
                    v-row
                      v-col(cols=12 md=6)
                        label.font-weight-bold.primary--text Nombre
                        p.text-left.black--text {{ owner.name || 'No asignado' }}
                      v-col(cols=12 md=6)
                        label.font-weight-bold.primary--text Rut
                        p.text-left.black--text {{ owner.rut || 'No asignado' }}
                      v-col.py-0(cols=12 md=6)
                        label.font-weight-bold.primary--text Email
                        p.text-left.black--text {{ owner.email || 'No asignado' }}
                      v-col.py-0(cols=12 md=6)
                        label.font-weight-bold.primary--text Contacto
                        p.text-left.black--text {{ form.owner.contact || 'No asignado' }}
          p.text-left.black--text(v-else) No se ha seleccionado un cliente
        h3.black--text.mb-8 Datos Paciente
        //- Formulario Paciente
        v-row
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Nombre*
            v-text-field(
              v-model="form.name"
              :rules="rules.name"
              outlined dense
            )
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Especie*
            v-text-field(
              v-model="form.specie"
              :rules="rules.specie"
              outlined dense
            )
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Raza
            v-text-field(
              v-model="form.race"
              outlined dense
            )
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Sexo*
            v-select(
              v-model="form.sex"
              :rules="rules.sex"
              outlined dense
              :items="sexOptions"
            )
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Fecha de Nacimiento
            v-text-field(
              v-model="form.date_of_birth"
              type="date"
              outlined dense
            )
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Peso
            v-text-field(
              v-model="form.weight"
              outlined dense
            )
          v-col.py-0(cols=12 md=4)
            label.font-weight-bold.primary--text Color
            v-text-field(
              v-model="form.color"
              outlined dense
            )
        v-row(justify="end")
          v-col(cols=6 sm="auto")
            v-btn.rounded-lg(@click="close" color="primary" dark outlined block :loading="loadingSubmit")
              span Cancelar
          v-col(cols=6 sm="auto")
            v-btn.rounded-lg(type="submit" color="primary" dark block :loading="loadingSubmit")
              span Guardar
</template>

<script>
import { validateRUT } from 'validar-rut'
import { debounce } from 'lodash'

export default {
  data () {
    return {
      dialog: false,
      newOwner: false,
      edit: false,
      clientAutocompleteResults: [],
      owner: null,
      activeClientForm: false,
      loadingForm: false,
      loadingSubmit: false,
      form: {
        name: '',
        specie: '',
        race: '',
        sex: '',
        date_of_birth: null,
        weight: '',
        color: '',
        owner: null,
      },
      sexOptions: [
        { text: 'Macho', value: 'macho' },
        { text: 'Hembra', value: 'hembra' },
      ],
      rules: {
        ownerName: [
          v => !!v || 'El nombre es requerido',
        ],
        ownerEmail: [
          v => !v || /.+@.+\..+/.test(v) || 'El email debe ser válido',
        ],
        ownerRut: [
          v => !v || validateRUT(v) || 'El rut debe ser válido',
        ],
        owner: [
          () => !!this.form.owner || 'El cliente es requerido',
        ],
        name: [
          v => !!v || 'El nombre es requerido',
        ],
        specie: [
          v => !!v || 'La especie es requerida',
        ],
        sex: [
          v => !!v || 'El sexo es requerido',
        ],
      },
      backendErrors: {},
    }
  },
  methods: {
    async open(id) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.loadingForm = true
      this.dialog = true
      this.activeClientForm = false
      this.edit = !!id
      this.owner = null
      this.form = {
        name: '',
        specie: '',
        race: '',
        sex: '',
        date_of_birth: null,
        weight: '',
        color: '',
        owner: null,
      }

      if (this.edit) {
          const { error, data: pet } = await this.$store.dispatch('pet/getPet', { id })
        if (error) {
          this.$toast.error('Error al obtener paciente')
          this.close()
          return
        }
        this.form = {
          ...pet,
        }
        this.owner = { ...pet.client }
        this.form.owner = { ...pet.client }
      }
      this.loadingForm = false
    },
      
    close() {
      this.dialog = false
    },
    newClient() {
      this.form.owner = {
        name: '',
        rut: '',
        email: '',
        contact: '',
      }
      this.owner = null
      this.activeClientForm = true
    },
    cancelClientForm() {
      this.activeClientForm = false
      this.form.owner = this.owner ? { ...this.owner } : null
    },
    setOwner (owner) {
      this.form.owner = { ...owner}
      this.activeClientForm = false
    },

    async submit () {
      this.backendErrors = {}
      if (!this.$refs.form.validate()) {
        return
      }
      this.loadingSubmit = true
      let clientId = this.form.owner.id
      // Se envia el formulario de Cliente
      if (this.activeClientForm) {
        const ownerData = {
          ...this.form.owner,
        }
        ownerData.rut = ownerData.rut || null
        if (this.form.owner.id) {
          // El cliente ya existe solo se actualiza
          const { error } = await this.$store.dispatch('client/updateClient', { data: ownerData, id: ownerData.id })
          if (error) {
            const errorDetail = Object.values(error.response.data).join('\n')
            this.backendErrors.ownerRut = error.response.data.rut
            this.$toast.error(`Error al actualizar cliente: ${errorDetail}`)
            this.loadingSubmit = false
            return
          }
        } else {
          // El cliente no existe se crea
          const { error, data: client } = await this.$store.dispatch('client/createClient', { data: ownerData })
          if (error) {
            const errorDetail = Object.values(error.response.data).join('\n')
            this.backendErrors.ownerRut = error.response.data.rut
            this.$toast.error(`Error al crear cliente: ${errorDetail}`)
            this.loadingSubmit = false
            return
          }
          clientId = client.id
        }
      }
      // Se envia el formulario de Paciente
      const data = {
        ...this.form,
      }
      data.owner = clientId
      if (this.edit) {
        const { error } = await this.$store.dispatch('pet/updatePet', { data, id: this.form.id })
        if (error) {
          this.$toast.error('Error al actualizar paciente')
          this.loadingSubmit = false
          return
        }
      } else {
        const { error } = await this.$store.dispatch('pet/createPet', { data })
        if (error) {
          this.$toast.error('Error al crear paciente')
          this.loadingSubmit = false
          return
        }
      }
      this.$toast.success('Paciente guardado')
      this.$emit('saved')
      this.close()
      this.loadingSubmit = false
    },

    async autocompleteClientSearch (search) {
      const { data, error } = await this.$store.dispatch('client/autocompleteClient', { search })
      if (!error) {
        this.clientAutocompleteResults = data
      }
    },
  },
  computed: {
    title () {
      return this.edit ? 'Editar Paciente' : 'Nuevo Paciente'
    }
  },
  created () {
    this.autocompleteClientSearch = debounce(this.autocompleteClientSearch, 500)
  }
}
</script>
