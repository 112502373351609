<template lang="pug">
div
  v-row
    v-col( cols=12 md=6 )
      h3.header Cliente
      v-container
        v-row( v-for="item in datos.cliente" no-gutters ).item-row
          v-col {{item.name}}
          v-col( cols=4 ) {{item.content}}

    v-col( cols=12 md=6 )
      h3.header Venta
      v-container
        v-row( v-for="item in datos.venta" no-gutters ).item-row
          v-col {{item.name}}
          v-col( cols=4 ) {{item.content}}

  v-row( v-if="datos.servicios.length > 0" )
    v-col
      h3.header Servicios

  v-row.mb-2( v-if="datos.servicios.length > 0" )
    v-col( v-for="s in datos.servicios" cols=12 md=6 )
      v-card
        v-card-title.py-1
          h5.header {{s.name}}
        v-divider
        v-card-text.pt-3.pb-4
          v-row( v-for="item in s.items" no-gutters ).item-row
            v-col {{item.name}}
            v-col( cols=4 ) {{item.content}}

          v-row( no-gutters ).item-row.mt-3
            v-col.header.font-weight-bold Productos incluidos
          v-row( v-for="p in s.products" no-gutters ).item-row
            v-col.ml-3 {{p.name}}
            v-col( cols=4 ) x {{p.quantity}} ({{p.unit}})
          v-row( v-if="s.products.length === 0" no-gutters ).item-row
            v-col.ml-3 Ninguno

  v-row( v-if="datos.productos.length > 0" )
    v-col
      h3.header Productos
      
  v-row.mb-2( v-if="datos.productos.length > 0" )
    v-col( v-for="p in datos.productos" cols=12 sm=6 )
      v-card
        v-card-title.py-1
          h5.header {{p.name}}
        v-divider
        v-card-text.pt-3.pb-4
          v-row( v-for="item in p.items" no-gutters ).item-row
            v-col {{item.name}}
            v-col( cols=4 ) {{item.content}}

</template>

<script>
import FormatterMixin from '@/components/mixins/FormatterMixin';
import StatusDataMixin from '../../mixins/StatusDataMixin';
export default {
  mixins: [ FormatterMixin, StatusDataMixin ],
  props: {
    sale: Object,
  },

  data() {
    return {
    }
  },

  computed: {

    datos() {
      const s = this.sale
      return {

        cliente: [
          {name: 'Nombre',          content: s.client_data ? s.client_data.name     : ''},
          {name: 'RUT',             content: s.client_data ? s.client_data.rut      : ''},
          {name: 'Teléfono',        content: s.client_data ? s.client_data.contact  : ''},
          {name: 'Correo',          content: s.client_data ? s.client_data.email    : ''},
        ],

        venta: [
          {name: 'Fecha creación',  content: this.dateTimeToLocaleDate(s.created_at)},
          {name: 'Monto total',     content: this.numberToPesos(s.total_price)},
          {name: 'Estado',          content: this.getStatusText(s.status)},
          {name: 'Fecha de pago',   content: s.status === 'PAID' ? this.dateTimeToLocaleDate(s.payment_date) : '---'},
          {name: 'Método de pago',  content: s.status === 'PAID' ? s.payment_method : '---'},
        ],

        servicios: s.services_in_sale.map(ss => ({
          name: ss.service_data.name,
          items: [
            {name: 'Valor total',   content: this.numberToPesos(ss.service_data.total_price)},
            {name: 'Profesional',   content: ss.professional_name},
          ],
          products: ss.service_data.product_data.map(p => ({
            name:     p.product.name,
            quantity: p.quantity,
            unit:     p.product.unit,
          })),
        })),

        productos: s.products_in_sale.map(ps => ({
          name: ps.product_data.name,
          items: [
            {name: 'Precio unitario',   content: this.numberToPesos(ps.is_fragmented_sale ? ps.product_data.fragmented_unit_price : ps.product_data.price)},
            {name: 'Venta fragmentada', content: ps.is_fragmented_sale ? 'Sí' : 'No'},
            {name: 'Cantidad',          content: `${ps.quantity} (x ${ps.is_fragmented_sale ? ps.product_data.purchase_size : ''} ${ps.product_data.unit})`},
            {name: 'Valor total',       content: this.numberToPesos((ps.is_fragmented_sale ? ps.product_data.fragmented_unit_price : ps.product_data.price) * ps.quantity)},
          ]
        })),

      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  color: var(--v-primary-base);
}

.item-row {
  padding: 2px 0 0;
}
</style>