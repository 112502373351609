<template lang="pug">
v-container.container
  TitleAndBreadcrumb( label="Lista de Prestaciones" )

  v-card
    v-card-text
      TopBar( @queryChange="handleQueryChange" )
    v-card-text.list-card-text
      List( ref="list" )

  .d-flex.align-center.justify-space-between
    GetSalarySlipButton
    Pagination(
      v-model="currentPage"
      :pageCount="pageCount"
    )
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import TitleAndBreadcrumb from '@/components/shared/TitleAndBreadcrumb.vue';
import TopBar from '@/components/prestaciones/listado/TopBar.vue';
import List from '@/components/prestaciones/listado/List.vue';
import Pagination from '@/components/prestaciones/listado/Pagination.vue';
import GetSalarySlipButton from '../components/prestaciones/listado/GetSalarySlipButton.vue';

export default {
  components: { TitleAndBreadcrumb, TopBar, List, Pagination, GetSalarySlipButton },

  computed: {
    ...mapFields('prestaciones', [
      'currentPage',
      'pageCount',
    ]),
  },

  watch: {
    currentPage(_) {
      this.$refs.list.loadData()
    }
  },

  methods: {
    handleQueryChange() {
      this.$refs.list.loadData()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.list-card-text {
  padding: 0;
  margin-top: 4px;
}

</style>
